

<div class="container-fluid" style="background-color: var(--gl-body-background-color);">
    <div class="row">
      <div class="col-12" style="overflow: auto; height: 100vh; max-height: 100vh; padding: 0.8rem; position: relative;">
        <!-- <app-auditform (selectedPlanEvent)="onPlanSelection($event)"></app-auditform> -->

        <!-- <app-auditmap #subscritionIp [ip]="ip_address"></app-auditmap> -->

        <app-rp-list></app-rp-list>
  
      </div>
      <!-- <div class="col-2" style="overflow: auto; max-height: 100vh; padding: 0.8rem; position: relative;"> -->
        <!-- <app-auditresult #subscritionDetails (eventDisplayGroup)="eventDisplayGroup($event)" (selectedActivityEvent)="onActivitySelection($event)"></app-auditresult> -->
      <!-- </div> -->
  
  
    </div>

    <!-- <app-auditdetail (onHideEvent)="onHideDisplayGroup($event)" [display]="displayGroup" [properties]="properties" [subject_type]="subject_type"></app-auditdetail> -->
  
</div>


<app-rp-add  *ngIf="repProgramadosService.modal_add0 || repProgramadosService.modalReportesProgramadosActiveAddEdit" ></app-rp-add>
<app-rp-show *ngIf="repProgramadosService.modalReportesProgramadosActiveDetalle"></app-rp-show>

