import {
  AfterContentChecked,
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { GeofencesService } from '../../services/geofences.service';
import { GeofenceImportExportService } from '../../services/geofence-import-export.service';
import {
  CircGeoFormatToSave,
  DataGeofence,
  DirectionsToSave,
  FileValidationData,
  GeoImp,
  ImportGeofence,
  LineEssential,
  PolygGeoFormatToSave,
  Speeds,
} from '../../models/interfaces';
import { MapItemConfiguration } from 'src/app/multiview/models/interfaces';
import Swal from 'sweetalert2';
import { MapServicesService } from 'src/app/map/services/map-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
import { CircularGeofencesService } from '../../services/circular-geofences.service';
import * as L from 'leaflet';
import { MessageService } from 'primeng-lts/api';
interface ImportGeofences {
  essential: DataGeofence;
  zone_speed?: number;
  zone2_speed?: number;
  zone3_speed?: number;
  max_zone_speed?: number;
  latLngRadio: string;
  selectedSpeed: string;
  speed?: boolean;
  type: string;
  id: number;
  error: [];
}
@Component({
  selector: 'app-geofences-modal',
  templateUrl: './geofences-modal.component.html',
  styleUrls: ['./geofences-modal.component.scss'],
})
export class GeofencesModalComponent
  implements OnInit, AfterContentChecked, OnDestroy, AfterViewInit
{
  @Output() geofenceImported = new EventEmitter<void>();
  minimapElement: HTMLElement | null = null;
  prueba: any;
  etiquetaPrueba: any;
  tags: any;
  importSource: string = '';

  minimapGeo: any;
  map!: L.Map;
  confGeoMap: MapItemConfiguration = {
    containerId: 'geofence-minimap',
    zoom: 8,
    maxZoom: 18,
    dataFitBounds: [[-10.3288, -74.9018]],
  };

  selectedGeofencesToImport: ImportGeofence[] = [];
  selectedGeofenceToModify: ImportGeofence;
  dataGeofence: ImportGeofence[] = [];
  dataGeofenceInvalidity: ImportGeofence[] = [];
  showInvalidGeofence: boolean = false;
  speedsList: Speeds[];
  modifiableGeofence: boolean = false;

  constructor(
    public geofencesService: GeofencesService,
    public mapService: MapServicesService,
    private spinner: NgxSpinnerService,
    public vehicleService: VehicleService,
    public circularGeofencesService: CircularGeofencesService,
    public geofenceImportExportService: GeofenceImportExportService,
    private ngZone: NgZone
  ) {
    this.speedsList = [
      {
        name: 'LÍMITE DE VELOCIDAD',
        slug: 'vel_max',
      },
      {
        name: 'TOLERABLE',
        slug: 'vel_zone',
      },
      {
        name: 'GRAVE',
        slug: 'vel2_zone',
      },
      {
        name: 'MUY GRAVE',
        slug: 'vel3_zone',
      },
    ];
    this.selectedGeofenceToModify = {
      id: '',
      type: '',
      geo_coordenadas: '',
      zone_vertices: '',
      zone_name: '',
      zone_perimetro: '',
      zone_area: '',
      zone_color: '',
      zone_visible: false,
      zone_name_visible: false,
      tag_name_color: '',
      tag_name_font_size: 0,
      vel_act_zone: false,
      vel_max: 0,
      vel_zona: 0,
      vel2_zona: 0,
      vel3_zona: 0,
      idoperation: 0,
      nameoperation: '',
      tags: [],
      descripcion: '',
      orden: '',
      origin: '',
      errors: [],
      hasStandardGeofences: false,
      hasDirection:false,
      direction:[],
      StandardGeofences: {
        name: '',
        description: '',
        Style: {
          LineStyle: {
            width: '',
            color: '',
          },
          PolyStyle: {
            color: '',
          },
        },
        Polygon: {
          outerBoundaryIs: {
            LinearRing: {
              coordinates: '',
            },
          },
        },
      },
    };
  }
  ngOnInit(): void {
    const getTags_ = this.geofencesService.getTag().subscribe((resp) => {
      this.tags = resp.data;
    });
    // console.log('getTag', getTags_);
    this.datosVelocidades();
    this.datosEtiqueta();
  }
  ngOnDestroy(): void {
    // console.log('geoImp: pass 3');
    if (this.minimapElement) {
      this.minimapElement.remove();
    }
    this.selectedGeofencesToImport = [];
    this.dataGeofence = [];
    this.minimapElement = null;
  }
  enviarDatosOtroComponente() {
    // let geos: DataGeofence[] = [];
    // this.selectedGeofencesToImport.map((i) => {
    //   geos.push(i.essential);
    // });
    // this.geofencesService.setData(geos);
  }
  ngAfterViewInit() {
    // console.log('ngAfterViewInit', this.dataGeofence);
  }
  ngAfterContentChecked(): void {}
  calculeTime(duration: number): string {
    const timeParts: { [key: string]: number } = {
      horas: Math.floor((duration / (1000 * 60 * 60)) % 24),
      minutos: Math.floor((duration / (1000 * 60)) % 60),
      segundos: Math.floor((duration / 1000) % 60),
    };

    const timeStrings: { [key: string]: string } = {
      horas:
        timeParts.horas > 0
          ? `${timeParts.horas} hora${timeParts.horas > 1 ? 's' : ''}`
          : '',
      minutos:
        timeParts.minutos > 0
          ? `${timeParts.minutos} minuto${timeParts.minutos > 1 ? 's' : ''}`
          : '',
      segundos:
        timeParts.segundos > 0
          ? `${timeParts.segundos} segundo${timeParts.segundos > 1 ? 's' : ''}`
          : '',
    };

    let result = '';

    if (timeStrings.horas) {
      result += timeStrings.horas;
      if (timeStrings.minutos || timeStrings.segundos) {
        result += ' ';
      }
    }

    if (timeStrings.minutos) {
      result += timeStrings.minutos;
      if (timeStrings.segundos) {
        result += ' con ';
      }
    }

    if (timeStrings.segundos) {
      result += timeStrings.segundos;
    }

    return result || '0 segundos';
  }

  guardarRegistro(): void {
    const saveGeofence: Record<
      string,
      (T: ImportGeofence) => Promise<any> | any
    > = {
      polig: (T: ImportGeofence) => this.savePolig(T),
      circ: (T: ImportGeofence) => this.saveCircle(T),
    };
    let timerInterval: NodeJS.Timeout;
    let count = 0;
    const numbersToCount = this.selectedGeofencesToImport.length; // Número arbitrario de elementos a contar
    const delayBetweenCount = 5000; // Delay en milisegundos entre cada conteo
    if (this.selectedGeofencesToImport.length > 0) {
      Swal.fire({
        icon: 'info',
        title: 'Confirmación',
        text: `La importación tomará aproximadamente ${this.calculeTime(
          numbersToCount * delayBetweenCount
        )}. Durante este tiempo, no podrá realizar otras acciones en plataforma.`,
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            title: 'Importando geocercas...',
            html: "Importando <b id='count-progress'></b> geocercas",
            didOpen: () => {
              Swal.showLoading();
              const countProgress = document.getElementById('count-progress');
              if (countProgress) {
                Object.entries(this.selectedGeofencesToImport).map(
                  ([key, item]) => {
                    setTimeout(() => {
                      saveGeofence[item.type](item);
                      console.log('geoImp: save', item, 'key', key);
                    }, delayBetweenCount * parseInt(key));
                  }
                );
                timerInterval = setInterval(() => {
                  count++;
                  console.log('geoImp: count', count);
                  countProgress.textContent = `${count} / ${numbersToCount}`;
                  if (count >= numbersToCount) {
                    clearInterval(timerInterval);
                    Swal.close();
                  }
                }, delayBetweenCount);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
            // showCloseButton: true,
            showConfirmButton: false, // Oculta el botón de confirmación
            allowOutsideClick: false, // Evita que se cierre haciendo clic fuera del cuadro de diálogo
            allowEscapeKey: false, // Evita que se cierre presionando la tecla de escape
          })
            .then()
            .catch()
            .finally(() => {
              this.geofencesService.modalActive = false;
              Swal.fire({
                icon: 'success',
                title: 'Importación exitosa',
                text: 'Se importaron correctamente las geocercas',
              });
            });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Seleccione al menos una geocerca para importar',
      });
    }
    console.log('geoImp: data', this.selectedGeofencesToImport);
    // this.enviarDatosOtroComponente();
  }
  getNameOperation(idOpe: any) {
    if (!idOpe) {
      return 'Geocercas Sin Operacion';
    } else {
      const foundVehicle = this.vehicleService.vehicles.find(
        (vehicle) => vehicle.idoperation == idOpe
      );
      if (foundVehicle) {
        if (foundVehicle.nameoperation == 'Unidades Sin Operacion') {
          return 'Geocercas Sin Operacion';
        } else {
          return foundVehicle.nameoperation;
        }
        // Usa nameOperation aquí según sea necesario
      } else {
        return 'Geocercas Sin Operacion';
      }
    }
  }
  changeGeofenceSelectColor() {
    this.geofenceImportExportService.minimap.map?.remove();
    this.geofenceImportExportService.startMiniMap(this.confGeoMap);
    const geoSelectedToModify = [
      {
        name: this.selectedGeofenceToModify.zone_name,
        coordinate: this.selectedGeofenceToModify.zone_vertices,
        description: this.selectedGeofenceToModify.descripcion,
        color: this.selectedGeofenceToModify.zone_color,
        width: '3',
        tag: '',
      },
    ];
    const setCoordOfSelectedToModify: number[][][] =
      this.geofenceImportExportService.coordinatesGeo(geoSelectedToModify);
    this.geofenceImportExportService.alignGeofence(setCoordOfSelectedToModify);
    this.geofenceImportExportService.addGeofences(
      setCoordOfSelectedToModify,
      geoSelectedToModify
    );
    // this.selectedGeofenceToModify = { ...geofence };
    console.log('geoImp ChangeColor');
  }
  showAllGeofences() {
    this.geofenceImportExportService.minimap.map?.remove();
    if (this.dataGeofence.length > 0) {
      this.geofenceImportExportService.startMiniMap(this.confGeoMap);
      const datoFormatToOldVersion = this.dataGeofence.map((e) => ({
        name: e.zone_name,
        coordinate: e.zone_vertices,
        description: e.descripcion,
        color: e.zone_color,
        width: '3',
        tag: '',
      }));
      const setCoordinates: number[][][] =
        this.geofenceImportExportService.coordinatesGeo(datoFormatToOldVersion);
      this.geofenceImportExportService.addGeofences(
        setCoordinates,
        datoFormatToOldVersion
      );
      this.geofenceImportExportService.minimap.map?.invalidateSize();
      this.geofenceImportExportService.alignGeofence(setCoordinates);
    }
  }
  hideGeofenceDataModifier() {
    this.modifiableGeofence = false;
  }
  modifyGeofenceData(geofence: ImportGeofence) {
    this.modifiableGeofence = true;
    this.geofenceImportExportService.minimap.map?.remove();
    this.geofenceImportExportService.startMiniMap(this.confGeoMap);
    const geoSelectedToModify = [
      {
        name: geofence.zone_name,
        coordinate: geofence.zone_vertices,
        description: geofence.descripcion,
        color: geofence.zone_color,
        width: '3',
        tag: '',
      },
    ];
    const setCoordOfSelectedToModify: number[][][] =
      this.geofenceImportExportService.coordinatesGeo(geoSelectedToModify);
    this.geofenceImportExportService.alignGeofence(setCoordOfSelectedToModify);
    this.geofenceImportExportService.addGeofences(
      setCoordOfSelectedToModify,
      geoSelectedToModify
    );
    this.selectedGeofenceToModify = { ...geofence };

    // this.geofenceImportExportService.alignGeofence(
    //   this.geofenceImportExportService.coordinatesGeo([
    //     { coordinate: geofence.zone_vertices },
    //   ])
    // );
  }
  saveModifiedGeofenceData() {
    if (!this.selectedGeofenceToModify.zone_name.trim()) {
      throw new Error('El nombre no debe estar vacio');
    }
    this.selectedGeofenceToModify = {
      ...this.selectedGeofenceToModify,
      vel_act_zone:
        this.selectedGeofenceToModify.vel_max > 0
          ? this.selectedGeofenceToModify.vel_act_zone
          : false,
      vel_zona:
        this.selectedGeofenceToModify.vel_max > 0
          ? this.selectedGeofenceToModify.vel_zona
          : 0,
      vel2_zona:
        this.selectedGeofenceToModify.vel_max > 0
          ? this.selectedGeofenceToModify.vel2_zona
          : 0,
      vel3_zona:
        this.selectedGeofenceToModify.vel_max > 0
          ? this.selectedGeofenceToModify.vel3_zona
          : 0,
    };
    this.dataGeofence = Object.entries(this.dataGeofence).map(
      ([_, geofence]) => {
        if (this.selectedGeofenceToModify.id === geofence.id) {
          return { ...this.selectedGeofenceToModify };
        }
        return geofence;
      }
    );
    this.selectedGeofencesToImport = Object.entries(
      this.selectedGeofencesToImport
    ).map(([_, geofence]) => {
      if (this.selectedGeofenceToModify.id === geofence.id) {
        return { ...this.selectedGeofenceToModify };
      }
      return geofence;
    });
    this.modifiableGeofence = false;
    // this.hideGeofenceDataModifier();
  }
  locateGeofence(itemGeofence: ImportGeofence) {
    this.modifiableGeofence = false;
    this.geofenceImportExportService.minimap.map?.remove();
    this.geofenceImportExportService.startMiniMap(this.confGeoMap);
    const geoItem = [
      {
        name: itemGeofence.zone_name,
        coordinate: itemGeofence.zone_vertices,
        description: itemGeofence.descripcion,
        color: itemGeofence.zone_color,
        width: '3',
        tag: '',
      },
    ];
    const setCooGeo: number[][][] =
      this.geofenceImportExportService.coordinatesGeo(geoItem);
    this.geofenceImportExportService.alignGeofence(setCooGeo);
    this.geofenceImportExportService.addGeofences(setCooGeo, geoItem);
  }
  generateMap(datos: DataGeofence[]) {
    // this.dataGeofence = datos;
    this.geofenceImportExportService.startMiniMap(this.confGeoMap);
    const setCoordinates: number[][][] =
      this.geofenceImportExportService.coordinatesGeo(datos);
    this.geofenceImportExportService.alignGeofence(setCoordinates);
    this.geofenceImportExportService.addGeofences(setCoordinates, datos);
  }
  datosVelocidades() {
    const pruebas: any[] = [
      {
        nroVelocidad: 1,
        velocidad: 'lenta',
        selected: false,
      },
      {
        nroVelocidad: 2,
        velocidad: 'moderada',
        selected: false,
      },
      {
        nroVelocidad: 3,
        velocidad: 'alta',
        selected: false,
      },
      {
        nroVelocidad: 4,
        velocidad: 'muy alta',
        selected: false,
      },
    ];
    this.prueba = pruebas;
  }
  datosEtiqueta() {
    const etiqueta: any[] = [
      {
        nroVelocidad: 1,
        velocidad: 'azul',
        selected: false,
      },
      {
        nroVelocidad: 2,
        velocidad: 'amarillo',
        selected: false,
      },
      {
        nroVelocidad: 3,
        velocidad: 'arequipa',
        selected: false,
      },
      {
        nroVelocidad: 4,
        velocidad: 'tacna',
        selected: false,
      },
      {
        nroVelocidad: 4,
        velocidad: 'lima',
        selected: false,
      },
    ];
    this.etiquetaPrueba = etiqueta;
  }
  async dataFromFather(obj: GeoImp, fileName: string) {
    const procesingDirectory: Record<
      string,
      () => Promise<ImportGeofence[]> | ImportGeofence[]
    > = {
      kml: () => this.procesingDataKML(obj),
      csv: () => this.procesingDataCSV(obj),
      kmz: () => this.procesingDataKML(obj),
      xml: () => this.procesingDataKML(obj),
    };
    const fileValidation: FileValidationData = {
      mimetype: [
        'application/vnd.google-earth.kml+xml',
        'application/vnd.google-earth.kmz',
        'application/xml',
        'text/csv',
        'text/plain',
      ],
      type: ['kml', 'kmz', 'csv', 'xml'],
      size: 5120000, //bytes
    };
    const procesingFuntion =
      procesingDirectory[fileName.split('.').pop() ?? ''].bind(this);
    if (!fileValidation.type.includes(fileName.split('.').pop() ?? '')) {
      throw new Error('Inconsistencia en el formato recivido');
    }
    let geofences: ImportGeofence[];
    geofences = await procesingFuntion();
    geofences = geofences
      .map((e) => ({
        ...e,
        zone_vertices: e.hasStandardGeofences
          ? e.StandardGeofences.Polygon.outerBoundaryIs.LinearRing.coordinates
          : e.zone_vertices,
        zone_name: e.hasStandardGeofences
          ? e.StandardGeofences.name
          : e.zone_name,
        descripcion: e.hasStandardGeofences
          ? !e.StandardGeofences.description.trim()
            ? e.StandardGeofences.name
            : e.StandardGeofences.name
          : e.descripcion,
        zone_color: e.hasStandardGeofences
          ? e.StandardGeofences.Style.PolyStyle.color
          : e.zone_color,
      }))
      .filter((e) => {
        this.isValidCoordinate(e) ? null : this.dataGeofenceInvalidity.push(e);
        return this.isValidCoordinate(e);
      });
    if (geofences.length > 0) {
      this.geofenceImportExportService.startMiniMap(this.confGeoMap);
      const datoFormatToOldVersion = geofences.map((e) => ({
        name: e.zone_name,
        coordinate: e.zone_vertices,
        description: e.descripcion,
        color: e.zone_color,
        width: '3',
        tag: '',
      }));
      if (datoFormatToOldVersion.length > 0) {
        const setCoordinates: number[][][] =
          this.geofenceImportExportService.coordinatesGeo(
            datoFormatToOldVersion
          );
        this.geofenceImportExportService.alignGeofence(setCoordinates);
        this.geofenceImportExportService.addGeofences(
          setCoordinates,
          datoFormatToOldVersion
        );
        console.log('geoCerCInvalidity', this.dataGeofenceInvalidity);
      }
      this.dataGeofence = geofences;
    } else {
      throw new Error('no hay geocercas para importar');
    }

    console.log('geoImp:: data genMap', geofences);
  }
  isValidCoordinate(g: ImportGeofence): boolean {
    const validCoordinateWitchRegex: Record<string, RegExp[]> = {
      polig: [/^(-?\d+\.\d+,-?\d+\.\d+,0\s)*(-?\d+\.\d+,-?\d+\.\d+,0\s?)$/],
      circ: [/^<?\(\-?\d+\.\d+,-?\d+\.\d+\),\d+\.\d+>?$/],
    };
    const isValidType = g.type in validCoordinateWitchRegex;
    const isValidVertices =
      typeof g.zone_vertices === 'string' && g.zone_vertices.trim() !== '';
    if (!isValidType || !isValidVertices) {
      return false;
    }
    return validCoordinateWitchRegex[g.type].some((regex) =>
      regex.test(g.type == 'polig' ? g.zone_vertices : g.geo_coordenadas)
    );
  }
  listGeofencesInvalidity() {
    this.showInvalidGeofence = !this.showInvalidGeofence;
    // return (
    //   'Geocercas invalidas: ' +
    //   this.dataGeofenceInvalidity
    //     .map((e) => e.zone_name)
    //     .join(', ')
    //     .toLowerCase()
    // );
  }
  async procesingDataKML(geo: GeoImp): Promise<ImportGeofence[]> {
    return geo.geo.Document.importGeofences;
  }
  async procesingDataCSV(geo: GeoImp): Promise<ImportGeofence[]> {
    return geo.geo.Document.importGeofences;
  }
  colorToBGR(c: string): string {
    const d: string = '404040';
    c = c.replace('#', '');
    if (c.length != 6 && c.length != 8) {
      return d;
    }
    // Extraer los valores de los componentes de color
    var r = parseInt(c.length == 6 ? c.substring(0, 2) : c.substring(2, 4), 16);
    var g = parseInt(c.length == 6 ? c.substring(2, 4) : c.substring(4, 6), 16);
    var b = parseInt(c.length == 6 ? c.substring(4, 6) : c.substring(6, 8), 16);
    c =
      // a.toString(16).padStart(2, '0') + // se quita el canal alfa por no ser de necesidad
      (
        b.toString(16).padStart(2, '0') +
        g.toString(16).padStart(2, '0') +
        r.toString(16).padStart(2, '0')
      ).toUpperCase();
    return c;
  }
  /**
   *
   * @param c :string, color of bbggrr
   * @returns :string, color to #rrggbb
   */
  colorToRGB(c: string): string {
    const d: string = '#404040';
    if (c.length != 6 && c.length != 8) {
      return d;
    }
    // Extraer los valores de los componentes de color
    var r = parseInt(c.substring(4, 6), 16);
    var g = parseInt(c.substring(2, 4), 16);
    var b = parseInt(c.substring(0, 2), 16);
    c =
      // a.toString(16).padStart(2, '0') + // se quita el canal alfa por no ser de necesidad
      (
        '#' +
        r.toString(16).padStart(2, '0') +
        g.toString(16).padStart(2, '0') +
        b.toString(16).padStart(2, '0')
      ).toUpperCase();
    return c;
  }
  checkBoolean(i: string): boolean {
    return i == 'true' ? true : false;
  }
  parseCoordinatesToPairs(c: string): string {
    return `(${c
      ?.trim()
      .split(' ')
      .map((v) => {
        const [x, y, z] = v.split(',');
        return `${x} ${y}`;
      })
      .join(', ')})`;
  }
  savePolig(g: ImportGeofence) {
    let polygonGeofenceFormatToSave: PolygGeoFormatToSave = {
      nombre: g.zone_name ?? '',
      descripcion: !g.descripcion.trim() ? g.orden ?? '' : g.descripcion ?? '',
      area: 0,
      perimetro: 0,
      categoria: 0,
      zone_type: 'POLYGON',
      color: g.zone_color ?? '#000000',
      visible_zona: g.zone_visible ?? false,
      nombre_visible_zona: g.zone_name_visible ?? false,
      tag_name_color: g.tag_name_color ?? '',
      tag_name_font_size: g.tag_name_font_size ?? 10,
      geo_geometry: this.parseCoordinatesToPairs(g.zone_vertices) ?? '',
      checkVelocidad: g.vel_act_zone ?? false,
      limite_velocidad: g.vel_max ?? 0,
      limite_tolerable: g.vel_zona ?? 0,
      limite_grave: g.vel2_zona ?? 0,
      limite_muy_grave: g.vel3_zona ?? 0,
      id_operacion_grupo: 0,
      id_operation: g.idoperation ?? 0,
      id_grupo: 0,
      tags: g.tags.join(','),
      hasDirection:g.hasDirection ?? false,
    };
    console.log('geoImp save', polygonGeofenceFormatToSave);
    this.geofencesService
      .store({
        ...polygonGeofenceFormatToSave,
        visible_zona: polygonGeofenceFormatToSave.visible_zona
          ? 'false'
          : 'true',
      })
      .then((res) => {
        this.geofencesService.nameComponentPol = 'LISTAR';
        if (res[1] == 'Registro Guardados') {
          console.log('geoImp: suseeec', res);
        }
        let gNew = res[2];
        let gNew2 = res[3][0];
        var geo: any = {};
        if (g.hasDirection) {
          let directions = g.direction;
          directions.map((_)=>{
            let direction:LineEssential = {
              id: parseInt(_.id.toString()),
              zoneId: gNew.id,
              name: _.name,
              points: _.points?.trim().split(' ').map((v) => {
                const [x, y, z] = v.split(',');
                return [parseFloat(y),parseFloat(x)];
              }),
              hasSpeed: _.hasSpeed,
              speed: _.speed,
              type: _.type
            }
            console.log('direction import to polig', direction);
            
            this.geofencesService.addGeofenceAddress(gNew.id,direction)
          })
        }
        geo.id = gNew.id;
        geo.descripcion = gNew.descripcion;
        geo.orden = gNew.nombre_zona;
        geo.vel2_zona = gNew.vel2_zona;
        geo.vel3_zona = gNew.vel3_zona;
        geo.vel_act_zona = gNew.vel_act_zona;
        geo.vel_max = gNew.vel_max;
        geo.vel_zona = gNew.vel_zona;
        geo.zone_cat = gNew.categoria_zona;
        geo.zone_color = gNew.color_zona;
        geo.zone_name = gNew.nombre_zona;
        geo.zone_name_visible = gNew.nombre_visible_zona;
        geo.zone_name_visible_bol = geo.zone_name_visible == 'true';
        geo.zone_visible = gNew.visible_zona;
        geo.tag_name_color = gNew.tag_name_color;
        geo.tag_name_font_size = gNew.tag_name_font_size;
        geo.geo_coordenadas = gNew2.geo_coordenadas;
        geo.zone_vertices = gNew.vertices_zona;
        geo.idoperation = gNew.operation_grupo_id ?? 0;
        geo.nameoperation = this.getNameOperation(geo.idoperation);
        geo.hasDirection = gNew.has_direction;
        geo.tags = gNew.geo_tags;
        geo.geo_elemento = new L.Polygon(
          this.getCoordenadas(JSON.parse(gNew2.geo_coordenadas).coordinates[0]),
          {
            weight: 3,
            fill: true,
            color: geo.zone_color, //'#000000'
          }
        ).addTo(this.mapService.map);
        this.geofencesService.bindMouseEvents(geo);
        var centerPoligon = geo.geo_elemento.getBounds().getCenter();

        let bg_color = this.geofencesService.tooltipBackgroundTransparent
          ? this.geofencesService.defaultTagNameBackground
          : this.mapService.hexToRGBA(geo.zone_color);
        let txt_color = this.geofencesService.tooltipBackgroundTransparent
          ? geo.tag_name_color == ''
            ? this.geofencesService.defaultTagNameColor
            : geo.tag_name_color
          : this.mapService.hexToRGBA(geo.zone_color);
        let font_size =
          (geo.tag_name_font_size == 0
            ? this.geofencesService.defaultTagNameFontSize
            : geo.tag_name_font_size) + 'px';

        geo.marker_name = L.circleMarker(centerPoligon, {
          // pane: 'markers1',
          radius: 0,
          fillColor: '#000', //color,
          fillOpacity: 1,
          color: '#000', //color,
          weight: 1,
          opacity: 1,
        }).bindTooltip(
          '<b class="" style="background-color: ' +
            bg_color +
            '; color : ' +
            txt_color +
            '; font-size: ' +
            font_size +
            '">' +
            geo.zone_name +
            '</b>',
          {
            permanent: true,
            // offset: [-100, 0],
            direction: 'center',
            className: 'leaflet-tooltip-own geofence-tooltip',
          }
        );

        geo.marker_name.addTo(this.mapService.map);

        this.geofencesService.geofences.push(geo);
        console.log('geoImp: save stores response', geo);

        // this.geofencesService
        this.spinner.hide('spinnerLoading');
        this.geofencesService.initializeTable(geo.id);
        this.geofencesService.updateGeoCounters();
        this.geofencesService.updateGeoTagCounters();
        this.geofencesService.eyeInputSwitch =
          this.geofencesService.geofenceCounters.visible != 0;
        this.geofencesService.tagNamesEyeState =
          this.geofencesService.geofenceTagCounters.visible != 0;
        this.spinner.hide('spinnerLoading');
        this.geofenceImported.emit();
        console.log('geo save res', geo);
      });
    //Despues de agregar o editar, ordena el a rray de geocercas por el atributo orden(nombre de la geocerca en mayuscula)
    // this.geofencesService.geofences.sort(function (a: any, b: any) {
    //   return a.orden.localeCompare(b.orden, 'en', {
    //     numeric: true,
    //   });
    // });
  }
  saveCircle(g: ImportGeofence) {
    let circleGeofenceFormatToSave: CircGeoFormatToSave = {
      nombre: g.zone_name ?? '',
      descripcion: !g.descripcion.trim() ? g.orden ?? '' : g.descripcion ?? '',
      radio:
        parseFloat(g.geo_coordenadas.replace(/[<>()]/g, '').split(',')[2]) /
        1000,
      area: 0,
      perimetro: 0,
      categoria: 0,
      color: g.zone_color ?? '#000000',
      nombre_visible_zona: g.zone_visible ?? false,
      zone_no_int_color: false,
      tag_name_color: g.tag_name_color ?? '',
      tag_name_font_size: g.tag_name_font_size ?? 0,
      geo_geometry: `<${g.geo_coordenadas ?? ''}>`,
      checkVelocidad: g.vel_act_zone ?? false,
      visible_zona: g.zone_visible ?? false,
      limite_velocidad: g.vel_max ?? 0,
      limite_tolerable: g.vel_zona ?? 0,
      limite_grave: g.vel2_zona ?? 0,
      limite_muy_grave: g.vel3_zona ?? 0,
      id_operation: g.idoperation ?? 0,
      id_grupo: 0,
      tags: g.tags.join(','),
    };
    if (circleGeofenceFormatToSave.radio != 10) {
      this.circularGeofencesService
        .store({
          ...circleGeofenceFormatToSave,
          visible_zona: circleGeofenceFormatToSave.visible_zona
            ? 'false'
            : 'true',
        })
        .subscribe((resp: any) => {
          console.log(resp);
          this.geofencesService.nameComponentPol = 'LISTAR';
          if (resp[1] == 'Registro Guardados') {
            console.log('suseed');
          }
          let gNew = resp[2];
          let gNew2 = resp[3][0];
          var geo: any = {};
          geo.id = gNew.id;
          geo.descripcion = gNew.descripcion;
          geo.orden = gNew.var_nombre;
          geo.tag_name_color = gNew.tag_name_color;
          geo.tag_name_font_size = gNew.tag_name_font_size;
          geo.zone_name_visible = gNew.bol_mostrar_nombre;
          geo.zone_name_visible_bol = gNew.zone_name_visible == 'true';
          geo.zone_visible = gNew.bol_mostrar;
          geo.geo_coordenadas = gNew2.geo_coordenadas;
          geo.zone_color = gNew.var_color;
          geo.zone_name = gNew.var_nombre;
          geo.zone_cat = gNew.int_categoria;
          geo.vel_act_zona = gNew.bol_limite_velocidad_activo;
          geo.vel_max = gNew.int_limite_velocidad_0;
          geo.vel_zona = gNew.int_limite_velocidad_1;
          geo.vel2_zona = gNew.int_limite_velocidad_2;
          geo.vel3_zona = gNew.int_limite_velocidad_3;
          geo.zone_no_int_color = true;
          geo.idoperation = gNew.operation_grupo_id ?? 0;
          geo.nameoperation = this.getNameOperation(geo.idoperation);
          geo.tags = gNew.geo_tags;
          geo.geo_elemento = new L.Circle(
            this.circularGeofencesService.getCoordenadas(geo.geo_coordenadas),
            {
              radius: this.circularGeofencesService.getRadius(
                geo.geo_coordenadas
              ),
              weight: 3,
              fill: true,
              color: geo.zone_color,
            }
          ).addTo(this.mapService.map);

          this.circularGeofencesService.bindMouseEvents(geo);

          let bg_color = this.circularGeofencesService
            .tooltipBackgroundTransparent
            ? this.circularGeofencesService.defaultTagNameBackground
            : this.mapService.hexToRGBA(geo.zone_color);
          let txt_color = this.circularGeofencesService
            .tooltipBackgroundTransparent
            ? geo.tag_name_color == ''
              ? this.circularGeofencesService.defaultTagNameColor
              : geo.tag_name_color
            : this.mapService.hexToRGBA(geo.zone_color);
          let font_size =
            (geo.tag_name_font_size == 0
              ? this.circularGeofencesService.defaultTagNameFontSize
              : geo.tag_name_font_size) + 'px';

          geo.marker_name = L.circleMarker(
            this.circularGeofencesService.getCoordenadas(geo.geo_coordenadas),
            {
              radius: 0,
              fillColor: '#000', //color,
              fillOpacity: 1,
              color: '#000', //color,
              weight: 1,
              opacity: 1,
            }
          ).bindTooltip(
            '<b class="" style="background-color: ' +
              bg_color +
              '; color : ' +
              txt_color +
              '; font-size: ' +
              font_size +
              '">' +
              geo.zone_name +
              '</b>',
            {
              permanent: true,
              direction: 'center',
              className: 'leaflet-tooltip-own',
            }
          );

          geo.marker_name.addTo(this.mapService.map);

          this.circularGeofencesService.circular_geofences.push(geo);
          this.spinner.hide('spinnerLoading');
          this.circularGeofencesService.initializeTable(geo.id);
          this.circularGeofencesService.updateGeoCounters();
          this.circularGeofencesService.updateGeoTagCounters();
          this.circularGeofencesService.eyeInputSwitch =
            this.circularGeofencesService.circularGeofenceCounters.visible != 0;
          this.circularGeofencesService.tagNamesEyeState =
            this.circularGeofencesService.circularGeofenceCounters.visible != 0;
          this.spinner.hide('spinnerLoading');
          this.geofenceImported.emit();
        });
    }
    console.log('geoImp save', circleGeofenceFormatToSave);
    // return circleGeofenceFormatToSave;
    // return {
    //   area: '0.00',
    //   categoria: 0,
    //   checkVelocidad: geoData.speed ? geoData.speed : false,
    //   color: geoData.essential.color
    //     ? `#${geoData.essential.color}`
    //     : '#000000',
    //   descripcion: geoData.essential.description
    //     ? geoData.essential.description
    //     : '',
    //   geo_geometry: geoData.latLngRadio ? geoData.latLngRadio : '',
    //   // '<(-6.851131456225328,-68.15917968750001),125009.55042792406>',
    //   id: 0,
    //   limite_grave: geoData.zone2_speed ? geoData.zone2_speed : 0,
    //   limite_muy_grave: geoData.zone3_speed ? geoData.zone3_speed : 0,
    //   limite_tolerable: geoData.zone_speed ? geoData.zone_speed : 0,
    //   limite_velocidad: geoData.max_zone_speed ? geoData.max_zone_speed : 0,
    //   nombre: geoData.essential.name ? geoData.essential.name : '',
    //   nombre_visible_zona: 'true',
    //   perimetro: '0.00',
    //   radio: geoData.latLngRadio
    //     ? (
    //         parseFloat(
    //           geoData.latLngRadio.replace(/[<>()]/g, '').split(',')[2]
    //         ) / 1000
    //       ).toFixed(2)
    //     : '0',
    //   tag_name_color: '#000000',
    //   tag_name_font_size: 10,
    //   visible_zona: 'true',
    //   zone_no_int_color: true,
    //   zone_type: 'POLYGON',
    // };
  }
  /**
   *
   * @param longitud :number, longitud de una coordenada
   * @param latitud :number, latitud de una coordenada
   * @param radio :number, radio en metros
   * @returns :string, coordenadas(lat, lng, alt) en formato kml
   */
  genCircCoords(longitud: number, latitud: number, radio: number): string {
    let geoPoints: string = '';
    let x0 = 0;
    let y0 = 0;
    const points = Math.max(
      Math.min(((radio - 1) * (100 - 20)) / (1000000 - 1) + 20, 100),
      20
    );
    radio = (radio / 6371000) * (180 / Math.PI);

    for (let i = 0; i < points; i++) {
      const angulo = (i * (2 * Math.PI)) / points;
      const x = longitud + radio * Math.cos(angulo);
      const y = latitud + radio * Math.sin(angulo);
      if (i == 0) {
        x0 = x;
        y0 = y;
      }
      geoPoints += `${y},${x},0 `;
    }
    geoPoints += `${y0},${x0},0 `;
    return geoPoints;
  }
  getCoordenadas(data: any) {
    let coo = [];
    for (let i = 0; i < data.length; i++) {
      coo.push([data[i][1], data[i][0]]);
    }
    return coo;
  }
  getCircleArea(radius: any) {
    radius = parseFloat(radius);
    let area = Math.PI * Math.pow(radius, 2);
    return Math.abs(area);
  }
}
