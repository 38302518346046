<p-dialog [(visible)]="driversService.modalProgrammingActive" [modal]="true" [resizable]="true" [style]="{width: '50vw', 'max-height': '70vh', 'overflow': 'auto'}">

  <ng-template class="p-dialog-title" pTemplate="header">
    <div>
      <i class="fas fa-user-clock me-2" aria-hidden="true"></i>
      <strong [innerHTML]="titleModal"></strong>
    </div>
  </ng-template>

  <ng-template class="p-dialog-content" pTemplate="content">

    <!-- TabView con estilos flex -->
    <p-tabView [(activeIndex)]="this.driversService.activeTabIndex" (onChange)="changeTabView()">
      <p-tabPanel header="Lista de Programaciones" leftIcon="pi pi-list">
        <app-drivers-programming-list (actionEdit)="onActionEdit()"></app-drivers-programming-list>
      </p-tabPanel>
      <p-tabPanel [header]="this.driversService.modalProgrammingAction + ' Programación'" [leftIcon]="(this.driversService.modalProgrammingAction=='Agregar') ? 'pi pi-plus' : 'pi pi-pencil'">
        <ng-container *ngIf="isEditProgramming">
          <app-drivers-programming-add></app-drivers-programming-add>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel header="Importar Programación" leftIcon="pi pi-upload">
        <app-drivers-programacion-importar></app-drivers-programacion-importar>
      </p-tabPanel>
    </p-tabView>

  </ng-template>

</p-dialog>
