import { Component, OnInit } from '@angular/core';

import { RepProgramadosService } from '../../services/rep-programados.service';


import * as moment from 'moment';

@Component({
  selector: 'app-rp-show',
  templateUrl: './rp-show.component.html',
  styleUrls: ['./rp-show.component.scss']
})
export class RpShowComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
  ) { }


  form: any;
  reporteProgramado:any;

  ngOnInit(): void {

    this.reporteProgramado = this.repProgramadosService.reporteProgramado;

    console.log("======================================================");
    console.log(this.reporteProgramado);
    
    this.reporteProgramado.unidades     = JSON.parse(this.reporteProgramado.p_vehiculos);
    this.reporteProgramado.hora_inicio  = moment(new Date(this.reporteProgramado.p_fecha_desde)).format("HH:mm");
    this.reporteProgramado.hora_fin     = moment(new Date(this.reporteProgramado.p_fecha_hasta)).format("HH:mm");
    this.reporteProgramado.hora_programacion = moment(new Date(this.reporteProgramado.intervalo_hora)).format("HH:mm");

  }

}
