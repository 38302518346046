<p-dialog [(visible)]="driversService.modalEnrollmentActive" [modal]="true" [resizable]="true">

  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingListDriverEnroll" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Esperando respuesta del Dispositivo ... </p></ngx-spinner>

  <ng-template pTemplate="header" class="p-dialog-title"
    >Enrolamiento de Conductores - CIPIA</ng-template
  >
  <div class="p-0" style="width: 700px">
    <div class="row mb-4">
      <div class="col p-field">
        <label class="row gl-row-underline">
          <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
            <label for="list-drivers">Seleccionar un Vehículo :</label>
          </div>
          <div class="col-7 text-center">
            <div class="p-field">
              <p-dropdown
                [autoDisplayFirst]="false"
                [filter]="true"
                [options]="vehicles"
                optionValue="IMEI"
                optionLabel="name"
                [(ngModel)]="selectedVehicle"
                filterBy="name"
                placeholder="Búsqueda por Vehículo"
                (onChange)="listedByDriver()"
                [appendTo]="'body'"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
        </label>
      </div>
    </div>
    <div class="flex-column mt-3">
      <div class="row gl-listbox-container">
        <div class="col">
          <h5>Lista General de Conductores </h5>
          <p-listbox
            [options]="listDriversFull"
            [(ngModel)]="selectedDrivers"
            [multiple]="false"
            [checkbox]="true"
            [filter]="true"
            emptyFilterMessage="No hay resultados de su busqueda"
            emptyMessage="Listado de Conductores vacío"
            filterPlaceHolder="Buscar Conductor por nombre"
            optionLabel="name_driver"
            [listStyle]="{ height: '200px' }"
          >
            <!-- <ng-template pTemplate="header">
              Lista General de Conductores
            </ng-template> -->
            <ng-template let-driver pTemplate="item">
              <label style="font-size: 12px;">{{ driver.name_driver + ' - ' + driver.key_number }}</label>
            </ng-template>
          </p-listbox>
          <small class="p-2"><i class="fas fa-list"></i>&nbsp;Conductores Disponibles ({{ listDriversFull.length }})</small>
        </div>
        <div class="col-1 d-flex flex-column justify-content-center align-items-center">
          <div style="height: 1.5rem"></div>
          <div class="">
            <button
              (click)="sendListDriversToEnrollment()"
              type="button"
              class="btn-gl-light-blue btn-circle"
              name="button"
              [disabled]="!(this.listDriversFull.length>0)"
              [pTooltip]="'Enrolar Conductor'"
            >
              <span>
                <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
              </span>
            </button>
            </div>
          <div style="height: 1rem"></div>
          <div class="">
            <button
              (click)="returnListDriversFull()"
              type="button"
              class="btn-gl-light-blue btn-circle"
              name="button"
              [disabled]="!(this.listDriverEnrolled.length>0)"
              [pTooltip]="'Remover Conductor'"
            >
              <span>
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="col">
          <h5>Lista de Conductores Enrolados</h5>
          <p-listbox
            [options]="listDriverEnrolled"
            [(ngModel)]="selectedDriversEnrolled"
            [multiple]="false"
            [checkbox]="true"
            [filter]="true"
            emptyFilterMessage="No hay resultados de su busqueda"
            emptyMessage="Listado de Conductores vacío"
            filterPlaceHolder="Buscar Conductor por nombre"
            optionLabel="name_driver"
            [listStyle]="{ height: '200px' }"
          >
            <!-- <ng-template pTemplate="header">
              Lista de Conductores Enrolados
            </ng-template> -->
            <ng-template let-driver pTemplate="item">
              <label style="font-size: 12px;">{{ driver.name_driver + ' - ' + driver.key_number }}</label>
            </ng-template>
          </p-listbox>
          <small class="p-2"><i class="fas fa-user-check"></i>&nbsp;Conductores Enrolados ({{ listDriverEnrolled.length }})<i *ngIf="driversUnknown>0" class="fas fa-exclamation-triangle fa-lg" style="color: red;" [pTooltip]="this.messageDriversUnknown" ></i></small>
        </div>
      </div>
    </div>
  </div>


  <ng-template class="p-dialog-content" pTemplate="content">

    <div class="row justify-content-center mb-4 pt-2 panel-izq-container-footer">
      <div class="col-4">
          <button type="button" class="btn-gl-cancel btn-dark-hover w-100" (click)="driversService.modalEnrollmentActive=false">Cerrar</button>
      </div>
    </div>
  </ng-template>

</p-dialog>
