<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingReportesProgramadosAdd" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" class="text-center"> {{ fullScreenSpinnerMsg }} </p></ngx-spinner>

<p-dialog [(visible)]="repProgramadosService.modal_add0" [style]="{ width: '50%', 'font-size': '0.9rem'  }" >

    <ng-template pTemplate="header" class="p-dialog-title" >
      <div>
          <i class="fa fa-plus me-2" aria-hidden="true" ></i>
          <strong>Agregar Nuevo Reporte Programado</strong>
      </div>
    </ng-template>
    
    <ng-template pTemplate="body">

        <div class="row">
            <div class="col-2 d-flex align-items-center justify-content-start text-start fw-bold">
                <label >Reporte:</label>
            </div>
            <div class="col-10">
                <!-- <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" optionValue="id" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." (onChange)="resetAllFields(); changedReport(); validateForm();" [disabled]="workingOnReport"></p-dropdown> -->
                <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" [filter]="true" filterBy="value" filterPlaceholder="Buscar..." emptyFilterMessage="No se encontró reporte para el filtro." optionValue="codigo" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." (onChange)="resetAllFields(); changedReport(); validateForm();"  ></p-dropdown>
            </div>
        </div>
  
    </ng-template>
  
    <ng-template pTemplate="footer" style="width: 60vw; padding-top: 0;">
      <p-button label="Agregar" icon="pi pi-plus" (click)="agregarReporteProgramado();" styleClass="p-button-secondary"></p-button>
    </ng-template> 
  
</p-dialog>
  

<p-dialog [(visible)]="repProgramadosService.modalReportesProgramadosActiveAddEdit" [style]="{ width: '50%', 'font-size': '0.9rem' }" [modal]="true" styleClass="p-fluid">

    <ng-template pTemplate="header" class="p-dialog-title" >
      <div>
          <i class="fa fa-plus me-2" aria-hidden="true" *ngIf="modal_add"></i>
          <i class="fa fa-edit me-2" aria-hidden="true" *ngIf="!modal_add"></i>
          <strong>{{repProgramadosService.titulo_modal_add_edit}}</strong>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
        <!-- <img [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.image" class="block m-auto pb-3" *ngIf="product.image" /> -->
        
        <!-- <p-card> -->

          <!-- <ng-template pTemplate="header">
            <img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />
          </ng-template> -->

          <div class="row">
            <div class="col p-field">
              <label class="row ">

                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Informe:</label>
                  </div>
                  <div class="col-9 ">
                    <div>
                      <label>
                        <strong>{{form.nombre_reporte}}</strong>
                      </label>
                    </div>
                  </div>
                  
              </label>
            </div>
          </div>


          <div class="row">
            <div class="col p-field">
              <label class="row ">

                <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label>Activo:</label>
                </div>
                <div class="col-9">
                    <p-checkbox
                      inputId="binary"
                      [binary]="true"
                      [(ngModel)]="form.bol_activo"
                    ></p-checkbox>
                </div>

              </label>
            </div>
          </div>




          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Nombre del informe:</label>
                  </div>
                  <div class="col-9 ">
                    
                      <div class="gl-row-underline">
                          <input
                          type="text"
                          size="70"
                          placeholder="Nombre del informe"
                          pInputText
                          [(ngModel)]="form.nombre_programacion"
                          />
                      </div>
       
                  </div>
              </label>
            </div>
          </div>


          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Vehículos:</label>
                  </div>
                  <div class="col-9">

                    <div class="gl-row-underline">
                      <p-multiSelect [options]="vehicles" [(ngModel)]="form.p_vehiculos" optionLabel="name" [showToggleAll]="true" [appendTo]="'body'" placeholder="Seleccione..." [selectedItemsLabel]="'{0} vehiculos seleccionados'"
                      ></p-multiSelect>
                    </div>

                  </div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row">

                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Hora del dia:</label>
                  </div>

                  <!-- <div class="col-9">
                    <input
                      type="text"
                      size="70"
                      placeholder="Nombre del informe"
                      pInputText
                      [(ngModel)]="form.nombre"
                    />
                  </div> -->

                  <div class="col-2">
                    <div class="p-field  gl-row-underline">
                        <p-calendar
                          [(ngModel)]="form.p_fecha_desde"
                          [timeOnly]="true"
                          [appendTo]="'body'"
                          [disabled]="false"
                          ></p-calendar>
                    </div>
                  </div>

                  <div class="col-1 d-flex align-items-center justify-content-start text-start fw-bold">
                    -
                  </div>

                  <div class="col-2">
                    <div class="p-field  gl-row-underline">
                        <p-calendar
                          [(ngModel)]="form.p_fecha_hasta"
                          [timeOnly]="true"
                          [appendTo]="'body'"
                          [disabled]="false"
                          ></p-calendar>
                    </div>
                  </div>



                  <div class="col-4 ">
                    
                  </div>

              </label>
            </div>
          </div>

          <!-- <div>-----------------------------------------------------------</div> -->

          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-12 ">
                      <div class="gl-row-underline">
                        <label></label>
                      </div>
                  </div>
              </label>
            </div>
          </div>
          <br>


          <div class="row" style="padding: 0px 0.3rem">
            <div class="col-6">
              <label>
                <p-checkbox name="groupname" [binary]="true" [(ngModel)]="form.p_chkDateHour" (onChange)="onChkDateHourChange(); validateForm();"></p-checkbox>
                Fecha/Hora</label>
            </div>
          </div>

          <br>

          <div class="row" style="padding: 0px 0.3rem">
       
            <ng-container *ngIf="showMovStop">
            <!-- <ng-container *ngIf="true"> -->

              <div class="col-6">
                <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="form.p_chkStops" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="workingOnReport"></p-checkbox>
                  Paradas</label>
                </div>
              </div>
              <div class="col-6">
                <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="form.p_chkMovements" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="workingOnReport"></p-checkbox>
                  Movimientos</label>
                </div>
              </div>
            </ng-container>

          </div>


          <div *ngIf="showExcVelOpt">
          <!-- <div *ngIf="true"> -->

            <div class="row">
              <div class="p-field">
                <div class="d-flex p-dropdown-full-width">
                  <p-dropdown [options]="pDropdownDuration" [(ngModel)]="form.p_checkboxDuration" [showClear]="false" optionLabe="label" optionValue="value" (onChange)="validateForm();" [styleClass]="'bg-subtitle'" [disabled]="workingOnReport"></p-dropdown>
                </div>
                <p-inputNumber *ngIf="!checkboxDuration" [(ngModel)]="form.p_limitSpeed" [showButtons]="true" [min]="0" [max]="300" suffix=" km/h" inputId="limiteVelocidad" [disabled]="workingOnReport"></p-inputNumber>
                <p-inputNumber *ngIf="checkboxDuration" [(ngModel)]="form.p_minimDur" [showButtons]="true" [min]="0" suffix=" seg" inputId="minimaDuracion" [disabled]="workingOnReport"></p-inputNumber>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="showZones">
          <!-- <div class="row" *ngIf="true"> -->
            <div class="p-field">
              <label class="bg-subtitle" id="zone_label">Geocercas :</label>
              <p-multiSelect [options]="zones" [(ngModel)]="form.p_zonas" optionLabel="zone_name" optionValue="id" [showToggleAll]="false" [appendTo]="'body'" placeholder="Seleccione la(s) geocerca(s)" (onChange)="onSelectedGeofenceschange(); validateForm();" [disabled]="workingOnReport"></p-multiSelect>
              <div style="padding: 0px 0.3rem; margin-top: 0.25rem; text-align: right;">
                <label>
                  <p-checkbox [(ngModel)]="chkAllZones" [binary]="true" inputId="binary" (onChange)="selectAllZones(); validateForm();" [disabled]="!areZonesLoaded || workingOnReport"></p-checkbox>
                  Todos
                </label>
              </div>
            </div>
          </div>





          <div class="row two-column-checkbox-container" *ngIf="showTipoExcesoVelocidad">
          <!-- <div class="row two-column-checkbox-container" *ngIf="true"> -->

            <div class="col-12">
              <label class="bg-subtitle">Seleccione el tipo de reporte de exceso:</label>
            </div>
    
            <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label class="bg-subtitle" >Duración:</label>
                    <!-- <p-dropdown [options]="listOptionsDuracion" [(ngModel)]="selectedDuracion" [showClear]="false" optionLabel="" optionValue="valueOption" (onChange)="onOptionSelected();validateForm();" [disabled]="workingOnReport"></p-dropdown> -->
                    <p-dropdown [options]="listOptionsDuracion" [(ngModel)]="form.p_selectedDuracion" optionLabel="name" [showClear]="true"  (onChange)="onchangeDuracion();" placeholder="Seleccionar Duración"></p-dropdown>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                    <label class="bg-subtitle" >Tolerancia:</label>
                    <p-dropdown [options]="listOptionsToleracia" [(ngModel)]="form.p_selectedToleracia" optionLabel="name" [showClear]="true" placeholder="Selecciona Tolerancia"></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                    <div class="p-field">
                        <label><p-checkbox name="groupname" [binary]="true" [(ngModel)]="form.p_chkRiesgo"></p-checkbox>Nivel de Riesgo</label>
                    </div>
                </div>
                <div class="col-6" >
                </div>
              </div>
            </div>
    
          </div>

          <div class="row two-column-checkbox-container" *ngIf="showTipoExcesoVelocidad">
            <div class="col-12">
              <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
            </div>
    
            <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">
    
       
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Fecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Fecha</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Hora" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Hora</label>
                  </div>
                </div>
              </div>
    
    
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Placa</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Código Interno</label>
                  </div>
                </div>
              </div>
    
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      ID Conductor</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Conductor</label>
                  </div>
                </div>
              </div>
          
    
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. Mobileye</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                        <label>
                          <p-checkbox [(ngModel)]="form.p_ec.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                          Vel. GPS speed</label>
                  </div>
                </div>
              </div>
    
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="form.p_ec.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                        Vel. CAN</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="form.p_ec.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                        Geocerca</label>
                  </div>
                </div>
              </div>
    
              <div class="row">
                <div class="col-6">
                  <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="form.p_ec.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                        Ubicación</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="form.p_ec.Satelite" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                        Satélite</label>
                  </div>
                </div>
              </div>
    
            </div>
          </div>




      <div class="row" *ngIf="showEvents || showEventsCipia || showAtencionEventsCipia">
        <div class="p-field">
          <label class="bg-subtitle" id="tag_label">Etiquetas de Geocercas :</label>
          <p-multiSelect [options]="repProgramadosService.listTags" [(ngModel)]="form.p_tags_zonas" optionLabel="var_name" optionValue="id" [showToggleAll]="false" [appendTo]="'body'" placeholder="Seleccione la(s) etiqueta(s)" (onChange)="onSelectedTagschange(); validateForm();" [disabled]="workingOnReport"></p-multiSelect>
          <div style="padding: 0px 0.3rem; margin-top: 0.25rem; text-align: right;">
            <label>
              <p-checkbox [(ngModel)]="chkAllTags" [binary]="true" inputId="binary" (onChange)="selectAllTags(); validateForm();" [disabled]="workingOnReport"></p-checkbox>
              Todos
            </label>
          </div>
        </div>
      </div>

      <br>

      <div class="row two-column-checkbox-container" *ngIf="showEvents || showEventsCipia || showAtencionEventsCipia">
        <div class="col-12">
          <label class="bg-subtitle">Seleccione los eventos a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

        <ng-container *ngFor="let type of repProgramadosService.eventsTypes">
          <ng-container *ngIf="type.active">
            <div class="row">
              <div class="col-12">
                <label class="bg-subtitle bg-subtitle-light">Eventos {{type.label}}
                  <!-- <p-checkbox [(ngModel)]="type.selectAll" [binary]="true" inputId="binary" (onChange)="onSelectAllChange(type)" [disabled]="reportService.workingOnReport"></p-checkbox> -->
                </label>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let event of repProgramadosService.events">
                <ng-container *ngIf="event.event_category == type.name">
                  <div class="col-6" style="margin-top: 5px" >
                    <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="event.active" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                        {{ event.name_event }}
                      </label>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
         
          <div class="row">
            <div class="col-6">
              <div class="p-field">
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
              </div>
            </div> 
          </div> 

        

        </div>
      </div>




          <!-- <div class="row two-column-checkbox-container" *ngIf="true"> -->
          <div class="row two-column-checkbox-container" *ngIf="showCheckboxs">

            <div class="col-12">
              <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
            </div>
    
            <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">
    
              <div class="row">
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.fServidor" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    Fecha Servidor</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.pCercano" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Punto Cercano</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.velCAN" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Velocidad CAN</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.velGPS" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Velocidad GPS</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.velGPS_speed" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Velocidad GPS_speed</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.velMobileye_ME460" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Velocidad Mobileye</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.velECO" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Velocidad ECO</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.DUOT2state" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    DUOT2 state</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.RxM" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      RPM</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.RPMAlta" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      RPM Alta</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.cNivel" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Nivel de Combustible</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.cRestante" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Combustible Restante</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.cMotor" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Consumo de Motor</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.odometro" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Odómetro</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.altitud" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Altitud</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.angulo" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Angulo</label>
                  </div>
                </div>
                <!-- ALIMENTACION GPS -->
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.alimentGps" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Alimentación GPS</label>
                  </div>
                </div>
                <!-- NIVEL DE BATERIA -->
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.nivelBateria" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Nivel de batería</label>
                  </div>
                </div>
                <!-- NIVEL DE COBERTURA -->
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.nivelCobertura" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Nivel de cobertura</label>
                  </div>
                </div>
                <!-- NIVEL DE TEMPERATURA GPS -->
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.temperaturaGps" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Temperatura GPS</label>
                  </div>
                </div>
                <!-- SATELITE -->
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.satelite" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Satélite</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.recFacial" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Reconocimiento Facial</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.onOff" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    Ignición</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.alcoholemia" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    Alcoholemia</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.parametros" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    Parámetros</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.ubicacion" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      Ubicación</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.referencia" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                    Referencia</label>
                  </div>
                </div>
              </div>
              <!-- ON / OFF AHORA ES IGNICION?? -->
              <!-- <div class="row">
                <div class="col-6">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_og.onOff" [binary]="true" inputId="binary" [disabled]="workingOnReport"></p-checkbox>
                      On / Off</label>
                  </div>
                </div>
              </div> -->
            </div>
    
          </div>

          <div class="row two-column-checkbox-container" *ngIf="showEvents || showEventsCipia">
            <div class="col-12">
              <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
            </div>
    
            <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">
    
       
              <div class="row">
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Fecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Fecha</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.FechaServidor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Fecha Servidor</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Evento" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Descripción</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Código</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Placa</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.TipoUnidad" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Tipo de Unidad</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      ID Conductor</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Conductor</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. Mobileye</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelGPS" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. GPS</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. GPS speed</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. CAN</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.VelECO" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Vel. ECO</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Geocerca</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.PuntoCercano" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Punto Cercano</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Ubicación</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Referencia" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Referencia</label>
                  </div>
                </div>
                <div class="col-6" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.EnlaceArchivo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Enlace a Archivo</label>
                  </div>
                </div>
                <div class="col-6" *ngIf="selectedReport == 'R040'" style="margin-top: 5px">
                  <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="form.p_ec.Parametros" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="workingOnReport"></p-checkbox>
                      Parámetros</label>
                  </div>
                </div>
              </div>
    
    
            </div>
          </div>

          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-12 ">
                      <div class="gl-row-underline">
                        <label></label>
                      </div>
                  </div>
              </label>
            </div>
          </div>
          <br>

          <!-- 
          <div class="row">
            <div class="col-3">
              <label>HORA PROGRAMADA:</label>
            </div>
            <div class="col-3 gl-row-underline">
              <div class="p-field">
                  <p-calendar
                    [(ngModel)]="form.hora_programado2"
                    [timeOnly]="true"
                    [appendTo]="'body'"
                    [disabled]="false"
                    ></p-calendar>
              </div>
            </div>
            <div class="col-6">
            </div>
          </div> -->
          
          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Intervalo:</label>
                  </div>
                  <div class="col-3 ">
                      <div class="gl-row-underline">
                          <p-dropdown [options]="pDropdownIntervalos" [(ngModel)]="form.intervalo" [showClear]="false" optionLabe="label" optionValue="value" [styleClass]="'bg-subtitle'" ></p-dropdown>
                      </div>
                  </div>     

                  <div class="col-3 ">
                      <div class="p-field gl-row-underline">

                          <p-calendar
                          [(ngModel)]="form.intervalo_hora"
                          [timeOnly]="true"
                          [appendTo]="'body'"
                          [disabled]="false"
                          ></p-calendar>

                      </div>
                  </div>    




                  <div class="col-3">
                    <label><em> El informe se enviará todas las mañanas a la hora elegida </em></label>
                  </div>

              </label>
            </div>
          </div>


          <div class="row"  *ngIf="this.form.intervalo == 'semanal'">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <!-- <label>Periódo días:</label> -->
                  </div>
                  <div class="col-3 ">
                      <div class="gl-row-underline">
                          <p-dropdown [options]="pDropdownDiasSemana" [(ngModel)]="form.periodo_dias_semanal" [showClear]="false" optionLabe="label" optionValue="value" [styleClass]="'bg-subtitle'" ></p-dropdown>
                      </div>
                  </div>     
                  <div class="col-6">
                  </div>
              </label>
            </div>
          </div>

          <div class="row" *ngIf="this.form.intervalo == 'mensual'">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Periódo días:</label>
                  </div>
                  <div class="col-3 ">
                      <div class="gl-row-underline">
                          <p-dropdown [options]="pDropdownDiasMes" [(ngModel)]="form.periodo_dias_mensual" [showClear]="false" optionLabe="label" optionValue="value" [styleClass]="'bg-subtitle'" ></p-dropdown>
                      </div>
                  </div>     
                  <div class="col-6">
                  </div>
              </label>
            </div>
          </div>



          <div class="row">
            <div class="col p-field">
              <label class="row ">
                  <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Formatos disponibles:</label>
                  </div>
                  <div class="col-3 ">
                      <div class="gl-row-underline">
                          <p-dropdown [options]="pDropdownFormatoExport" [(ngModel)]="form.formato_exportacion" [showClear]="false" optionLabe="label" optionValue="value" [styleClass]="'bg-subtitle'" ></p-dropdown>
                      </div>
                  </div>     
                  <div class="col-6">
                  </div>
              </label>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col p-field">
              <label class="row">
                <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                  <label>Recibir informe:</label>
                </div>
                <div class="col-9">
                    <p-checkbox
                      inputId="binary"
                      [binary]="true"
                      [(ngModel)]="form.notificacion_email"
                    ></p-checkbox>
                </div>
              </label>
            </div>
          </div>


          <div class="row">
            <div class="col p-field">
              <label class="row">
                <div class="col-3 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label>Lista e-mails:</label>
                </div>
                <div class="col-9">
                  <div class="gl-row-underline">
                    <input
                      type="text"
                      size="70"
                      placeholder="correo-1@mail.com,correo-2@mail.com..."
                      pInputText
                      [disabled]="!form.notificacion_email"
                      [(ngModel)]="form.notificacion_email_lista"
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>

          <!-- emails -->
          <!-- <div class="row d-flex justify-content-center">
            <div class="col-5">
              <label class="row">
                <span class="col-9 form-check-label">Agregar Correo:</span>
                <div class="col-2 d-flex flex-column justify-content-center">
                  <div class="form-check">
                    <p-checkbox
                      inputId="binary"
                
                      [binary]="true"
                      [(ngModel)]="form.notificacion_email"
                
                    ></p-checkbox>
                  </div>
                </div>
              </label>
            </div>
            <div class="col-5"></div>
          </div> -->

          <div class="row"></div>

          <!-- <div class="form-group row">
            <div class="col-12 d-flex">
              <input
                type="text"
                placeholder="ejemplo@mail.com"
                class="flex-grow-1"
                pInputText
                [(ngModel)]="form.notificacion_email_lista"
                name="email"
                (keydown.enter)="addEmail()"
              />
              <button
                type="button"
                class="btn-gl-clear flex-none ms-2"
                (click)="addEmail()"
                [disabled]="!alertForm.value.chkCorreo"
              >
                <i
                  class="fa fa-plus-circle fa-2x icon-gl-blue"
                  data-placement="bottom"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div> -->

          <!-- <div class="form-group row">
            <div class="col-9">
              <div class="text">
                <ul>
                  <li
                    *ngFor="
                      let email of alertForm.value.lista_emails;
                      let i = index
                    "
                    ng-bind-html-unsafe="opt"
                  >
                    {{ email }}
                    <i
                      class="ms-1 fa fa-minus-circle"
                      (click)="restEmail(i)"
                      type="button"
                      data-placement="bottom"
                      aria-hidden="true"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- fin emails -->


          <!-- <div class="row">
              <div class="col-3">
                  Nombre:
              </div>
              <div class="col-9">
                  <input type="text" pInputText [(ngModel)]="form.nombre" />            
              </div>
              
              <div class="col-3">
                  Diariamente:
              </div>
              <div class="col-9">
                  <p-autoComplete [(ngModel)]="form.hora_programado" [virtualScroll]="true" [suggestions]="filteredItems" [virtualScrollItemSize]="34" (completeMethod)="filterItems($event)" field="label" [dropdown]="true"> </p-autoComplete>
              </div>
          </div> -->



        <!-- </p-card> -->
         <br>
         <br>
         <br>





    </ng-template>

    <ng-template pTemplate="footer">
      <p-button label="Guardar" icon="pi pi-check" (click)="guardarReporteProgramado();"></p-button>
      <p-button label="Cancelar" icon="pi pi-times" (click)="cancelarReporteProgramado();" styleClass="p-button-secondary" [style]="{ 'margin-left': '.5em' }"></p-button>
        <!-- <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button> -->
        <!-- <button pButton pRipple label="Ok" icon="pi pi-check" class="p-button-text" (click)="reportService.modalReportesProgramadosActiveDetalle = false;"></button> -->
    </ng-template>
</p-dialog>
