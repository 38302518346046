
<div class="table-content-component-panel-monitoreo" style="position: relative;">

  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingPlatformAlertsSpinner" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Cargando Conductores Plataforma... </p></ngx-spinner>

  <div class="card-body p-fluid" id="rowBusqueda">
    <div class="d-flex">
      <div class="d-flex flex-grow-1">
        <span class="p-input-icon-right flex-grow-1 me-2">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input (keyup)="onSearchDriver()" [(ngModel)]="searchValueDriver" type="text" id="searchKey" class="form-control"  class="form-control ng-pristine ng-untouched ng-valid ng-empty" aria-invalid="false" placeholder="Buscar por Nombre / Placa / Ibutton..." />
        </span>
      </div>

      <button *appHasPermission="'drivers_create'" (click)="clickAgregarDriver()" type="button" class="btn-gl-blue btn-circle btn-dark-hover flex-none me-2" title="Agregar Conductor" data-bs-toggle="tooltip" data-bs-placement="auto" container="body" onclick="this.blur();">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button>

      <div ngbDropdown container="body" placement="right-top left-top" class="flex-none">
        <button class="btn-gl-blue btn-dark-hover btn-circle" ngbDropdownToggle>
          <i class="fas fa-fw fa-bars fa-1" aria-hidden="true"></i>
        </button>
        <div ngbDropdownMenu class="gl-custom-dropdown dropdown-alert-panel">
          <!-- <div ngbDropdownItem (click)="openProgrammingModal()">
            <span>Programación de Conductores</span>
          </div> -->
          <div ngbDropdownItem (click)="openEnrollmentModal()">
            <span>Enrolamiento Cipia</span>
          </div>
        </div>
      </div>

      <!-- <div ngbDropdown container="body" placement="right-top left-top" class="flex-none">
        <button class="btn-gl-gray-blue btn-dark-hover btn-circle" ngbDropdownToggle>
          <i class="fa fa-fw fa-bars fa-1" aria-hidden="true"></i>
        </button>
        <div ngbDropdownMenu class="gl-custom-dropdown dropdown-alert-panel">

          <div ngbDropdownItem (click)="clickAgregarDriver()" >
            <span>Agregar Conductor</span>
          </div>
          <div ngbDropdownItem (click)="clickAgregarProgramacion()" >
            <span>Agregar Programacion</span>
          </div>
          <div ngbDropdownItem (click)="clickImportarProgramacion()" >
            <span>Importar Programacion</span>
          </div>
          <div ngbDropdownItem (click)="clickProgramacionHistorico()" >
            <span>Programaciones Registradas</span>
          </div>

        </div>
      </div> -->


    </div>
  </div>

  <div class="panel-izq-table-container" style="flex-grow: 0;">
    <table id="tbl_geos">
      <colgroup>
        <col width="5%" />
        <col width="25%" />
        <col width="20%" />
        <col width="40%" />
        <!-- <col width="20%" /> -->
        <col width="10%" />

      </colgroup>
      <thead class="visible-on-scroll">
          <tr>
              <th>Nº</th>
              <!-- <td></td> -->
              <th class="text-start">NOMBRE</th>
              <th>DNI</th>
              <th>IDENTIFICACIÓN</th>
              <!-- <th>N° Licencia</th> -->
              <!-- <th><i class="fa fa-id-card fa-lg"></i></th> -->
              <!-- <td><span class="fa fa-car fa-lg"></span></td> -->
              <th><i class="fa fa-pencil fa-lg"></i></th>

          </tr>
      </thead>
      <tbody style="border-top: inherit;">

          <tr *ngFor="let driver of driversService.tblDataDriver;let indice=index">
            <td class="text-center">{{indice+1}} </td>
            <!-- <td class="text-center">{{driver.id}} </td> -->
            <td>{{driver.nombre_conductor}} </td>
            <td>{{driver.dni_conductor}}</td>
            <!-- <td>{{driver.telefono_conductor}}</td> -->
            <!-- <td>{{driver.nro_licencia}}</td> -->
            <!-- <td>{{driver.tracker_nombre}}</td>
            <td>{{driver.nro_llave}}</td>
            <td>{{driver.nro_cipia}}</td> -->
            <!-- <td>{{driver.dni_conductor}}</td> -->
             <td>
              <div class="d-flex justify-content-center">
                <div class="p-2" *ngIf="driver.tipo_identificacion.vehicle"><i class="fas fa-car" style="font-size: 1rem" pTooltip="Por Vehículo" tooltipPosition="top"></i></div>
                <div class="p-2" *ngIf="driver.tipo_identificacion.icipia"><i class="fas fa-eye" style="font-size: 1rem" pTooltip="Por Código Cipia" tooltipPosition="top"></i></div>
                <div class="p-2" *ngIf="driver.tipo_identificacion.ibutton"><i class="fas fa-key" style="font-size: 1rem" pTooltip="Por Llave Ibutton" tooltipPosition="top"></i></div>
              </div>
             </td>
            <!-- <td *ngIf="(driver.tipo_identificacion == 'ibutton')">{{driver.nro_llave}}</td> -->
            <!-- <td *ngIf="(driver.tipo_identificacion == 'imei')">{{driver.tracker_nombre}}</td> -->
            <!-- <td *ngIf="(driver.tipo_identificacion == '' || driver.tipo_identificacion == null)"></td> -->

            <!-- <td class="inline-png-checkbox text-center">
              <p-checkbox [(ngModel)]="driver.activo" [disabled]="true" [binary]="true"></p-checkbox>
            </td> -->


            <td>
              <div *appHasPermission="['drivers_update', 'drivers_delete']" ngbDropdown container="body" class="d-flex justify-content-center align-items-center edit-column" [autoClose]="true" placement="right-top right-bottom left-top left-bottom">

                <i class="fa fa-pencil" ngbDropdownToggle></i>
                <div ngbDropdownMenu class="gl-custom-dropdown geofences-table-dropdown">
                  <div *appHasPermission="'drivers_update'" ngbDropdownItem (click)="clickConfigurarDriver(driver.id)" ><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Configurar</div>
                  <div *appHasPermission="'drivers_delete'" ngbDropdownItem (click)="clickEliminarDriver(driver.id)" ><i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar</div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="driversService.isRowDataEmpty">
            <td colspan="6" class="text-center">No hay conductores asignados</td>
          </tr>


      </tbody>
    </table>
  </div>

  <div *appHasPermission="'drivers_create'" class="d-flex justify-content-center my-4 panel-izq-container-footer">
    <div class="col-6">
        <button type="button" (click)="clickAgregarDriver()" class="btn-gl-save btn-dark-hover w-100" >Agregar Conductor</button>
    </div>
  </div>
  <div *ngIf="(driversService.modalActive)">
    <app-drivers-modal></app-drivers-modal>
  </div>

  <div *ngIf="(driversService.modalProgramacionActive)">
    <app-drivers-programacion-modal></app-drivers-programacion-modal>
  </div>

  <div *ngIf="(driversService.modalProgrammingActive)">
   <app-drivers-programming-modal></app-drivers-programming-modal>
  </div>

  <div *ngIf="(driversService.modalEnrollmentActive)">
    <app-driver-enrollment-modal></app-driver-enrollment-modal>
   </div>


</div>


