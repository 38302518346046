<div class="table-content-component-panel-monitoreo" style="position: relative">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    name="loadingEventList"
    size="medium"
    color="#fff"
    type="ball-pulse"
    [fullScreen]="false"
    ><p style="color: white" #loadingSpinner>
      Cargando Eventos...
    </p></ngx-spinner
  >
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    name="loadingOperators"
    size="medium"
    color="#fff"
    type="ball-pulse"
    [fullScreen]="false"
    ><p style="color: white" #loadingSpinner>
      Cargando Eventos ...
    </p></ngx-spinner
  >
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    name="loadingVehiculos"
    size="medium"
    color="#fff"
    type="ball-pulse"
    [fullScreen]="false"
  >
    <p style="color: white" #loadingSpinner>Cargando Vehículos...</p>
  </ngx-spinner>

  <div *ngIf="eventService.eventDeveloperStatus">
    <div class="">
      <label>Debug Servicio Event</label>
    </div>
    <div class="">
      <label for="">imei de unidad:</label>
      <input
        [(ngModel)]="imei_debug"
        type="text"
        name=""
        value="864200050708453"
      />
    </div>
    <div class="">
      <button type="button" name="button">Terminar Debug</button>
      <button (click)="clickDatosDebug()" type="button" name="button">
        Ver datos IMEI
      </button>
      <button (click)="clickEndDeveloper()" type="button" name="button">
        Terminar modo Desarrolador
      </button>
    </div>
    <div class="">
      <ul>
        <li *ngFor="let debug of data_debug">
          {{ debug }}
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body p-fluid" id="rowBusqueda">
    <div class="row">
      <div class="col-sm-6">
        <!--
        /*---- Buscador por anterior ----*/ 
        <input
          type="text"
          [(ngModel)]="placa"
          class="form-control"
          placeholder="Buscar placa|imei|código"
          (keyup)="searchByPlate()"
        /> -->

        <p-multiSelect
          [options]="vehiclesList"
          optionLabel="name"
          [showToggleAll]="true"
          [(ngModel)]="selectedVehicles"
          (onChange)="filterByVehicles()"
          placeholder="Selecciona vehículos"
          [selectedItemsLabel]="'{0} vehiculos seleccionados'"
          [filter]="true"
          filterBy="plate_number,cod_interno,name"
        >
        </p-multiSelect>
      </div>
      <div class="col-sm-6">
        <!-- <input
          type="text"

          [(ngModel)]="placa"
          class="form-control"
          placeholder="Tipo de Evento"
        /> -->
        <!--p-dropdown
          [options]="eventService.eventsGroupedList"
          [(ngModel)]="selectedEvent"
          optionLabel="option"
          optionValue="tipo"
          [appendTo]="'body'"
          [showClear]="true"
          [filter]="true"
          filterBy="option"
          placeholder="Todos los Eventos"
          (ngModelChange)="changeTypeEvent()"></p-dropdown-->
        <p-multiSelect
          [options]="eventService.eventsGroupedList"
          [group]="true"
          [(ngModel)]="selectedEvent"
          optionLabel="name"
          defaultLabel="Sin eventos seleccionados"
          [selectedItemsLabel]="'{0} eventos seleccionados'"
          [showToggleAll]="true"
          scrollHeight="250px"
          (onChange)="changeTypeEvent()"
          [appendTo]="'body'"
        >
          <ng-template let-group pTemplate="group">
            <div class="p-d-flex p-ai-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
  </div>

  <div class="panel-izq-table-container">
    <p-table
      [value]="eventService.eventsFiltered"
      selectionMode="single"
      (onRowExpand)="rowExpandend($event)"
      dataKey="uuid_event"
      [responsive]="true"
      [autoLayout]="true"
      [rowExpandMode]="'single'"
      [expandedRowKeys]="expandedRows"
      sortField="fecha_tracker"
      [sortOrder]="-1"
      sortMode="multiple"
    >
      <ng-template pTemplate="header" class="visible-on-scroll">
        <tr>
          <th style="width: 1.5rem"></th>
          <th Column="name" (click)="clickEventPanel()">Evento</th>
          <th Column="nombre">Nombre</th>
          <th Column="nombre_objeto">Placa</th>
          <th Column="fecha_tracker">Fecha</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-event let-expanded="expanded">
        <tr
          tabindex="-1"
          (click)="switchEventOnMap(event, currRow)"
          [style]="{ 'font-size': '0.75rem' }"
          [ngClass]="{
            'unread-event': !event.viewed,
            'tr-selected':
              event.uuid_event == eventService.activeEvent.uuid_event,
            'evaluation-event': event.evaluated == 2,
            'evaluated-event': event.evaluated == 1
          }"
          [class]="event.clase"
          [pRowToggler]="event.bol_evaluation ? event : ''"
          #currRow
          [attr.id]="'event-' + event.uuid_event"
        >
          <!-- [id]="event.uuid_event" //anterior  -->
          <!-- [attr.id]="'event-' + event.uuid_event" // se uso para poder pintar    -->

          <td>
            <button
              *ngIf="event.bol_evaluation"
              type="button"
              pButton
              pRipple
              class="p-button-rounded p-button-plain p-button-text"
              [icon]="expanded ? 'pi pi-circle-on' : 'pi pi-circle-off'"
              #rowToggle
            ></button>
          </td>
          <td>
            <div class="event-column">
              <span style="text-align: center">{{ event.name }}</span>
            </div>
          </td>
          <td>{{ event.nombre }}</td>
          <td>{{ event.nombre_objeto || "Unidad no registrada" }}</td>
          <td>{{ event.fecha_tracker }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-event>
        <app-event-evaluation
        *appHasPermission="'events_evaluateevent'"
          (closeEvaluationExpanded)="closeEvaluationExpanded($event.evaluation)"
          (submitEvaluation)="
            submitEvaluation(
              $event.evaluation,
              $event.event,
              currentRowSelected
            )
          "
          (submitSendEvaluationByWhatsapp)="
            submitSendEvaluationByWhatsapp($event.EvaluationByWhatsapp)
          "
          (submitSendExternal)="submitSendExternal($event.External)"
          [submitting]="submitting"
          [evaluation_criteria_filter]="evaluation_criteria_filter"
          [event]="event"
          [eventsEvaluated]="eventsEvaluated"
          [loading_evaluation]="loading_evaluation"
          [evaluation_criteria]="evaluation_criteria"
        ></app-event-evaluation>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" style="text-align: center">Sin coinicidencias</td>
        </tr>
      </ng-template>
    </p-table>
    <div
      *appHasPermission="'events_btn_showmore'"
      class="show-more-container"
      style="text-align: center; margin-top: 1rem; margin-bottom: 1rem"
    >
      <!-- <button label="argar más Eventos"pButton type="button" class="p-button-raised p-button-rounded" icon="pi pi-plus" (Click)="loadMore()" [disabled]="isLoading"></button> -->
      <button
        class="p-button btn-gl-save btn-dark-hover"
        style="
          border: none;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        "
        (click)="loadMore()"
        [disabled]="isLoadingMoreEvents || !canLoadMoreEvents"
      >
        <i class="pi pi-plus" style="margin-right: 0.75rem"></i>
        Mostrar más eventos
      </button>

      <!--table id="tbl_events">
      <thead class="visible-on-scroll">
        <tr>
          <th (click)="clickEventPanel()" >Evento</th>
          <th>Nombre</th>
          <th>Placa</th>
          <th>Fecha</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let event of eventService.eventsFiltered">
          <tr #currRow (click)="switchEventOnMap(event, currRow)" [ngClass]="{ 'unread-event': !event.viewed, 'tr-selected': event.id == eventService.activeEvent.id }" [class]="event.clase">
            <td>
              <div class="event-column">
                <span>{{ event.name }}</span>
              </div>
            </td>
            <td>{{ event.nombre }}</td>
            <td>{{ event.nombre_objeto }}</td>
            <td>{{ event.fecha_tracker }}</td>
          </tr>
        </ng-container>
        <tr *ngIf="noResults">
          <td colspan="4" class="text-center">Sin coinicidencias</td>
        </tr>

      </tbody>
    </table-->
    </div>
  </div>
</div>
