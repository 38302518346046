<p-dialog
  [(visible)]="geofencesService.modalActive"
  (onHiden)="geofencesService.modalActive = false"
  [style]="{ width: '1250px' }"
>
  <ng-template pTemplate="header"> Importación de Geocercas </ng-template>
  <ng-template pTemplate="content">
    <div class="w-100 d-flex gap-2">
      <!-- lista fe Geocercas -->
      <div
        class="d-flex flex-column justify-content-between pb-3"
        [style]="{ width: '700px' }"
      >
        <p-table
          #dt
          [scrollable]="true"
          scrollHeight="560px"
          styleClass="p-datatable-sm"
          [value]="dataGeofence"
          [globalFilterFields]="['zone_name']"
          [rowHover]="true"
          dataKey="id"
          [(selection)]="selectedGeofencesToImport"
        >
          <!-- <ng-template pTemplate="caption">
            <div class="table-header">
              <div class="d-flex">
                <input
                  class="flex-fill"
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar por Nombre..."
                />
              </div>
            </div>
          </ng-template> -->
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col style="width: 2.5rem" />
              <col style="width: 21rem" />
              <col style="width: 6rem" />
              <col style="width: 6rem" />
              <col style="width: 8rem" />
              <col style="width: 3rem" />
              <col style="width: 3rem" />
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="zone_name">Nombre</th>
              <th>Velocidad</th>
              <th>Dirección</th>
              <th>Límite de velocidad</th>
              <th>
                <i class="pi pi-map" (click)="showAllGeofences()"></i>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dataGeofence>
            <tr>
              <td>
                <p-tableCheckbox [value]="dataGeofence"></p-tableCheckbox>
              </td>
              <td>
                <div
                  class="d-flex gap-2 w-100"
                  (click)="locateGeofence(dataGeofence)"
                >
                  <svg
                    [style.overflow]="'inherit'"
                    [style.color]="dataGeofence.zone_color"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    stroke="#000000"
                    stroke-width="1px"
                  >
                    <circle
                      *ngIf="dataGeofence.type == 'circ'"
                      cx="8"
                      cy="8"
                      r="8"
                    />
                    <path
                      *ngIf="dataGeofence.type == 'polig'"
                      d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
                    />
                  </svg>
                  <div class="flex-fill truncate-text">
                    {{ dataGeofence.zone_name }}
                  </div>
                  <span
                    tooltipPosition="right"
                    [pTooltip]="dataGeofence.errors.join(',')"
                    *ngIf="dataGeofence.errors.length > 0"
                  >
                    <svg
                      [style.color]="'#ff0000'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"
                      />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
                      />
                    </svg>
                  </span>
                </div>
                <div class="d-flex flex-row" *ngIf="false">
                  <div
                    class="d-flex justify-content-center align-items-center me-1"
                  >
                    <svg
                      [style.color]="'#' + dataGeofence.zone_color"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <circle
                        *ngIf="dataGeofence.type == 'circ'"
                        cx="8"
                        cy="8"
                        r="8"
                      />
                      <path
                        *ngIf="dataGeofence.type == 'polig'"
                        d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
                      />
                    </svg>
                  </div>
                  <input
                    class="p-inputtext-sm border-bottom"
                    type="text"
                    pInputText
                    [(ngModel)]="dataGeofence.zone_name"
                    [readonly]="true"
                  />
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-start">
                  <p-inputSwitch
                    tooltipPosition="top"
                    [pTooltip]="
                      dataGeofence.vel_act_zone ? 'Activado' : 'Desactivado'
                    "
                    [(ngModel)]="dataGeofence.vel_act_zone"
                    [readonly]="true"
                  ></p-inputSwitch>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-start">
                  <p-inputSwitch
                    tooltipPosition="top"
                    [pTooltip]="
                      dataGeofence.hasDirection ? 'Activado' : 'Desactivado'
                    "
                    [(ngModel)]="dataGeofence.hasDirection"
                    [readonly]="true"
                  ></p-inputSwitch>
                </div>
              </td>
              <td>
                <div class="p-text-nowrap p-text-truncate">
                  {{ dataGeofence.vel_max }} km/h
                </div>
                <!-- <input
                    pInputText
                    class="p-inputtext-sm"
                    type="text"
                    [(ngModel)]="dataGeofence.max_zone_speed"
                    [min]="0"
                    [max]="300"
                    [disabled]="!dataGeofence.speed"
                  /> -->
              </td>
              <td>
                <div>
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-search"
                    class="p-button-rounded"
                    (click)="locateGeofence(dataGeofence)"
                    pTooltip="Enfocar geocerca"
                    tooltipPosition="top"
                  ></button>
                </div>
              </td>
              <td>
                <div>
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-cog"
                    pTooltip="Modificar datos"
                    tooltipPosition="top"
                    class="p-button-rounded"
                    (click)="modifyGeofenceData(dataGeofence)"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div
              class="p-d-flex p-ai-center p-jc-between d-flex justify-content-between"
            >
              <div>
                {{ selectedGeofencesToImport.length }} de
                {{ dataGeofence.length }}
                geocercas seleccionadas
              </div>
              <div
                *ngIf="dataGeofenceInvalidity.length > 0"
                class="d-flex align-items-center gap-4"
              >
                <button
                  pButton
                  type="button"
                  (click)="listGeofencesInvalidity()"
                  class="p-button-danger p-button-sm"
                  tooltipPosition="top"
                  [pTooltip]="
                    dataGeofenceInvalidity.length + ' Geocercas no válidas'
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </ng-template>
        </p-table>
        <div class="d-flex justify-content-center p-1">
          <button
            (click)="guardarRegistro()"
            class="btn-gl-save btn-dark-hover w-25"
          >
            Importar
          </button>
        </div>
      </div>
      <!-- mini mapa -->
      <div
        class="card d-flex flex-column justify-content-between mb-3"
        [style]="{ width: '450px', height: '600px' }"
      >
        <div
          [id]="'geofence-minimap'"
          [style]="{
            width: '450px',
            height: '358px'
          }"
        ></div>
        <div
          class="d-flex align-items-center"
          [style]="{
            width: '450px',
            height: '240px'
          }"
        >
          <div *ngIf="!modifiableGeofence" class="w-100">
            <div
              *ngIf="!showInvalidGeofence"
              class="d-flex w-100 justify-content-center align-items-center gap-2"
            >
              <span>Haga click en la opción</span>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-cog"
                class="p-button-rounded"
              ></button>
              <span>para editar datos de la geocerca</span>
            </div>
            <div *ngIf="showInvalidGeofence" class="w-100">
              <p-table
                [value]="dataGeofenceInvalidity"
                [scrollable]="true"
                scrollHeight="200px"
                styleClass="p-datatable-sm"
              >
                <ng-template pTemplate="colgroup" let-columns>
                  <colgroup>
                    <col style="width: 10rem" />
                    <col style="width: 4rem" />
                    <col style="width: 8rem" />
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th class="truncate-text">Nombre</th>
                    <th class="truncate-text">Tipo</th>
                    <th class="truncate-text">Geocordenadas</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-geo>
                  <tr>
                    <td
                      class="truncate-text"
                      [style.backgroundColor]="
                        !geo.zone_name.trim() ? '#ff181861' : '#ffffff'
                      "
                    >
                      {{ geo.zone_name }}
                    </td>
                    <td
                      class="truncate-text"
                      [style.backgroundColor]="
                        geo.type == 'point' || geo.type == 'line'
                          ? '#ff181861'
                          : '#ffffff'
                      "
                    >
                      {{ geo.type }}
                    </td>
                    <td
                      class="truncate-text"
                      [style.backgroundColor]="
                        !geo.zone_vertices.trim() ? '#ff181861' : '#ffffff'
                      "
                    >
                      {{ geo.zone_vertices }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div
            *ngIf="modifiableGeofence"
            class="card-body d-flex flex-column justify-content-between"
            style="height: 100%"
          >
            <div class="p-field p-grid w-100">
              <label for="zone_name" class="p-col-fixed"
                >Nombre de geocerca</label
              >
              <span class="p-input-icon-left">
                <i class="pi"
                  ><svg
                    [style.overflow]="'inherit'"
                    [style.color]="selectedGeofenceToModify.zone_color"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    stroke="#000000"
                    stroke-width="1px"
                  >
                    <circle
                      *ngIf="selectedGeofenceToModify.type == 'circ'"
                      cx="8"
                      cy="8"
                      r="8"
                    />
                    <path
                      *ngIf="selectedGeofenceToModify.type == 'polig'"
                      d="M7.685.256a.5.5 0 0 1 .63 0l7.421 6.03a.5.5 0 0 1 .162.538l-2.788 8.827a.5.5 0 0 1-.476.349H3.366a.5.5 0 0 1-.476-.35L.102 6.825a.5.5 0 0 1 .162-.538l7.42-6.03Z"
                    /></svg
                ></i>
                <input
                  [style.border]="'1px solid'"
                  class="p-inputtext-sm"
                  [(ngModel)]="selectedGeofenceToModify.zone_name"
                  name="zone_name"
                  type="text"
                  pInputText
                  tooltipPosition="top"
                  [pTooltip]="
                    selectedGeofenceToModify.type == 'polig'
                      ? 'Geocerca Poligonal'
                      : 'Geocerca Circular'
                  "
                />
              </span>
            </div>
            <div class="p-field p-grid d-flex w-100">
              <div
                class="d-flex flex-fill justify-content-start align-items-center gap-2"
              >
                <label>Velocidad:</label>
                <p-inputSwitch
                  tooltipPosition="top"
                  [pTooltip]="
                    selectedGeofenceToModify.vel_act_zone
                      ? 'Activado'
                      : 'Desactivado'
                  "
                  [(ngModel)]="selectedGeofenceToModify.vel_act_zone"
                ></p-inputSwitch>
              </div>
              <div
                class="d-flex flex-fill justify-content-start align-items-center gap-2"
              >
                <label>Color:</label>
                <input
                  [(ngModel)]="selectedGeofenceToModify.zone_color"
                  (change)="changeGeofenceSelectColor()"
                  class="form-control-color form-control-color-gl"
                  pTooltip="Cambiar color"
                  tooltipPosition="top"
                  style="width: 3rem"
                  type="color"
                />
              </div>
            </div>
            <div class="p-field d-flex flex-row w-100">
              <div class="w-25">
                <label class="w-100 truncate-text" for="vel_max">
                  Límites de velocidad
                </label>
                <p-inputNumber
                  class="p-inputtext-sm"
                  inputId="vel_max"
                  [min]="0"
                  [max]="400"
                  [(ngModel)]="selectedGeofenceToModify.vel_max"
                  tooltipPosition="top"
                  pTooltip="Límites de velocidad"
                  suffix=" km/h"
                  [disabled]="!selectedGeofenceToModify.vel_act_zone"
                ></p-inputNumber>
              </div>
              <div class="w-25">
                <label class="w-100 truncate-text" for="vel_zona"
                  >Tolerable</label
                ><p-inputNumber
                  class="p-inputtext-sm"
                  inputId="vel_zona"
                  [min]="0"
                  [max]="400"
                  [(ngModel)]="selectedGeofenceToModify.vel_zona"
                  tooltipPosition="top"
                  pTooltip="Velocidad tolerable"
                  suffix=" km/h"
                  [disabled]="!selectedGeofenceToModify.vel_act_zone"
                ></p-inputNumber>
              </div>
              <div class="w-25">
                <label class="w-100 truncate-text" for="vel2_zona">Grave</label
                ><p-inputNumber
                  class="p-inputtext-sm"
                  inputId="vel2_zona"
                  [min]="0"
                  [max]="400"
                  [(ngModel)]="selectedGeofenceToModify.vel2_zona"
                  tooltipPosition="top"
                  pTooltip="Velocidad grave"
                  suffix=" km/h"
                  [disabled]="!selectedGeofenceToModify.vel_act_zone"
                ></p-inputNumber>
              </div>
              <div class="w-25">
                <label class="w-100 truncate-text" for="vel3_zona"
                  >Muy grave</label
                ><p-inputNumber
                  class="p-inputtext-sm"
                  inputId="vel3_zona"
                  [min]="0"
                  [max]="400"
                  [(ngModel)]="selectedGeofenceToModify.vel3_zona"
                  tooltipPosition="top"
                  pTooltip="Velocidad muy grave"
                  suffix=" km/h"
                  [disabled]="!selectedGeofenceToModify.vel_act_zone"
                ></p-inputNumber>
              </div>
            </div>
            <div class="p-field d-flex w-100 justify-content-between gap-4">
              <button
                class="btn-gl-cancel btn-dark-hover flex-fill"
                (click)="hideGeofenceDataModifier()"
              >
                Cancelar
              </button>
              <button
                class="btn-gl-save btn-dark-hover flex-fill"
                (click)="saveModifiedGeofenceData()"
                tooltipPosition="top"
                [pTooltip]="
                  !selectedGeofenceToModify.zone_name.trim()
                    ? 'Nombre de la geocerca vacio'
                    : 'Actualizar geocerca'
                "
                [disabled]="!selectedGeofenceToModify.zone_name.trim()"
              >
                Actualizar y cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-modal-geo-det></app-modal-geo-det> -->
  </ng-template>
</p-dialog>
