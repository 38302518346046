<form [formGroup]="driverProgrammingForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" autocomplete="off">
  <div class="p-fluid p-formgrid p-grid mb-4" style="font-size: 13px;">
    <div class="dialog-form-container gl-middleline">
      <div class="row">
        <div class="col p-field mt-2">
          <label class="row gl-row-underline">
            <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
              <label for="driver">Conductor:</label>
            </div>
            <div class="col-7">
              <p-dropdown
                emptyMessage="No existen Conductores"
                emptyFilterMessage="No existen Conductores"
                placeholder="Seleccione un Conductor"
                [autoDisplayFirst]="false"
                [showClear]="true"
                [options]="drivers"
                formControlName="driver_id"
                [appendTo]="'body'"
                optionValue="id"
                optionLabel="name" required>
              </p-dropdown>
            </div>
          </label>
        </div>
        <div class="col p-field mt-2">
          <div class="row gl-row-underline">
            <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
              <label for="vehicle">Vehículo:</label>
            </div>
            <div class="col-7">
              <p-dropdown
                [autoDisplayFirst]="false"
                [showClear]="true"
                placeholder="Seleccione un Vehículo"
                [appendTo]="'body'"
                [options]="vehicles"
                formControlName="key_number"
                optionValue="id"
                optionLabel="name" required>
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col p-field mt-2">
          <label class="row gl-row-underline">
            <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
              <label for="fecha_ini">Fecha Inicial:</label>
            </div>
            <div class="col-7">
              <p-calendar
                formControlName="fecha_ini"
                [appendTo]="'body'"
                [showIcon]="true"
                dateFormat='dd/mm/yy'
                [showButtonBar]="true"
                [showTime]="true"
                required
                [maxDate]="driverProgrammingForm.value.fecha_fin"
              ></p-calendar>
            </div>
          </label>
        </div>
        <div class="col p-field mt-2" *ngIf="showDateEnd">
          <label class="row gl-row-underline">
            <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
              <label for="fecha_ini">Fecha Final:</label>
            </div>
            <div class="col-7">
              <!-- <input type="datetime-local" name="fecha_ini" id="id_fecha_ini" min="2024-01-01T00:00" max="2044-01-01T00:00"> -->
              <p-calendar
                formControlName="fecha_fin"
                [showIcon]="true"
                [appendTo]="'body'"
                dateFormat='dd/mm/yy'
                [showButtonBar]="true"
                [showTime]="true"
                [minDate]="driverProgrammingForm.value.fecha_ini"
              ></p-calendar>
            </div>
          </label>
        </div>
        <div class="col p-field mt-2" *ngIf="!showDateEnd">
        </div>
      </div>
    </div>
  </div>
</form>
<div class="form-group">
  <div class="d-flex align-items-center justify-content-end">
    <p-checkbox [(ngModel)]="showDateEnd" [binary]="true" label="Fecha Final"></p-checkbox>
  </div>
</div>

<div class="row justify-content-center mb-4 pt-4 panel-izq-container-footer">
  <div class="col-4">
      <button type="button" class="btn-gl-cancel btn-dark-hover w-100" (click)="driversService.modalProgrammingActive=false">Cerrar</button>
  </div>
  <div class="col-4">
    <button type="button" class="btn-gl-save btn-dark-hover w-100" (click)="onSubmit()">Guardar y Continuar</button>
  </div>
</div>
