import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RepProgramadosService {

  constructor() { }


  listReportes : any;
  reportesProgramados : any;

  titulo_modal_add_edit = "";

  reporteProgramado : any;


  // modalReportesProgramadosActive : boolean = true;
  modalReportesProgramadosActiveDetalle : boolean = false;
  modalReportesProgramadosActiveAddEdit : boolean = false;
  
  paramsReportesProgramados : any = {};

  modal_add0 = false;
  modal_add = false;

  vehicles : any;
  events: any=[];
  eventsTypes = [
    { name: 'gps', active: false, label: 'GPS', selectAll: false },
    { name: 'platform', active: false, label: 'PLATAFORMA', selectAll: false },
    { name: 'security', active: false, label: 'SEGURIDAD VEHICULAR', selectAll: false },
    { name: 'mobile', active: false, label: 'SOLUCIONES MÓVILES', selectAll: false },
    { name: '360', active: false, label: 'FATIGA 360', selectAll: false },
  ];

  zones: any=[];
  listTags: any=[];
  


}
