import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RepProgramadosService } from '../../services/rep-programados.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTracker } from 'src/app/multiview/models/interfaces';




import * as moment from 'moment';
import Swal from 'sweetalert2';
import { count } from 'console';

@Component({
  selector: 'app-rp-list',
  templateUrl: './rp-list.component.html',
  styleUrls: ['./rp-list.component.scss']
})
export class RpListComponent implements OnInit {

  constructor(
    public repProgramadosService: RepProgramadosService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,

  ) {
  }

  ventana_visible = false;
  form: any;

  reports: any=[];
  events: any=[];
  reportesProgramados: any=[];
  fullScreenSpinnerMsg:any = 'Cargando Reporte Programados ...';



  ngOnInit(): void {
    this.spinner.show('loadingReportesProgramados');
    // this.fullScreenSpinnerMsg = 'Cargando Reporte Programados ...';

    // this.dateInit = new Date(moment(hoy).format("MM/DD/YYYY"));
    // // this.dateEnd = new Date(moment(hoy).format("MM/DD/YYYY"));
    // this.dateEnd = this.dateInit;
    this.ventana_visible = true;
    console.log(" =====> INNITIT ");
    // console.log(this.reportService.paramsReportesProgramados);

    
    const hoy = Date.now();
    this.form = {
        nombre : '',
        hora_programado : {label:'00:00', value:0},
        hora_programado2 : new Date(moment(hoy).format("MM/DD/YYYY")),
        notificacion_email:true,
        bol_activo:true,
        notificacion_email_lista:''

    };


    this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
      next: data => {


        this.http.post(environment.apiUrl + '/api/getReports', {}).subscribe({
          next: data2 => {
            console.log("reportProgranados", data);
            console.log("ListaReportes", data2);

            this.reports = data2;
            this.repProgramadosService.listReportes = this.reports;

            this.reportesProgramados = data;
            this.repProgramadosService.reportesProgramados = this.reportesProgramados;


            for (let i = 0; i < this.reportesProgramados.length; i++) {
              const element = this.reportesProgramados[i];
              element.unidades = JSON.parse(element.p_vehiculos);

              //==================================================== UNIDADES ==============================================

              element.vehiculos_str =  ( element.unidades.length == 1 ) ? element.unidades[0].name : element.unidades.length + " Vehículos";

              //==================================================== UNIDADES ==============================================


              // =================================================== SIGUIENTE PROGRAMACION =======================================================
              var MP1 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              var MP2 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

              element.intervalo_hora_HH = moment(new Date(element.intervalo_hora)).format("HH");

              if (element.intervalo == 'diariamente') {
                  MP1 = moment(new Date()).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                  MP2 = moment(new Date()).add(1, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              } else if (element.intervalo == 'semanal') {
                  MP1 = moment(new Date()).day( element.periodo_dias_semanal ).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                  MP2 = moment(new Date()).day( element.periodo_dias_semanal ).add(7, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              } else if (element.intervalo == 'quincenal') {
                  MP1 = moment().date(15).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
                  MP2 = moment(new Date()).add(1, 'months').date(1).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
              } else if (element.intervalo == 'mensual') {
                  MP1 = moment().date(1).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                  MP2 = moment().date(1).add(1, 'months').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              }

              var h_actual = moment(new Date()); //2024-09-20 19:34:45
              var h_prog   = moment(MP1); //2024-09-20 16:00:00
              var s2       = h_prog.diff(h_actual, 'minutes');
      
              console.log("GGGAAAA ");
              console.log(s2);
              console.log(MP1);
              console.log(MP2);

              if ( s2 < 0 ) {
                element.proximo_reporte_generado_2 = MP2;
              } else {
                element.proximo_reporte_generado_2 = MP1;
              }
              // =================================================== FIN SIGUIENTE PROGRAMACION =======================================================

              console.log("============= diario");
              var M1 = moment(new Date()).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              var M1_2 = moment(new Date()).add(1, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              console.log(M1);
              console.log(M1_2);
              //semanal
              // moment().day(0); // este domingo (0)
              // moment().day(1); //lunes
              // var Msemana1 = moment(new Date()).day(1).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              // var Msemana2 = moment(new Date()).day(1).add(7, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              var Msemana1 = moment(new Date()).day( element.periodo_dias_semanal ).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              var Msemana2 = moment(new Date()).day( element.periodo_dias_semanal ).add(7, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              console.log("============= semanal");
              console.log(Msemana1);
              console.log(Msemana2);
              // console.log("============= day 0 y 1s");
              // console.log(moment().day(0).format("YYYY-MM-DD HH:mm:ss"));
              // console.log(moment().day(1).format("YYYY-MM-DD HH:mm:ss"));
              //quincenal
              console.log("============= quincenal");
              // console.log(moment().date(0).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00');
              // console.log(moment().date(1).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00');
              // console.log(moment().date(15).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00');
              var Mquincena1 = moment().date(15).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
              var Mquincena2 = moment(new Date()).add(1, 'months').date(1).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
              console.log( Mquincena1 );
              console.log( Mquincena2 );
              //mensual
              console.log("============= mensual");
              var Mmes1 = moment().date(1).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              var Mmes2 = moment().date(1).add(1, 'months').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
              console.log( Mmes1 );
              console.log( Mmes2 );
              // =================================================== FIN SIGUIENTE PROGRAMACION =======================================================


              // element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
              // element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
              // element.nombre_reporte  = element.p_numRep;
              for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                const reporte = this.repProgramadosService.listReportes[j];
                if (element.p_numRep == reporte.codigo ) {
                  element.nombre_reporte = reporte.value;
                }
              }
            }
            
            this.spinner.hide("loadingReportesProgramados");

          },
          error: () => {
            console.log('Hubo un error al procesar la solicitud');
          }
        });
    
      },
      error: () => {
        console.log('Fallo al obtener reportesProgramados');
        // this.errorFlag++;
        // this.endInit();
      }
    });


    this.http.get<any>(environment.apiUrl + '/api/tracker').subscribe({
      next: data => {
          // this.listTags = data.data;
          console.log("vehiculos : ", data);
          this.repProgramadosService.vehicles = this.reducedInfoVehicles(data);
          // this.ListVehicles = this.vehicles;
          // console.log('Vehicles: ',this.vehicles);
      
      },
      error: () => {
        console.log('Fallo al obtener vehiculos');
      }
    });

    this.http.get<any>(environment.apiUrl + '/api/getPermissEvents').subscribe({
      next: data => {
                   // Aquí puedes trabajar con los datos obtenidos
                   console.log('EVENTOS DEL USUARIO OBTENIDOS: 1vez', data);
                   // Realiza cualquier acción con los datos recibidos
                   if (data.success){
                     this.repProgramadosService.events = data.data;
                     this.repProgramadosService.events = this.repProgramadosService.events.map((event: any) => {
                       return { ...event, active: false };
                     });
                     this.updateShowTypeEvents();
                   }else{
                     this.repProgramadosService.events = [];
                     console.log('EL USUARIO NO TIENE EVENTOS');
                   }
      },
      error: (error) => {
        console.error('Error al obtener los eventos:', error);
      }
    });

    this.http.get<any>(environment.apiUrl + '/api/listTags').subscribe({
      next: data => {
        this.repProgramadosService.listTags = data.data;
        console.log("listTags", this.repProgramadosService.listTags);
        // this.areTagsLoaded = true;
        // this.endInit();
      },
      error: () => {
        console.log('Fallo al obtener listTags');
        // this.errorFlag++;
        // this.endInit('Ha ocurrido un problema al intentar obtener la lista de etiquetas. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con el área de TI.');
      }
    });

  }

  updateShowTypeEvents() {
    this.repProgramadosService.events.forEach((event: any) => {
      const eventsTypesToUpdate = this.repProgramadosService.eventsTypes.find(type => type.name === event.event_category);
      if (eventsTypesToUpdate) {
        eventsTypesToUpdate.active = true;
      }
    });
    console.log('test types -->',this.repProgramadosService.eventsTypes,this.repProgramadosService.events);
  }


  
  reducedInfoVehicles(vehicles:any) {
    // Reducir y especificar la cantidad de informacion a enviar por vehiculo
    const reducedVehicles = vehicles.map((vh: UserTracker) => {
      return {
        IMEI: vh.IMEI,
        name: vh.name,
        driver_id: vh.driver_id,
        namegrupo: vh.namegrupo,
        idgrupo: vh.idgrupo,
        nameconvoy: vh.nameconvoy,
        idconvoy: vh.idconvoy,
        nameoperation: vh.nameoperation,
        idoperation: vh.idoperation,
        tipo: vh.tipo,
      };
    });
    return reducedVehicles;
  }


  agregarReporteProgramado0() {
    console.log('=======> agregarReporteProgramado0 ');
    this.repProgramadosService.modal_add = true;
    this.repProgramadosService.modal_add0 = true;
  }


  enviarCorreoReporteProgramado() {
    console.log('=======> enviarCorreoReporteProgramado ');
    // this.repProgramadosService.modal_add = true;
    // this.repProgramadosService.modal_add0 = true;
    this.http.post(environment.apiUrl + '/api/runReporteProgramado',{}).subscribe({
      next: data => {
        //console.log(this.selectedConvoy.length);
        console.log(typeof data);
        console.log(data);
      
        //=============================================================================================================
  
      },
    });


  }


  editReporteProgramado(rp:any) {
    console.log('=======> editReporteProgramado ');
    this.repProgramadosService.reporteProgramado = rp;

    this.repProgramadosService.modal_add = false;
    this.repProgramadosService.titulo_modal_add_edit = "Editar Reporte Programado";
    this.repProgramadosService.modalReportesProgramadosActiveAddEdit = true;
  }

  
  verDetalleReporteProgramado(rp:any){
    console.log("==== verDetalleReporteProgramado ====");
    console.log(rp);
    this.repProgramadosService.modalReportesProgramadosActiveDetalle = true;
    this.repProgramadosService.reporteProgramado = rp;
  }

  EliminarReporteProgramado(rp:any){
    console.log("==== EliminarReporteProgramado ====");
    console.log(rp);

    // this.fullScreenSpinnerMsg = 'Cargando Reporte Programados ...';


    Swal.fire({
      // title: 'Título de la Alerta',
      text: '¿Desea Eliminiar el Reporte Programado : '+rp.nombre_programacion+' ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      confirmButtonColor: '#30a9d9',
      cancelButtonText: 'No',
      cancelButtonColor: '#e3e3e3',
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Sí');
        this.fullScreenSpinnerMsg = 'Eliminando Reporte Programado...';
        this.spinner.show("loadingReportesProgramados");

        // this.fullScreenSpinnerMsg = "Eliminando Reporte Programado...";
        // this.spinner.show("reloadSpinner");
        
        this.http.post(environment.apiUrl + '/api/deleteReporteProgramado', rp).subscribe({
          next: data => {
            //console.log(this.selectedConvoy.length);
            console.log(typeof data);
            console.log(data);
            //this.spinner.hide("reloadSpinner");
            // this.reportService.modalReportesProgramadosActive = false;
            this.fullScreenSpinnerMsg = "Actualizando ...";
            //=============================================================================================================
            this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
              next: data => {
                  console.log("reportProgranados", data);
                  this.reportesProgramados = data;
                  this.repProgramadosService.reportesProgramados = this.reportesProgramados;

                  // for (let i = 0; i < this.reportesProgramados.length; i++) {
                  //   const element = this.reportesProgramados[i];
                  //   element.unidades = JSON.parse(element.p_vehiculos);
                  //   element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                  //   element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                  //   for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                  //     const reporte = this.repProgramadosService.listReportes[j];
                  //     if (element.p_numRep == reporte.codigo ) {
                  //       element.nombre_reporte = reporte.value;
                  //     }
                  //   }
                  // }

                  // for (let i = 0; i < this.reportesProgramados.length; i++) {
                  //   const element = this.reportesProgramados[i];
                  //   // element.unidades = JSON.parse(element.p_vehiculos);
                  //   // element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                  //   // element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                  //   // element.nombre_reporte  = element.p_numRep;
                  //   for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                  //     const reporte = this.repProgramadosService.listReportes[j];
                  //     if (element.p_numRep == reporte.codigo ) {
                  //       element.nombre_reporte = reporte.value;
                  //     }
                  //   }
                  // }


                  for (let i = 0; i < this.reportesProgramados.length; i++) {
                    const element = this.reportesProgramados[i];
                    element.unidades = JSON.parse(element.p_vehiculos);

                    //==================================================== UNIDADES ==============================================
                    element.vehiculos_str =  ( element.unidades.length == 1 ) ? element.unidades[0].name : element.unidades.length + " Vehículos";
                    //==================================================== UNIDADES ==============================================
                    // =================================================== SIGUIENTE PROGRAMACION =======================================================
                    var MP1 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    var MP2 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

                    element.intervalo_hora_HH = moment(new Date(element.intervalo_hora)).format("HH");

                    if (element.intervalo == 'diariamente') {
                        MP1 = moment(new Date()).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                        MP2 = moment(new Date()).add(1, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    } else if (element.intervalo == 'semanal') {
                        MP1 = moment(new Date()).day( element.periodo_dias_semanal ).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                        MP2 = moment(new Date()).day( element.periodo_dias_semanal ).add(7, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    } else if (element.intervalo == 'quincenal') {
                        MP1 = moment().date(15).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
                        MP2 = moment(new Date()).add(1, 'months').date(1).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
                    } else if (element.intervalo == 'mensual') {
                        MP1 = moment().date(1).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                        MP2 = moment().date(1).add(1, 'months').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    }

                    var h_actual = moment(new Date()); //2024-09-20 19:34:45
                    var h_prog   = moment(MP1); //2024-09-20 16:00:00
                    var s2       = h_prog.diff(h_actual, 'minutes');
          

                    if ( s2 < 0 ) {
                      element.proximo_reporte_generado_2 = MP2;
                    } else {
                      element.proximo_reporte_generado_2 = MP1;
                    }
                    // =================================================== FIN SIGUIENTE PROGRAMACION =======================================================

                    // element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                    // element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                    // element.nombre_reporte  = element.p_numRep;
                    for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                      const reporte = this.repProgramadosService.listReportes[j];
                      if (element.p_numRep == reporte.codigo ) {
                        element.nombre_reporte = reporte.value;
                      }
                    }
                  }
            

                  this.spinner.hide("loadingReportesProgramados");
              },
              error: () => {
                console.log('Fallo al obtener reportesProgramados');
              }
            });
            //=============================================================================================================
      
          },
        });


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('No');
        // this.spinner.show("reportSpinner");

      } else {
        //CUANDO ES ESC O X
        console.log('Cancel');
      }
    });




  }

}
