<p-dialog [(visible)]="driversService.modalActive" [modal]="true" [resizable]="false" [style]="{'width':'70%'}" >

    <ng-template class="p-dialog-title" pTemplate="header">
      <div>
        <i class="fa fa-user me-2" aria-hidden="true"></i>
        <strong [innerHTML]="this.driversService.action == 'edit' ? 'Configurar Conductor' : 'Agregar nuevo Conductor' " ></strong>
      </div>
    </ng-template>


    <form [formGroup]="driversForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()" autocomplete="off">

      <div class="dialog-body-container">

        <div class="p-fluid p-formgrid p-grid" style="font-size: 12px;">

          <div class="dialog-form-container d-flex" >

            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="name_driver">Nombres y Apellidos</label>
                  </div>
                  <div class="col-7">
                    <input id="name_driver" type="text" pInputText class="p-inputtext-sm" formControlName="nombre" autocomplete="no" >
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="tipo-documento">Tipo de Documento :</label>
                  </div>
                  <div class="col-7">
                    <div class="p-field">
                      <p-dropdown
                        [filter]="true"
                        [options]="typeDocuments"
                        optionLabel="label"
                        optionValue="value"
                        formControlName="tipo_documento"
                        [showClear]="true"
                        [appendTo]="'body'"
                        (onChange)="changeTypeDocument($event.value)"
                        placeholder="Seleccione el Tipo de Documento...">
                      </p-dropdown>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="dni_driver">Nro Documento</label>
                  </div>
                  <div class="col-7">
                    <input id="dni_driver" type="numberss" pInputText class="p-inputtext-sm" formControlName="dni" autocomplete="no" [maxlength]="nroDocumentMaxLength">
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="licencia_driver">Nro Licencia</label>
                  </div>
                  <div class="col-7">
                    <input id="licencia_driver" type="text" pInputText class="p-inputtext-sm" formControlName="nro_licencia" autocomplete="no">
                  </div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="licencia_driver">Nro Celular</label>
                  </div>
                  <div class="col-7">
                    <input id="licencia_driver" type="text" pInputText class="p-inputtext-sm" formControlName="nro_cellphone" autocomplete="no" maxlength="9">
                  </div>
                </label>
              </div>
            </div>


            <!-- Deprecate - Cambio a Programacion -->
            <!-- <div class="row">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="tipo-unidad-imei">Vehículo :</label>
                  </div>
                  <div class="col-7">
                    <div class="p-field">
                      <p-dropdown
                      [filter]="true"
                      [options]="cars"
                      optionLabel="nombre"
                      optionValue="imei"
                      formControlName="tracker_imei"
                      [showClear]="true"
                      [appendTo]="'body'"
                      placeholder="Seleccione el Vehículo..."></p-dropdown>
                    </div>
                  </div>
                </label>
              </div>
            </div> -->
            <div class="row" *appHasPermission="'drivers_view_codecipia'">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="tipo-unidad-imei">Código Cipia:</label>
                  </div>
                  <div class="col-7">
                    <div class="p-field">
                      <p-dropdown
                      [filter]="true"
                      [options]="icipias"
                      optionLabel="value"
                      optionValue="id"
                      formControlName="nro_cipia"
                      [showClear]="true"
                      [appendTo]="'body'"
                      placeholder="Seleccione el Código Cipia..."></p-dropdown>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row" *appHasPermission="'drivers_view_ibutton'">
              <div class="col p-field">
                <label class="row gl-row-underline">
                  <div class="col-5 d-flex align-items-center justify-content-start text-start fw-bold">
                    <label for="tipo-unidad-llave">Nro Llave :</label>
                  </div>
                  <div class="col-7">
                    <div class="p-field">
                      <p-dropdown
                      [filter]="true"
                      [options]="ibuttons"
                      optionLabel="value"
                      optionValue="id"
                      formControlName="nro_llave"
                      [showClear]="true"
                      [appendTo]="'body'"
                      placeholder="Seleccione llave I-button..."></p-dropdown>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <ng-template pTemplate="footer" style="width: 60vw;"> -->
          <div class="row justify-content-center mt-4 pt-2 panel-izq-container-footer">
            <div class="col-4">
                <button type="button" class="btn-gl-cancel btn-dark-hover w-100" (click)="driversService.modalActive=false">Cancelar</button>
            </div>
            <div class="col-4">
                <button type="submit" class="btn-gl-save btn-dark-hover w-100" >Guardar</button>
            </div>
          </div>
      <!-- </ng-template> -->


    </form>

    <ng-template pTemplate="footer" style="width: 60vw;">
    </ng-template>


  </p-dialog>
