<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="fullScreenSpinner" size = "medium" color = "#fff" type = "ball-rotate" [fullScreen] = "true">
  <p *ngIf="!areVehiclesLoaded && !areZonesLoaded" style="color: #ffffff" > Cargando vehículos y zonas... </p>
  <p *ngIf="!areVehiclesLoaded && areZonesLoaded" style="color: #ffffff" > Cargando vehículos... </p>
  <p *ngIf="areVehiclesLoaded && !areZonesLoaded" style="color: #ffffff" > Cargando zonas... </p>
  <p *ngIf="areVehiclesLoaded && areZonesLoaded" style="color: #ffffff" > {{ fullScreenSpinnerMsg }} </p>
  <!-- AGREGAR CARGADO DE DRIVERS Y IBUTTONS -->
</ngx-spinner>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="reloadSpinner" size = "medium" color = "#fff" type = "ball-rotate" [fullScreen] = "true"><p style="color: white" > Recargando página... </p></ngx-spinner>

<p-dialog [position]="popupDialogPosition" [(visible)]="showBlockedTabDialog" key="blockedTabDialog" [style]="popupDialogInlineStyle" [closable]="false" [draggable]="false" [modal]="true" [resizable]="false" id="dialogTest" styleClass="allow-popup-dialog">
  <ng-template pTemplate="header" *ngIf="popupDialogPosition != 'center' || unknownBrowser">
    <div class="pointer-arrows-container">
      <i class="pi pi-angle-double-up" style="font-size: 2rem;"></i>
      <i class="pi pi-angle-double-up" style="font-size: 2rem;"></i>
      <i class="pi pi-angle-double-up" style="font-size: 2rem;"></i>
      <i class="pi pi-angle-double-up" style="font-size: 2rem;"></i>
      <i class="pi pi-angle-double-up" style="font-size: 2rem;"></i>
    </div>
  </ng-template>

  <h4 *ngIf="isChrome">Haz clic en
    <img src="./assets/images/popup-icon-chrome.jpg">
    para habilitar las ventanas emergentes</h4>
  <h4 *ngIf="isOpera">Haz clic en
    <img src="./assets/images/popup-icon-opera.jpg">
    para habilitar las ventanas emergentes</h4>
  <h4 *ngIf="isFirefox">
    Haz clic en el botón "Opciones" para habilitar las ventanas emergentes</h4>
  <h4 *ngIf="isSafari">Haz clic en
    <img src="./assets/images/popup-icon-safari.jpg">
    para habilitar las ventanas emergentes</h4>
  <h4 *ngIf="unknownBrowser">Bloqueo de ventanas emergentes / pop-ups</h4>
    El navegador ha bloqueado el reporte en una nueva pestaña. Por favor, habilita/permite las ventanas emergentes.
    <!-- <svg viewBox="0 2 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: inline-block; height: 1.8rem;"><g>
      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></g>
    </svg> -->
  <p-footer>
    <button type="button" pButton class="btn-gl-save btn-dark-hover" label="Entendido" (click)="dismissBlockedTabDialog();"></button>
  </p-footer>
</p-dialog>

<p-confirmDialog #cd [style]="{width: '30rem'}" [breakpoints]="{'960px': '50vw', '640px': '75vw'}" class="gl-confirm-dialog" key="newTabConfirmation" [closable]="false">
  <ng-template pTemplate="header">
    <h3>Confirmación</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="col-4">
      <button type="button" class="btn-gl-save btn-dark-hover btn-sm w-100" (click)="cd.accept()">Sí</button>
    </div>
    <div class="col-4">
      <button type="button" class="btn-gl-cancel btn-dark-hover btn-sm w-100" (click)="cd.reject()">No</button>
    </div>
  </ng-template>
</p-confirmDialog>
<p-card>
  <ng-template pTemplate="header">
    <span class="d-block p-2 text-white">
      <i class="fas fa-chart-bar"></i>Panel de Reportes</span>
  </ng-template>
    <div class="p-fluid y-form-container" style="font-size: 0.9rem;">
      <div class="row">
        <div class="p-field">
          <label class="bg-subtitle">Reporte :</label>
          <!-- <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" optionValue="id" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." (onChange)="resetAllFields(); changedReport(); validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown> -->
          <p-dropdown [options]="reports" [(ngModel)]="selectedReport" optionLabel="value" [filter]="true" filterBy="value" filterPlaceholder="Buscar..." emptyFilterMessage="No se encontró reporte para el filtro." optionValue="codigo" [appendTo]="'body'" [showClear]="false" placeholder="Seleccione el reporte..." (onChange)="resetAllFields(); changedReport(); validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown>

        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="p-field">
          <label class="bg-subtitle" id="filtrado_label">Filtrar por:</label>
          <p-dropdown [options]="listOptions" [(ngModel)]="listOptionCheckbox" [showClear]="false" optionLabel="idOption" optionValue="valueOption" (onChange)="onOptionSelected();validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown>
          </div>
        </div>
        <div class="col-6" *ngIf="listOptionCheckbox=='operacion' || listOptionCheckbox=='convoy'">
          <div class="p-field">
            <label class="bg-subtitle" id="operation_label">Operaciones:</label>
            <p-dropdown [options]="operations" [(ngModel)]="selectedOperation" [showClear]="true"  optionLabel="nameoperation" optionValue="idoperation" (onChange)="onChangeOperation();validateForm();"  [placeholder]="placeholderOperation" [disabled]="reportService.workingOnReport || disabledOperation"></p-dropdown>
          </div>
        </div>
      </div>
      
      
      <div class="row">
        <div class="col-6" *ngIf="listOptionCheckbox=='grupo'">
          <div class="p-field">
            <label class="bg-subtitle" id="operation_label">Operaciones:</label>
            <p-dropdown [options]="operations" [(ngModel)]="selectedOperation" [showClear]="true" optionLabel="nameoperation" optionValue="idoperation" (onChange)="onChangeOperation();validateForm();"  [placeholder]="placeholderOperation"  [disabled]="reportService.workingOnReport || disabledOperation"></p-dropdown>
          </div>
        </div>
        <div class="col-6" *ngIf="listOptionCheckbox=='grupo' || listOptionCheckbox=='convoy'">
          <div class="p-field">
            <label class="bg-subtitle" id="grupo_label">Grupos:</label>
            <p-dropdown [options]="groups" [(ngModel)]="selectedGroup" [showClear]="true" optionLabel="namegrupo" optionValue="idgrupo" (onChange)="onChangeGroup();validateForm(); " [placeholder]="placeholderGroup" [disabled]="reportService.workingOnReport || disabledGroup"></p-dropdown>
          </div>
        </div>
        <div class="col-6" *ngIf="listOptionCheckbox=='convoy'">
          <div class="p-field">
            <label class="bg-subtitle" id="grupo_label">Convoys:</label>
            <p-dropdown [options]="convoys" [(ngModel)]="selectedConvoy" [showClear]="true" optionLabel="nameconvoy" optionValue="idconvoy" (onChange)="onChangeConvoy();validateForm();" [placeholder]="placeholderConvoy" [disabled]="reportService.workingOnReport || disabledConvoy"></p-dropdown>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="p-field">
            <label class="bg-subtitle" id="vehicle_label">Vehículos:</label>
            <p-multiSelect [options]="vehicles" [(ngModel)]="selectedVehicles" optionLabel="name" [showToggleAll]="true" [appendTo]="'body'" placeholder="Seleccione..." [selectedItemsLabel]="'{0} vehiculos seleccionados'"
            (onChange)="onSelectedVehiclesChange(); validateForm();" [disabled]="reportService.workingOnReport"></p-multiSelect>
            <div style="padding: 0px 0.3rem; margin-top: 0.25rem; text-align: right;">
              <!-- <label class="png-checkbox-container d-flex align-items-center gap-1 ps-1"> -->
              <label>
                <p-checkbox [(ngModel)]="chkAllVehicles" [binary]="true" inputId="binary" (onChange)="selectAllVehicles(); validateForm();" [disabled]="!areVehiclesLoaded || reportService.workingOnReport"></p-checkbox>
                Todos los vehículos
              </label>
            </div>

          </div>
        </div>
        <!-- <div class="col-6">
          <div class="p-field">
            <p-dropdown [options]="pDropdownGroup" [(ngModel)]="checkboxGroup" [showClear]="false" optionLabel="label" optionValue="value" (onChange)="onSelectedConvoyChange(); validateForm();" [styleClass]="'bg-subtitle'" [disabled]="reportService.workingOnReport"></p-dropdown>
            <p-dropdown *ngIf="!checkboxGroup" [options]="convoys" [(ngModel)]="selectedConvoy" [showClear]="false" [appendTo]="'body'" placeholder="Seleccione convoy..." (onChange)="onSelectedConvoyChange(); validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown>
            <p-dropdown *ngIf="checkboxGroup" [options]="groups" [(ngModel)]="selectedGroup" [showClear]="false" [appendTo]="'body'" placeholder="Seleccione grupo..." (onChange)="onSelectedGroupChange(); validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown>
          </div>
        </div> -->
      </div>


      <div class="row" style="padding: 0px 0.3rem">
        <div class="col-6">
          <label>
            <p-checkbox name="groupname" [binary]="true" [(ngModel)]="chkDateHour" (onChange)="onChkDateHourChange(); validateForm();"></p-checkbox>
            Fecha/Hora</label>
        </div>
      </div>
  


      <div class="row" *ngIf="showEvents || showEventsCipia || showAtencionEventsCipia">
        <div class="p-field">
          <label class="bg-subtitle" id="tag_label">Etiquetas de Geocercas :</label>
          <p-multiSelect [options]="listTags" [(ngModel)]="selectedTags" optionLabel="var_name" optionValue="id" [showToggleAll]="false" [appendTo]="'body'" placeholder="Seleccione la(s) etiqueta(s)" (onChange)="onSelectedTagschange(); validateForm();" [disabled]="reportService.workingOnReport"></p-multiSelect>
          <div style="padding: 0px 0.3rem; margin-top: 0.25rem; text-align: right;">
            <label>
              <p-checkbox [(ngModel)]="chkAllTags" [binary]="true" inputId="binary" (onChange)="selectAllTags(); validateForm();" [disabled]="!areTagsLoaded || reportService.workingOnReport"></p-checkbox>
              Todos
            </label>
          </div>
        </div>
      </div>
      

      <div class="row" style="padding: 0px 0.3rem">
        <ng-container *ngIf="showOdomOpt">
          <div class="col-6">
            <label>
              <p-checkbox name="groupname" [binary]="true" [(ngModel)]="chkOdomVirtual" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Odómetro Virtual</label>
          </div>
        </ng-container>
        <ng-container *ngIf="showMovStop">
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkStops" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Paradas</label>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkMovements" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Movimientos</label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showBrakeAccel">
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkFrenada" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Frenada</label>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkAceleracion" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Aceleración</label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="showFatigaDistraccion">
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkFatigaSomnolencia" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Posible Fatiga</label>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field">
              <label>
                <p-checkbox [(ngModel)]="chkFatigaDistraccion" [binary]="true" inputId="binary" (onChange)="validateForm();" [disabled]="reportService.workingOnReport"></p-checkbox>
              Distracción</label>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- <ng-container *ngIf="showExcVelOpt">
        <div class="row">
          <div class="col-6">
            <div class="p-field">
              <p-radioButton name="exVe" value="limVel" label="Límite de Velocidad" [(ngModel)]="excesoVelocidad" (onClick)="showExcVel();"></p-radioButton>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field">
              <p-radioButton name="exVe" value="durExc" label="Duración" [(ngModel)]="excesoVelocidad" (onClick)="showExcVel();"></p-radioButton>
            </div>
          </div>
        </div>
      </ng-container> -->
      <!-- <ng-container *ngIf="showOdomOpt">
        <div class="row">
          <div class="col-6">
            <div class="p-field">
              <p-radioButton name="odomV" value="0" label="Odómetro" [(ngModel)]="odometroVirtual"></p-radioButton>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field">
              <p-radioButton name="odomV" value="1" label="Odómetro Virtual" [(ngModel)]="odometroVirtual"></p-radioButton>
            </div>
          </div>
        </div>
      </ng-container> -->

      <div *ngIf="showExcVelOpt">
        <div class="row">
          <div class="p-field">
            <div class="d-flex p-dropdown-full-width">
              <p-dropdown [options]="pDropdownDuration" [(ngModel)]="checkboxDuration" [showClear]="false" optionLabe="label" optionValue="value" (onChange)="validateForm();" [styleClass]="'bg-subtitle'" [disabled]="reportService.workingOnReport"></p-dropdown>
              <!-- <button class="bg-subtitle btn-toggle-option btn-toggle-left" [ngClass]="!checkboxDuration? '': 'disabled'" (click)="checkboxDuration = false; onSelectedConvoyChange(); validateForm();">Límite de Velocidad</button>
              <button class="bg-subtitle btn-toggle-option btn-toggle-right" [ngClass]="checkboxDuration? '': 'disabled'" (click)="checkboxDuration = true; onSelectedGroupChange(); validateForm();">Duración</button> -->
            </div>
            <p-inputNumber *ngIf="!checkboxDuration" [(ngModel)]="limitSpeed" [showButtons]="true" [min]="0" [max]="300" suffix=" km/h" inputId="limiteVelocidad" [disabled]="reportService.workingOnReport"></p-inputNumber>
            <p-inputNumber *ngIf="checkboxDuration" [(ngModel)]="minimDur" [showButtons]="true" [min]="0" suffix=" seg" inputId="minimaDuracion" [disabled]="reportService.workingOnReport"></p-inputNumber>
          </div>
          <!-- <div class="col-6">
            <div class="p-field" *ngIf="excesoVelocidad == 'limVel'">
              <label class="bg-subtitle" for="limiteVelocidad">Límite de velocidad</label>
              <p-inputNumber [(ngModel)]="limitSpeed" [showButtons]="true" [min]="0" [max]="300" inputId="limiteVelocidad"></p-inputNumber>
            </div>
          </div>
          <div class="col-6">
            <div class="p-field" *ngIf="excesoVelocidad == 'durExc'">
              <label class="bg-subtitle" for="minimaDuracion">Duración</label>
              <p-inputNumber [(ngModel)]="minimDur" [showButtons]="true" [min]="0" suffix=" seg" inputId="minimaDuracion"></p-inputNumber>
            </div>
          </div> -->
        </div>
      </div>





      <div class="row two-column-checkbox-container" *ngIf="showCombustibleOpt">

        <div class="col-12">
          <label class="bg-subtitle">Seleccione la configuración del reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="chkCombustibleResumen" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Resumen</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="!chkCombustibleResumen" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Detallado</label>
              </div>
            </div>
          </div>
        </div>
      </div>







      <div class="row" *ngIf="showLimitTime">
        <div class="col-12">
          <label class="bg-subtitle">Límite de tiempo:</label>
        </div>
        <div class="row" style="margin: 1rem 0rem 1rem 0.3rem; padding: 0px 0.3rem 0px 0px;">
          <div class="col-6">
            <div class="row">
              <div class="p-field">
                <label>Fecha inicio:
                  <p-calendar
                    [(ngModel)]="dateInit"
                    dateFormat="dd/mm/yy"
                    [maxDate]="dateEnd"
                    [appendTo]="'body'"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="{{strYearRange}}"
                    (onSelect)="onTimeChange(); validateForm();"
                    (onBlur)="onTimeChange(); validateForm();"
                    (onInput)="onTimeChange(); validateForm();"
                    (onClickOutside)="onTimeChange(); validateForm();"
                    [disabled]="reportService.workingOnReport"
                    ></p-calendar>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="p-field">
                <label>Hora inicio:
                  <p-calendar
                    [(ngModel)]="timeInit"
                    [ngClass]="{'invalid-input': !areHoursValid}"
                    [timeOnly]="true"
                    [appendTo]="'body'"
                    (onSelect)="onTimeChange(); validateForm();"
                    (onBlur)="onTimeChange(); validateForm();"
                    (onInput)="onTimeChange(); validateForm();"
                    (onClickOutside)="onTimeChange(); validateForm();"
                    [disabled]="reportService.workingOnReport"
                    ></p-calendar>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="p-field">
                <label>Fecha final:
                  <p-calendar
                    [(ngModel)]="dateEnd"
                    dateFormat="dd/mm/yy"
                    [minDate]="dateInit"
                    [appendTo]="'body'"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="{{strYearRange}}"
                    (onSelect)="onTimeChange(); validateForm();"
                    (onBlur)="onTimeChange(); validateForm();"
                    (onInput)="onTimeChange(); validateForm();"
                    (onClickOutside)="onTimeChange(); validateForm();"
                    [disabled]="reportService.workingOnReport"
                    ></p-calendar>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="p-field">
                <label>Hora final:
                  <p-calendar
                    [(ngModel)]="timeEnd"
                    [ngClass]="{'invalid-input': !areHoursValid}"
                    [timeOnly]="true"
                    [appendTo]="'body'"
                    (onSelect)="onTimeChange(); validateForm();"
                    (onBlur)="onTimeChange(); validateForm();"
                    (onInput)="onTimeChange(); validateForm();"
                    (onClickOutside)="onTimeChange(); validateForm();"
                    [disabled]="reportService.workingOnReport"
                    ></p-calendar>
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="row justify-content-end fw-bold" *ngIf="!areHoursValid">
          *La hora de inicio no puede ser mayor a la hora final
        </div>
      </div>
      <div class="row" *ngIf="showZones">
        <div class="p-field">
          <label class="bg-subtitle" id="zone_label">Geocercas :</label>
          <p-multiSelect [options]="zones" [(ngModel)]="selectedZones" optionLabel="zone_name" optionValue="id" [showToggleAll]="false" [appendTo]="'body'" placeholder="Seleccione la(s) geocerca(s)" (onChange)="onSelectedGeofenceschange(); validateForm();" [disabled]="reportService.workingOnReport"></p-multiSelect>
          <div style="padding: 0px 0.3rem; margin-top: 0.25rem; text-align: right;">
            <label>
              <p-checkbox [(ngModel)]="chkAllZones" [binary]="true" inputId="binary" (onChange)="selectAllZones(); validateForm();" [disabled]="!areZonesLoaded || reportService.workingOnReport"></p-checkbox>
              Todos
            </label>
          </div>
        </div>
      </div>



      <div class="row two-column-checkbox-container" *ngIf="showTipoExcesoVelocidad">

        <div class="col-12">
          <label class="bg-subtitle">Seleccione el tipo de reporte de exceso:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label class="bg-subtitle" >Duración:</label>
                <!-- <p-dropdown [options]="listOptionsDuracion" [(ngModel)]="selectedDuracion" [showClear]="false" optionLabel="" optionValue="valueOption" (onChange)="onOptionSelected();validateForm();" [disabled]="reportService.workingOnReport"></p-dropdown> -->
                <p-dropdown [options]="listOptionsDuracion" [(ngModel)]="selectedDuracion" optionLabel="name" [showClear]="true"  (onChange)="onchangeDuracion();" placeholder="Seleccionar Duración"></p-dropdown>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label class="bg-subtitle" >Tolerancia:</label>
                <p-dropdown [options]="listOptionsToleracia" [(ngModel)]="selectedToleracia" optionLabel="name" [showClear]="true" placeholder="Selecciona Tolerancia"></p-dropdown>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
                <div class="p-field">
                    <label><p-checkbox name="groupname" [binary]="true" [(ngModel)]="chkRiesgo"></p-checkbox>Nivel de Riesgo</label>
                </div>
            </div>
            <div class="col-6" >

            </div>
          </div>

        
        </div>

      </div>



      <div class="row two-column-checkbox-container" *ngIf="showCheckboxs">

        <div class="col-12">
          <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.fServidor" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Fecha Servidor</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.pCercano" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Punto Cercano</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velCAN" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad CAN</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velGPS" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad GPS</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velGPS_speed" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad GPS_speed</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velMobileye_ME460" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad Mobileye</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velECO" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad ECO</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.DUOT2state" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                DUOT2 state</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.RxM" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  RPM</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.RPMAlta" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  RPM Alta</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.cNivel" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Nivel de Combustible</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.cRestante" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Combustible Restante</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.cMotor" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Consumo de Motor</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.odometro" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Odómetro</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.altitud" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Altitud</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.angulo" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Angulo</label>
              </div>
            </div>
            <!-- ALIMENTACION GPS -->
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.alimentGps" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Alimentación GPS</label>
              </div>
            </div>
            <!-- NIVEL DE BATERIA -->
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.nivelBateria" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Nivel de batería</label>
              </div>
            </div>
            <!-- NIVEL DE COBERTURA -->
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.nivelCobertura" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Nivel de cobertura</label>
              </div>
            </div>
            <!-- NIVEL DE TEMPERATURA GPS -->
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.temperaturaGps" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Temperatura GPS</label>
              </div>
            </div>
            <!-- SATELITE -->
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.satelite" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Satélite</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.recFacial" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Reconocimiento Facial</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.onOff" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Ignición</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.alcoholemia" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Alcoholemia</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.parametros" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Parámetros</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.ubicacion" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Ubicación</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.referencia" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Referencia</label>
              </div>
            </div>
          </div>
          <!-- ON / OFF AHORA ES IGNICION?? -->
          <!-- <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.onOff" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  On / Off</label>
              </div>
            </div>
          </div> -->
        </div>

      </div>


      <div class="row two-column-checkbox-container" *ngIf="showCheckboxsCipia">

        <div class="col-12">
          <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.fServidor" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Fecha Servidor</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.ubicacion" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Ubicación</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.velGPS" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Velocidad 360°</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="oG.parametros" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport"></p-checkbox>
                Parámetros</label>
              </div>
            </div>
          </div>



        </div>

      </div>


      <div class="row two-column-checkbox-container" *ngIf="showEvents || showEventsCipia || showAtencionEventsCipia">
        <div class="col-12">
          <label class="bg-subtitle">Seleccione los eventos a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

        <ng-container *ngFor="let type of eventsTypes">
          <ng-container *ngIf="type.active">
            <div class="row">
              <div class="col-12">
                <label class="bg-subtitle bg-subtitle-light">Eventos {{type.label}}
                  <!-- <p-checkbox [(ngModel)]="type.selectAll" [binary]="true" inputId="binary" (onChange)="onSelectAllChange(type)" [disabled]="reportService.workingOnReport"></p-checkbox> -->
                </label>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let event of events">
                <ng-container *ngIf="event.event_category == type.name">
                  <div class="col-6" style="margin-top: 5px" >
                    <div class="p-field">
                      <label>
                        <p-checkbox [(ngModel)]="event.active" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                        {{ event.name_event }}
                      </label>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
         
          

          <!-- <div class="row">
            <div class="col-12">
              <label class="bg-subtitle bg-subtitle-light">Eventos GPS</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6" style="margin-top: 5px" >
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSbateriaDesconectada" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Batería Desconectada</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px" >
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSbateriaBaja" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Batería Baja del GPS</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSaceleracionBrusca" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Aceleracíon Brusca</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSextremAceleracionBrusca" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Aceleracíon Extremadamente Brusca</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSfrenadaBrusca" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Frenada Brusca</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSextremFrenadaBrusca" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Frenada Extremadamente Brusca</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSsos" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                SOS</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSmotorEncendido" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Motor Encendido</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSmotorApagado" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Motor Apagado</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSdriverDetected" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Conductor Identificado</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSdriverNotDetected" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Conductor No Identificado</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSmanipuled" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Manipulación De Dispositivo</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSjamming" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Jamming</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.GPSantenaOff" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                Antena GPS desconectada</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="bg-subtitle bg-subtitle-light mt-2">Eventos Plataforma</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evEntrada" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Zona de Entrada</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evSalida" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Zona de Salida</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evEstadia" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Tiempo de Estadia en Zona</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evParada" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Parada en Zona no Autorizada</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evExcesoDeVelocidad" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Exceso de Velocidad</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evInfraccion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Infracción</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="bg-subtitle bg-subtitle-light mt-2">Eventos Seguridad Vehicular</label>
            </div>
          </div>
          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evAnticolisionFrontal" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Anticolisión Frontal</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evColisionConPeatones" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Riesgo de Colisión con Peatones</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evNoRostro" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  No Rostro</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evFatigaExtrema" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fatiga Extrema</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDesvioCarrilIzquierda" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Desvío de Carril Hacia la Izquierda</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDesvioCarrilDerecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Desvío de Carril Hacia la Derecha</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evBloqueoVisionMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Bloqueo de Visión del Mobileye</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.AccAlcoholemia" [binary]="true" inputId="binary"></p-checkbox>
                  Alcoholemia</label>
              </div>
            </div> 
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.AccFatiga" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Posible Fatiga</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.AccDistraccion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Distracción</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evVibracionSensorFatiga" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vibración del cojín de Fatiga</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="bg-subtitle bg-subtitle-light mt-2">Eventos Soluciones Móviles</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDvrOperativo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  DVR Operativo</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDvrInoperativo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  DVR Inoperativo</label>
              </div>
            </div>
          </div>
         
          <div class="row">
            <div class="col-12">
              <label class="bg-subtitle bg-subtitle-light mt-2">Eventos Fatiga 360º</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualización de Estado 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Completada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Fallida 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Iniciada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Advertencia de Cambio de Carril 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Advertencia de Colisión con Peatones 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Advertencia de Colisión Frontal 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Advertencia de Colisión Frontal Urbana 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Calibracion Completada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Calibración del acelerómetro 3D completada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Error de calibración 360°°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Iniciada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Iniciada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Actualizacion FW Iniciada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorAdormitado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor Adormitado 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evConductorSomnoliento360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor Somnoliento 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDistraccionDetectada360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Distracción Detectada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evCinturonNoDetectado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Cinturón no Detectado 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDistraccionDetectada360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Distracción Detectada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evCinturonNoDetectado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Cinturón no Detectado 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evCigarroDetectado" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Cigarro Detectada 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evCelularDetectado360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Celular Detectado 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evErrorDeCamara" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Error de Cámara 360°</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eV.evDeteccionDeManipulacion360" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Detección de Manipulación 360°</label>
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <!-- <label>
                  <p-checkbox [(ngModel)]="eV.OtroTodos" [binary]="true" inputId="binary" (onChange)="onChkAllEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Todos</label> -->
                <!-- <label>
                  <p-checkbox [(ngModel)]="eV.OtroTodos" [binary]="true" inputId="binary" (onChange)="onChkAllEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Todos los Eventos</label> -->
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
              </div>
            </div> 
          </div> 

        

        </div>
      </div>


      <div class="row two-column-checkbox-container" *ngIf="showEvents || showEventsCipia">
        <div class="col-12">
          <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

   
          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Fecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.FechaServidor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha Servidor</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Evento" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Descripción</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Código</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Placa</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.TipoUnidad" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Tipo de Unidad</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  ID Conductor</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. Mobileye</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelGPS" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. GPS</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. GPS speed</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. CAN</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelECO" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. ECO</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Geocerca</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.PuntoCercano" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Punto Cercano</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Ubicación</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Referencia" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Referencia</label>
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.EnlaceArchivo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Enlace a Archivo</label>
              </div>
            </div>
            <div class="col-6" *ngIf="selectedReport == 'R040'" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Parametros" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Parámetros</label>
              </div>
            </div>
          </div>


          <!-- <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Evento" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Evento</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Código</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Placa</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.TipoUnidad" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Tipo de Unidad</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  ID Conductor</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. Mobileye</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelGPS" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. GPS</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                 <label>
                  <p-checkbox [(ngModel)]="eC.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. Mobileye</label> 
              </div>
            </div>
            <div class="col-6" style="margin-top: 5px">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. GPS speed</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. CAN</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelECO" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. ECO</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Geocerca</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.PuntoCercano" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Punto Cercano</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Ubicación</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Referencia" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Referencia</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.EnlaceArchivo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Enlace a Archivo</label>
              </div>
            </div>
            <div class="col-6" *ngIf="selectedReport == 'R040'">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Parametros" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Parámetros</label>
              </div>
            </div>
          </div> -->

        </div>
      </div>

      <div class="row two-column-checkbox-container" *ngIf="showAtencionEventsCipia">
        <div class="col-12">
          <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

   
          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Fecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.FechaServidor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha Servidor</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Evento" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Descripción</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Código</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Placa</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.TipoUnidad" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Tipo de Unidad</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  ID Conductor</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.FechaEvaluacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha de Evaluación</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.CriterioEvaluacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Criterio de Evaluación</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Observacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Observación</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Validacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Validación</label>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Vel. CAN</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.VelGPS" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Vel. GPS</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Vel. Mobileye</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.VelECO" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Vel. ECO</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Geocerca</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.PuntoCercano" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Punto Cercano</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Ubicación</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Referencia" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Referencia</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.OperadorMonitoreo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Operador de Monitoreo</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.EnlaceArchivo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Enlace a Archivo</label>
                <!-- <label>
                  <p-checkbox [(ngModel)]="eC.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. GPS speed</label> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.ComRadial" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()"
                    [disabled]="reportService.workingOnReport"></p-checkbox>
                  Comunicación radial</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
              </div>
            </div>
          </div>

        </div>
      </div>
      

      <div class="row two-column-checkbox-container" *ngIf="showTipoExcesoVelocidad">
        <div class="col-12">
          <label class="bg-subtitle">Seleccione las opciones a incluir en el reporte:</label>
        </div>

        <div class="col-12 d-flex flex-column gap-2 py-3" style="padding: 0rem 1.25rem;">

   
          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Fecha" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Fecha</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.hora" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Hora</label>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Placa" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Placa</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Codigo" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Código Interno</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.IdConductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  ID Conductor</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.Conductor" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Conductor</label>
              </div>
            </div>
          </div>
      

          <div class="row">
            <div class="col-6">
              <div class="p-field">
                <label>
                  <p-checkbox [(ngModel)]="eC.VelMobileye" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                  Vel. Mobileye</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                    <label>
                      <p-checkbox [(ngModel)]="eC.VelGPSspeed" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                      Vel. GPS speed</label>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.VelCAN" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Vel. CAN</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.Zona" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Geocerca</label>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.Ubicacion" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Ubicación</label>
              </div>
            </div>
            <div class="col-6">
              <div class="p-field">
                  <label>
                    <p-checkbox [(ngModel)]="eC.Satelite" [binary]="true" inputId="binary" (onChange)="onChangeChkEvents()" [disabled]="reportService.workingOnReport"></p-checkbox>
                    Satélite</label>
              </div>
            </div>
          </div>



        </div>
      </div>
      


      <div class="row" *ngIf="showChkSimultaneousTables">
        <div class="p-field col-12 d-flex" style="padding: 0rem 1.25rem;">
          <label class="pe-2">
            <p-checkbox [(ngModel)]="chkSimultaneousTables" [binary]="true" inputId="binary" [disabled]="!isEverythingLoaded || reportService.workingOnReport" (onChange)="logDropState()"></p-checkbox>
            Mostrar tablas en simultáneo</label>
          <span class="flex-none center-icon neutral-icon-color">
            <i class="fa fa-question-circle fa-lg" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="auto" title="Se recomienda desactivar esta opción si se han seleccionado 20 o más vehículos."></i>
          </span>
        </div>
      </div>

    </div>

  <ng-template pTemplate="footer">
    <button [disabled]="!isFormFilled || reportService.workingOnReport" (click)="getConfirm()" class="btn-gl-save btn-dark-hover" style="width: 10rem; border: none;">Generar Reporte</button>
    <button [disabled]="!isFormFilled || reportService.workingOnReport" (click)="reportar_reporte_programado()"  hidden="hidden">Guardar Reporte Programado</button>

    <!-- <button [disabled]="!isFormFilled || reportService.workingOnReport" (click)="confirm()" class="btn-gl-save btn-dark-hover" style="width: 10rem; border: none;">Generar Reporte</button> -->
    <!-- <label>
      <p-checkbox [(ngModel)]="!chkTableDropdown" [binary]="true" inputId="binary" [disabled]="reportService.workingOnReport" (onChange)="logDropState()"></p-checkbox>
      Mostrar tablas en simultáneo</label> -->
  </ng-template>
</p-card>