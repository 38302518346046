import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RepProgramadosService } from '../../services/rep-programados.service';
import { VehicleService } from '../../../vehicles/services/vehicle.service';
import { UserTracker } from 'src/app/multiview/models/interfaces';



import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import * as moment from 'moment';

@Component({
  selector: 'app-rp-add',
  templateUrl: './rp-add.component.html',
  styleUrls: ['./rp-add.component.scss']
})
export class RpAddComponent implements OnInit {

  ListVehicles: any=[];
  areVehiclesLoaded = false;
  errorFlag = 0;
  fullScreenSpinnerMsg:any = '';
  form: any = {};
  reports: any=[];
  reportesProgramados: any=[];
  selectedReport: any;
  selectedVehicles:any;

  nom_reporte_add:any;
  vehicles:any;


  pDropdownFormatoExport: any= [
    { label: 'EXCEL', value: 'excel' },
    { label: 'HTML', value: 'html' },
    { label: 'PDF', value: 'pdf' }
  ];

  pDropdownPeriodoDias: any= [
    { label: '1 día',  value: 1 },
    { label: '2 días', value: 2 },
    { label: '3 días', value: 3 },
    { label: '4 días', value: 4 },
    { label: '5 días', value: 5 },
    { label: '6 días', value: 6 },
    { label: '7 días', value: 7 },
    { label: '8 días', value: 8 },
    { label: '9 días', value: 9 },
    { label: '10 días', value: 10 },
    { label: '11 días', value: 11 },
    { label: '12 días', value: 12 },
    { label: '13 días', value: 13 },
    { label: '14 días', value: 14 },
    { label: '15 días', value: 15 }
  ];

  pDropdownIntervalos: any= [
    { label: 'Diariamente',  value: 'diariamente' },
    { label: 'semanal', value: 'semanal' },
    { label: 'quincenal', value: 'quincenal' },
    { label: 'mensual', value: 'mensual' }
  ];

  listOptionsDuracion: any= [
    // { name: '0 segundos', code: '0' },
    { name: '15 segundos', code: 15 },
    { name: '30 segundos', code: 30 },
    { name: '45 segundos', code: 45 },
    { name: '60 segundos', code: 60 }
  ];
  listOptionsToleracia: any= [
    // { name: 'Toleracia 0', code: '0' },
    { name: '3 km/h', code: 3 },
    { name: '5 km/h', code: 5 },
    { name: '10 km/h', code: 10 }
  ];
 
  
  //Reporte 5 - Reporte General
  oG = {
      DUOT2state:false, // --- NEW2
      RPMAlta:false,
      RxM:false,
      aBrusca:false,
      alcoholemia:false,
      altitud:false,
      angulo:false,
      cMotor:false,
      cRestante: false, // --- NEW
      fBrusca:false,
      fExBrusca:false,
      fServidor:false,   // --- NEW
      fatiga:false,
      cNivel:false,  //  NEW
      odometro:false,
      onOff:false,
      pCercano:false,
      parametros: false,  // --- NEW
      recFacial:false,
      referencia:false,
      ubicacion:false,
      velCAN:false,
      velECO:false,
      velGPS:false,
      velGPS_speed:false,
      velMobileye_ME460:false,
      //NEW 24-01
      alimentGps:false,
      nivelBateria:false,
      nivelCobertura:false,
      temperaturaGps:false,
      satelite:false,
    };

    eC = {
      Fecha :true,
      Hora :false,
      FechaServidor :false,
      Evento :true,
      Codigo :true,
      Placa :true,
      TipoUnidad :false,
      IdConductor :false,
      Conductor :false,
  
      FechaEvaluacion : false,
      CriterioEvaluacion : false,
      Observacion : false,
      Validacion: false,
  
      VelMobileye :false,
      VelGPS :true,
      VelCAN :false,
      VelECO :false,
      VelGPSspeed :false,
  
      Zona :false,
      PuntoCercano :false,
      Ubicacion :false,
      Referencia :false,
      EnlaceArchivo :false,
      Parametros : false,
      Satelite:false,
  
      OperadorMonitoreo : false,  // R. Atención de Eventos
    }

    //Reporte 6 - Reporte de Eventos
    eV: { [key: string]: boolean } = {};
  workingOnReport = false;


  showSubLimitTime = true;

  showCard = false; //Div que contiene [ showExcVelOpt - showMovStop - showZones - showCheckboxs ]
  showExcVelOpt = false; //Limite de velocidad
  showOdomOpt = false; // Combustible
  showZones = false; // Seleccionador de geocercas
  showCheckboxs = false;// Opciones reporte general
  showCheckboxsCipia = false;// Opciones reporte general CIPIA
  showTipoExcesoVelocidad = false; //Opciones excesos de velocidad duracion tolerancia
  showMovStop = false; //Ver Paradas y Movimiento
  showDivHorizontal = false; // Nombre de cabecera del reporte
  showLimitTime = false; //Configuracion de rango de tiempo -- true la mayoria
  showEvents = false; //Configuracion
  showEventsCipia = false; //Configuracion
  showAtencionEventsCipia = false;
  showTrans1min = false; //Configuracion de duracion de parada >1min
  showFatigaOp = false; //Configuracion de opcion de fatiga 2
  showBrakeAccel = false; //Configuración Aceleracion y frenada
  showFatigaDistraccion = false; //Configuracion Distraccion y posible fatiga
  showCombustibleOpt = false; //Configuracion de reporte de combustible



  //Removido del formulario
  chkDateHour = true; //False muestra fecha y h juntas. true separadas

  //Reporte 0 - Paradas y Movi
	chkStops: boolean = true;
	chkMovements: boolean = true;
  // showMovStop = false;

  //Reporte 1 - Exceso de Vel
  // showExcVelOpt: boolean = false;
  checkboxDuration: boolean = false;
  minimDur = 15;
  limitSpeed = 90;

  pDropdownDuration: any= [
    { label: 'Límite de Velocidad', value: false },
    { label: 'Duración', value: true }
  ];

  pDropdownDiasSemana: any= [
    { label: 'Domingo', value: 0 },
    { label: 'Lunes', value: 1 },
    { label: 'Martes', value: 2 },
    { label: 'Miercoles', value: 3 },
    { label: 'Jueves', value: 4 },
    { label: 'Viernes', value: 5 },
    { label: 'Sábado', value: 6 },

  ];

  pDropdownDiasMes: any= [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },    
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },   
    { label: '30', value: 30 },
  ];


  //Reporte 2 - Entrada y Salida
  // showZones: boolean = false;
  selectedZones: any =[];
  // selectedTags: any =[];

  chkAllTags = false;


    
  constructor(
    public repProgramadosService: RepProgramadosService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }



  ngOnInit(): void {
    // private URL_LIST = environment.apiUrl + '/api/tracker';
    this.reports = this.repProgramadosService.listReportes;
    // this.selectedReport = this.repProgramadosService.reporteProgramado.p_numRep;
    // this.http.get<any>(environment.apiUrl + '/api/tracker').subscribe({
    //   next: data => {
    //       // this.listTags = data.data;
    //       console.log("vehiculos : ", data);
    //       this.vehicles = this.reducedInfoVehicles(data);
    //       this.ListVehicles = this.vehicles;
    //       console.log('Vehicles: ',this.vehicles);
    //   },
    //   error: () => {
    //     console.log('Fallo al obtener vehiculos');
    //   }
    // });

    this.vehicles = this.repProgramadosService.vehicles;
    this.ListVehicles = this.vehicles;

    
    this.repProgramadosService.modal_add = false;

    this.repProgramadosService.events.forEach((ev:any) => {
      ev.active = false;
    });

    console.log('Event types -->',this.repProgramadosService.eventsTypes,this.repProgramadosService.events);


    
    if (this.repProgramadosService.modal_add0) {
      console.log("=============================== add");
      // console.log(this.selectedReport);
      // const hoy = Date.now();
      // this.form = {
      //     p_numRep            : '',//this.nom_reporte_add.codigo,
      //     nombre_reporte      : '',//this.nom_reporte_add.value,
      //     nombre_programacion : '',
      //     bol_activo          : true,
      //     p_vehiculos         : [],
      //     p_fecha_desde       : new Date(),
      //     p_fecha_hasta       : new Date(),
      //     p_chkDateHour : false,
      //     p_chkStops : true,
      //     p_chkMovements : true,
      //     p_checkboxDuration: true,
      //     p_limitSpeed: 10,
      //     p_minimDur:20,
      //     p_zonas: [],
      //     p_selectedDuracion:"--",
      //     p_selectedToleracia:"--",
      //     p_chkRiesgo:true,
      //     p_ec : this.eC,
      //     p_og : this.oG,
      //     intervalo           : 'mensual',
      //     intervalo_hora      : new Date(),
      //     periodo_dias        : 7,
      //     formato_exportacion : 'html',
      //     notificacion_email  : true,
      //     notificacion_email_lista:''
      //     // hora_programado2    : new Date(moment(hoy).format("MM/DD/YYYY")),
      // };
      //==========================================================================================
      // this.form.nombre_reporte    = '';//this.nom_reporte_add.codigo,
      // this.form.p_numRep          = '';//this.nom_reporte_add.value,
      // this.form.bol_activo          = true;
      // this.form.nombre_programacion = '';
      // this.form.p_vehiculos       = [];
      // this.form.p_fecha_desde =  new Date("2020-10-10 00:00:00");
      // this.form.p_fecha_hasta =  new Date("2020-10-10 00:00:00");
      // this.form.p_chkDateHour          = true;
      // this.form.p_selectedDuracion    = null;
      // this.form.p_selectedToleracia   = null;
      // this.form.p_chkRiesgo           = true;
      // console.log("---------------- this.eC");
      // console.log(this.eC);
      // this.form.p_ec =  this.eC;
      // this.form.p_og =  this.oG;
      // this.form.intervalo =  'mensual';
      // this.form.intervalo_hora =  new Date("2020-10-10 00:00:00");
      // this.form.periodo_dias_semanal =  1;
      // this.form.periodo_dias_mensual =  1;
      // this.form.formato_exportacion =  'html';


    } else {
      console.log("=============================== edit");
      console.log(this.repProgramadosService.reporteProgramado);
      this.selectedReport = this.repProgramadosService.reporteProgramado.p_numRep;
      console.log(typeof this.selectedReport);
      this.changedReport();

      var rp = this.repProgramadosService.reporteProgramado;
      console.log(this.vehicles);
      console.log(rp.p_vehiculos);
      // console.log(rp.p_ev);
      
      // console.log(JSON.parse(rp.p_vehiculos));
      //-----------------------------------------------------------------------------------------------------------------------------------

      var p_vehiculos = JSON.parse(rp.p_vehiculos);
      var vehiculos_imei = [];
      for (let index = 0; index < p_vehiculos.length; index++) {
        const element = p_vehiculos[index];
        var unidad = this.vehicles.find((vehicle:any) => vehicle.IMEI === element.IMEI);
        vehiculos_imei.push(unidad);
      }
      // console.log(vehiculos_imei);

      var duracionExcesos_aux = this.listOptionsDuracion.find((list:any) => list.code === rp.p_duracionExcesos);
      var toleranciaExcesos_aux = this.listOptionsToleracia.find((list:any) => list.code === rp.p_toleraciaExcesos);
      // console.log(duracionExcesos_aux);
      // console.log(toleranciaExcesos_aux);

      var p_ec_aux = JSON.parse(rp.p_ec)[0];
      var p_og_aux = JSON.parse(rp.p_og)[0];

      var p_tags_zonas_aux = JSON.parse(rp.p_tags_zonas);

      var p_ev_aux = JSON.parse(rp.p_ev)[0];
      console.log("p_ev_aux --> ",p_ev_aux);
      this.repProgramadosService.events.forEach((event: {name_form: any; active: boolean;}) => {
        event.active = p_ev_aux[event.name_form];
      });


      //------------------------------------------------------------------------------------------------------------------------------------
      // const hoy = Date.now();
      // this.form = rp;
      this.form.id                = rp.id;
      this.form.nombre_reporte    = rp.nombre_reporte;
      this.form.p_numRep          = rp.p_numRep;
      this.form.p_url             = rp.p_url;

      
      this.form.bol_activo          = rp.bol_activo;
      this.form.nombre_programacion = rp.nombre_programacion;
      // this.form.p_vehiculos    = []; //JSON.parse(rp.p_vehiculos),
      this.form.p_vehiculos       = vehiculos_imei;

      this.form.p_fecha_desde =  new Date(rp.p_fecha_desde);
      this.form.p_fecha_hasta =  new Date(rp.p_fecha_hasta);

      this.form.p_chkDateHour          = rp.p_chkDateHour;

      this.form.p_selectedDuracion    = duracionExcesos_aux;
      this.form.p_selectedToleracia   = toleranciaExcesos_aux;
      this.form.p_chkRiesgo           = rp.p_chkRiesgo;

      this.form.p_ec =  p_ec_aux;
      this.form.p_og =  p_og_aux;
      this.form.p_tags_zonas =  p_tags_zonas_aux;

      

      this.form.intervalo =  rp.intervalo;
      this.form.intervalo_hora =  new Date(rp.intervalo_hora);
      this.form.periodo_dias_semanal =  rp.periodo_dias_semanal; 
      this.form.periodo_dias_mensual =  rp.periodo_dias_mensual;
      this.form.formato_exportacion =  rp.formato_exportacion;

      this.form.notificacion_email       = rp.notificacion_email;
      this.form.notificacion_email_lista = rp.notificacion_email_lista;

      // this.form = {

      //     // p_numRep              : rp.p_numRep,
      //     // nombre_reporte        : rp.nombre_reporte,
      //     // nombre_programacion   : rp.nombre_programacion,
      //     // bol_activo            : rp.bol_activo,

      //     // p_vehiculos           : rp.p_vehiculos,
      //     // p_fecha_desde         : new Date(rp.p_fecha_desde),
      //     // p_fecha_hasta         : new Date(rp.p_fecha_hasta),

      //     intervalo           : 'mensual',
      //     intervalo_hora      : new Date(),
      //     periodo_dias        : 7,
      //     formato_exportacion : 'html',

      //     // notificacion_email: rp.notificacion_email,
      //     // notificacion_email_lista:rp.notificacion_email_lista,

      //     // hora_programado   : {label:'00:00', value:0},
      //     // hora_programado2  : new Date(moment(hoy).format("MM/DD/YYYY")),  
      // };
      
    }

    console.log(" ----- init ");
    console.log(this.form);
    
    
    
  }

  reducedInfoVehicles(vehicles:any) {
    // Reducir y especificar la cantidad de informacion a enviar por vehiculo
    const reducedVehicles = vehicles.map((vh: UserTracker) => {
      return {
        IMEI: vh.IMEI,
        name: vh.name,
        driver_id: vh.driver_id,
        namegrupo: vh.namegrupo,
        idgrupo: vh.idgrupo,
        nameconvoy: vh.nameconvoy,
        idconvoy: vh.idconvoy,
        nameoperation: vh.nameoperation,
        idoperation: vh.idoperation,
        tipo: vh.tipo,
      };
    });
    return reducedVehicles;
  }

  selectAllTags(){
    // this.selectedTags = this.chkAllTags? this.repProgramadosService.listTags.map((tag: { id: any; }) => { return tag.id}): [];
    this.form.p_tags_zonas = this.chkAllTags? this.repProgramadosService.listTags.map((tag: { id: any; }) => { return tag.id}): [];
  }

  
  cancelarReporteProgramado() {
    console.log('=======> cancelarAddEditReporteProgramado ');
    this.repProgramadosService.modalReportesProgramadosActiveAddEdit = false;
  }


  agregarReporteProgramado() {
    console.log('=======> agregarReporteProgramadoFase2 ');

    console.log(this.reports);
    console.log(this.selectedReport);
    this.nom_reporte_add = this.reports.find((report:any) => report.codigo === this.selectedReport);
    console.log("-- this.nom_reporte_add --");
    console.log(this.nom_reporte_add);


    //------------------------------------------------------------------------------------
    this.form.p_numRep        = this.nom_reporte_add.codigo;
    this.form.nombre_reporte  = this.nom_reporte_add.value;
    this.form.p_url           = this.nom_reporte_add.url;



    // this.form.nombre_reporte    = '';//this.nom_reporte_add.codigo,
    // this.form.p_numRep          = '';//this.nom_reporte_add.value,

    this.form.bol_activo          = true;
    this.form.nombre_programacion = '';

    this.form.p_vehiculos       = [];

    this.form.p_fecha_desde =  new Date("2020-10-10 00:00:00");
    this.form.p_fecha_hasta =  new Date("2020-10-10 00:00:00");

    this.form.p_chkDateHour          = true;

    this.form.p_selectedDuracion    = null;
    this.form.p_selectedToleracia   = null;
    this.form.p_chkRiesgo           = true;

    console.log("---------------- this.eC");
    console.log(this.eC);
    
    this.form.p_ec =  this.eC,
    this.form.p_og =  this.oG,
    this.form.p_tags_zonas =  [];


    this.form.intervalo =  'diariamente';
    this.form.intervalo_hora =  new Date("2020-10-10 00:00:00");
    this.form.periodo_dias_semanal =  1;
    this.form.periodo_dias_mensual =  1;
    this.form.formato_exportacion =  'excel';

    this.form.notificacion_email       = true;
    this.form.notificacion_email_lista = '';

    //---------------------------------------------------------------------------------------




    this.repProgramadosService.modal_add = true;
    this.repProgramadosService.titulo_modal_add_edit = "Agregar Nuevo Reporte Programado";
    this.repProgramadosService.modalReportesProgramadosActiveAddEdit = true;
  }

  resetAllFields() {

  }

  onChkDateHourChange(){
    console.log("--- onChkDateHourChange ---");

  }

  onSelectedTagschange(){
    this.chkAllTags = this.repProgramadosService.listTags.length == this.form.p_tags_zonas.length; // this.selectedTags.length;
  }
  
  onChangeChkEvents(){


    // this.events.forEach((event: {name_form: any; active: boolean;}) => {
    //   event.active = this.eV.OtroTodos;
    //   this.eV[event.name_form] = event.active;
    // });

    // this.eventsTypes.forEach((type: {selectAll: boolean;}) => {
    //   type.selectAll = this.eV.OtroTodos;
    // });
    // console.log(this.eV);

    console.log("----- onChangeChkEvents -----");

    //   this.eV.OtroTodos = true;
    // Ahora, para actualizar this.ev con data de this.events:
    this.repProgramadosService.events.forEach((event: { name_form: string | number; active: boolean; }) => {
      this.eV[event.name_form] = event.active;
    });
    // Verificar si al menos un event.active es false
    const alMenosUnFalse = this.repProgramadosService.events.some((event: { active: boolean; }) => event.active === false);
    // Actualizar this.ev.OtroTodos
    this.eV.OtroTodos = !alMenosUnFalse;
    // console.log(this.eV, this.eC, this.oG);
    // console.log(this.eV);
    // console.log(this.eV.OtroTodos);

  }


  onchangeDuracion(){
    console.log(this.form.p_selectedDuracion);
    console.log(this.form.p_selectedToleracia);
    console.log(this.form.p_chkRiesgo);

  }

  changedReport(){
    console.log("---------- changedReport -------------");
    
    console.log(this.selectedReport);
    console.log(typeof this.selectedReport);

    //this.titleService.setTitle(this.reports[this.selectedReport].value);
    this.showSubLimitTime = true;

		this.showCard = false; //Div que contiene [ showExcVelOpt - showMovStop - showZones - showCheckboxs ]
		this.showExcVelOpt = false; //Limite de velocidad
    this.showOdomOpt = false; // Combustible
		this.showZones = false; // Seleccionador de geocercas
		this.showCheckboxs = false;// Opciones reporte general
    this.showCheckboxsCipia = false;// Opciones reporte general CIPIA
    this.showTipoExcesoVelocidad = false; //Opciones excesos de velocidad duracion tolerancia
		this.showMovStop = false; //Ver Paradas y Movimiento
		this.showDivHorizontal = false; // Nombre de cabecera del reporte
		this.showLimitTime = false; //Configuracion de rango de tiempo -- true la mayoria
		this.showEvents = false; //Configuracion
    this.showEventsCipia = false; //Configuracion
    this.showAtencionEventsCipia = false;
		this.showTrans1min = false; //Configuracion de duracion de parada >1min
		this.showFatigaOp = false; //Configuracion de opcion de fatiga 2
		this.showBrakeAccel = false; //Configuración Aceleracion y frenada
    this.showFatigaDistraccion = false; //Configuracion Distraccion y posible fatiga
    this.showCombustibleOpt = false; //Configuracion de reporte de combustible

    // let aux_convoy = this.vehicles.filter((vehicle: { idconvoy: any; }) => vehicle.idconvoy == this.selectedConvoy);
    // let aux_group = this.vehicles.filter((vehicle: { idgrupo: any; }) => vehicle.idgrupo == this.selectedGroup);

    switch(this.selectedReport){
      case 'R001': // 0 - R001	REPORTE DE PARADAS Y MOVIMIENTOS
        this.showMovStop = true;
        this.showLimitTime = true;
        break;
      case 'R002': // 1 - R002	REPORTE DE EXCESOS DE VELOCIDAD
        this.showExcVelOpt = true;
        this.showLimitTime = true;
        break;
      case 'R003': // 2 - R003	REPORTE DE ENTRADA Y SALIDA
        this.showZones = true;
        this.showLimitTime = true;
        break;
      case 'R004': // 3 - R004	REPORTE DE COMBUSTIBLE
        this.showLimitTime = true;
        this.showOdomOpt = true;
        this.showCombustibleOpt = true;
        break;
      case 'R005': // 4 - R005	REPORTE DE EXCESOS EN ZONA
        this.showLimitTime = true;
        break;
      case 'R006': // 5 - R006	REPORTE GENERAL
        this.showLimitTime = true;
        this.showCheckboxs = true;
        break;
      case 'R007': // 6 - R007	REPORTE DE EVENTOS
        this.showLimitTime = true;
        this.showEvents = true;
        break;
      case 'R008': // 7 - R008	REPORTE DE POSICIÓN
        break;
      case 'R011':  // 10 -R011	REPORTE DE FRENADA Y ACELERACIÓN BRUSCA (ECO DRIVE)
        this.showLimitTime = true;
        this.showBrakeAccel = true;
        break;
      case 'R012': // 11 -R012	REPORTE DE DISTRACIÓN Y POSIBLE FATIGA
        this.showLimitTime = true;
        this.showFatigaDistraccion = true;
        break;
      case 'R013': // 12 -R013	REPORTE DE CALIFICACION DE MANEJO
        this.showLimitTime = true;
        break;
      case 'R014':  // 13 -R014	REPORTE DE FATIGA EXTREMA
        this.showLimitTime = true;
        break;
      case 'R015':  // 14 -R015	REPORTE DE ANTICOLISIÓN FRONTAL
      case 'R016':  // 15 -R016	REPORTE DE COLISIÓN CON PEATONES
      case 'R017':  // 16 -R017	REPORTE DE DESVÍO DE CARRIL HACIA LA IZQUIERDA
      case 'R018':  // 17 -R018	REPORTE DE DESVÍO DE CARRIL HACIA LA DERECHA
      case 'R019':  // 18 -R019	REPORTE DE BLOQUEO DE VISIÓN DE MOBILEYE
      case 'R020':  // 19 -R020	REPORTE GERENCIAL (Cuenta cruzdelsur)
      case 'R021':  // 20 -R021	REPORTE DE EXCESOS DE VELOCIDAD (FORMATO EXTENDIDO)
      case 'R022':  // 21 -R022	REPORTE DE SOMNOLENCIA Y/O DISTRACCIÓN - PROLOINT
      case 'R023':  // 22 -R023	REPORTE DE MANTENIMIENTO FISICO
      case 'R024':  //    -R024	REPORTE DE NO ROSTRO
      case 'R025':  //    -R025 NEW 1
      case 'R026':  //    -R026 NEW 2
      case 'R027':  //    -
      case 'R028':  //    -
      case 'R029':  //    -
      case 'R030':  //    -
      case 'R031':  //    -
      case 'R032':  //    -
          this.showLimitTime = true;
				break;
      case 'R033':  //    -
          this.showZones = true;
        break;
      case 'R034':  //    -
      case 'R035':  //    -
          //this.showZones = true;
      break;
      case 'R036':  //   - R036	REPORTE GENERAL CIPIA
          this.showLimitTime = true;
          this.showCheckboxsCipia = true;
          // this.showCheckboxs = true;
      break;
      case 'R037':  //   - R037	REPORTE DE EVENTOS
          this.showLimitTime = true;
          this.showEventsCipia = true;
          // this.showEvents = true;

          this.eC = {
            Fecha :true,
            Hora :false,
            FechaServidor :false,
            Evento :true,
            Codigo :true,
            Placa :true,
            TipoUnidad :true,
            IdConductor :false,
            Conductor :true,

            FechaEvaluacion : false,
            CriterioEvaluacion : false,
            Observacion : false,
            Validacion: false,

            VelMobileye :false,
            VelGPS :true,
            VelCAN :true,
            VelECO :false,
            VelGPSspeed :false,

            Zona :true,
            PuntoCercano :true,
            Ubicacion :true,
            Referencia :false,
            EnlaceArchivo :false,
            Parametros : false,
            Satelite:false,

            OperadorMonitoreo : false,  // R. Atención de Eventos
          }

      break;
      case 'R038':  //   - R038	REPORTE DE ATENCIÓN DE EVENTOS
          this.showLimitTime = true;
          this.showAtencionEventsCipia = true;
          // this.showEvents = true;
          // this.updateCheckDefaultEvents();
          this.eC = {
            Fecha :true,
            Hora :false,
            FechaServidor :true,
            Evento :true,
            Codigo :true,
            Placa :true,
            TipoUnidad :true,
            IdConductor :false,
            Conductor :true,

            FechaEvaluacion : true,
            CriterioEvaluacion : true,
            Observacion : true,
            Validacion: true,

            VelMobileye :false,
            VelGPS :true,
            VelCAN :true,
            VelECO :false,
            VelGPSspeed :false,

            Zona :true,
            PuntoCercano :true,
            Ubicacion :true,
            Referencia :false,
            EnlaceArchivo :false,
            Parametros : false,
            Satelite:false,

            OperadorMonitoreo : true,  // R. Atención de Eventos
          }

      break;
      case 'R039': //  - R039	REPORTE DE EXCESOS DE VELOCIDAD (NUEVO FORMATO)
        this.showLimitTime = true;
      break;
      case 'R040':  //   - R040	REPORTE DE EVENTOS INTERNO
          this.showLimitTime = true;
          this.showEventsCipia = true;
          // this.showEvents = true;

          this.eC = {
            Fecha :true,
            Hora :false,
            FechaServidor :false,
            Evento :true,
            Codigo :true,
            Placa :true,
            TipoUnidad :true,
            IdConductor :false,
            Conductor :true,

            FechaEvaluacion : false,
            CriterioEvaluacion : false,
            Observacion : false,
            Validacion: false,

            VelMobileye :false,
            VelGPS :true,
            VelCAN :true,
            VelECO :false,
            VelGPSspeed :false,

            Zona :true,
            PuntoCercano :true,
            Ubicacion :true,
            Referencia :false,
            EnlaceArchivo :false,
            Parametros : false,
            Satelite:false,

            OperadorMonitoreo : false,  // R. Atención de Eventos
          }

      break;
      case 'R041':
        this.showLimitTime = true;
        this.showTipoExcesoVelocidad = true;

        this.eC = {
          Fecha :true,
          Hora :false,
          FechaServidor :false,
          Evento :false,
          Codigo :true,
          Placa :true,
          TipoUnidad :false,
          IdConductor :false,
          Conductor :true,

          FechaEvaluacion : false,
          CriterioEvaluacion : false,
          Observacion : false,
          Validacion: false,

          VelMobileye :false,
          VelGPS :false,
          VelCAN :true,
          VelECO :false,
          VelGPSspeed :false,

          Zona :true,
          PuntoCercano :false,
          Ubicacion :true,
          Referencia :false,
          EnlaceArchivo :false,
          Parametros : false,
          Satelite:true,

          OperadorMonitoreo : false,  // R. Atención de Eventos
        }

      break;
      case 'R042': //  - R042	REPORTE DE COMBUSTIBLE RESUMEN
        this.showLimitTime = true;
      break;
      case 'R043': // - R043 REPORTE DE ESTADO DE TRANSMISION
        this.showLimitTime = true;
      break;
      case 'R044': // R044 REPORTE DE EXCESO DE VELOCIDAD CON DIRECCION
        this.showLimitTime = true;
        this.showTipoExcesoVelocidad = true;

        this.eC = {
          Fecha :true,
          Hora :false,
          FechaServidor :false,
          Evento :false,
          Codigo :true,
          Placa :true,
          TipoUnidad :false,
          IdConductor :false,
          Conductor :true,

          FechaEvaluacion : false,
          CriterioEvaluacion : false,
          Observacion : false,
          Validacion: false,

          VelMobileye :false,
          VelGPS :false,
          VelCAN :true,
          VelECO :false,
          VelGPSspeed :false,

          Zona :true,
          PuntoCercano :false,
          Ubicacion :true,
          Referencia :false,
          EnlaceArchivo :false,
          Parametros : false,
          Satelite:true,

          OperadorMonitoreo : false,  // R. Atención de Eventos
        }

      break;

      default: break;
    }
    /*  console.log(Object.keys(this.reports[this.selectedReport]).length === 0);
    console.log(_.isEmpty(this.reports[this.selectedReport])); */
    // console.log(_.isEmpty(this.selectedReport));
    console.log(this.selectedReport);
    console.log(typeof this.selectedReport);
    console.log("Selected report", this.selectedReport);
    // console.log("Grupo", this.checkboxGroup && !_.isEmpty(this.selectedGroup) && this.selectedGroup);
    // console.log("Convoy", !this.checkboxGroup && !_.isEmpty(this.selectedConvoy) && this.selectedConvoy)

    // console.log("Vehiculos en el Convoy", this.vehicles.filter((vehicle: { nameconvoy: any; }) => vehicle.nameconvoy == this.selectedConvoy));
    // console.log("Selected Vehicles", this.selectedVehicles);

  }

  
  validateForm() {
    console.log("------- validateForm ----------");
    
  }



  guardarReporteProgramado() {
    this.spinner.show("loadingReportesProgramadosAdd");
    this.fullScreenSpinnerMsg = "Guardando Reporte Programado...";

    console.log('=======> guardarReporteProgramado ');
    console.log(this.form);
    console.log(this.form.p_numRep);

    this.onChangeChkEvents();


    

    
    //======= variables =======

    // ------ SI SE SELECCIONA UN CONVOY, SE PROCEDERA A PASAR CREAR EL ARRAY DE LOS VEHICULOS PERTENECIENTES A DICHO CONVOY.
    var vehiculos_imei = [];
    for (let index = 0; index < this.form.p_vehiculos.length; index++) {
      const element = this.form.p_vehiculos[index];
      vehiculos_imei.push({IMEI: element.IMEI, name:element.name, nameconvoy:element.nameconvoy});
    }


    var h1 = moment(new Date(this.form.p_fecha_desde));
		var h2 = moment(new Date(this.form.p_fecha_hasta));
    var M1 = "2020-10-10" + 'T' + h1.format("HH:mm") + ':00-05:00';
		var M2 = "2020-10-10" + 'T' + h2.format("HH:mm") + ':00-05:00';


    var h3 = moment(new Date(this.form.intervalo_hora));
    var M3 = "2020-10-10" + 'T' + h3.format("HH:mm") + ':00-05:00';


    this.repProgramadosService.paramsReportesProgramados.p_numRep               = this.form.p_numRep;
    this.repProgramadosService.paramsReportesProgramados.p_url                  = this.form.p_url;
    this.repProgramadosService.paramsReportesProgramados.id                     = this.form.id;



    this.repProgramadosService.paramsReportesProgramados.nombre_programacion    = this.form.nombre_programacion;
    this.repProgramadosService.paramsReportesProgramados.bol_activo             = this.form.bol_activo;
    this.repProgramadosService.paramsReportesProgramados.p_vehiculos            = JSON.stringify(vehiculos_imei),//JSON.stringify(this.selectedVehicles),

    this.repProgramadosService.paramsReportesProgramados.p_fecha_desde          = M1;
    this.repProgramadosService.paramsReportesProgramados.p_fecha_hasta          = M2;
    this.repProgramadosService.paramsReportesProgramados.p_fecha_actual         = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    
    
    this.repProgramadosService.paramsReportesProgramados.p_chkDateHour          = this.form.p_chkDateHour;

    var p_duracionExcesos = (this.form.p_selectedDuracion === undefined || this.form.p_selectedDuracion === null) ? 0: this.form.p_selectedDuracion.code;
    var p_toleraciaExcesos = (this.form.p_selectedToleracia === undefined || this.form.p_selectedToleracia === null) ? 0: this.form.p_selectedToleracia.code;

    this.repProgramadosService.paramsReportesProgramados.p_duracionExcesos      = p_duracionExcesos;
    this.repProgramadosService.paramsReportesProgramados.p_toleraciaExcesos     = p_toleraciaExcesos;
    this.repProgramadosService.paramsReportesProgramados.p_chkRiesgo            = this.form.p_chkRiesgo;

    this.repProgramadosService.paramsReportesProgramados.p_tags_zonas            = JSON.stringify(this.form.p_tags_zonas); //JSON.stringify(this.selectedTags);


    this.repProgramadosService.paramsReportesProgramados.p_ec                   =      JSON.stringify([this.form.p_ec]); //this.form.p_ec;
    this.repProgramadosService.paramsReportesProgramados.p_og                   =      JSON.stringify([]); //this.form.p_ec;
    this.repProgramadosService.paramsReportesProgramados.p_ev                   =      JSON.stringify([this.eV]); //this.form.p_ec;


    this.repProgramadosService.paramsReportesProgramados.intervalo              = this.form.intervalo;
    this.repProgramadosService.paramsReportesProgramados.intervalo_hora         = M3;
    this.repProgramadosService.paramsReportesProgramados.periodo_dias_semanal   = this.form.periodo_dias_semanal;
    this.repProgramadosService.paramsReportesProgramados.periodo_dias_mensual   = this.form.periodo_dias_mensual;

    
    

    // this.repProgramadosService.paramsReportesProgramados.periodo_dias              = this.form.periodo_dias;


    this.repProgramadosService.paramsReportesProgramados.formato_exportacion        = this.form.formato_exportacion;
    this.repProgramadosService.paramsReportesProgramados.notificacion_email         = this.form.notificacion_email;
    this.repProgramadosService.paramsReportesProgramados.notificacion_email_lista   = this.form.notificacion_email_lista;

    //----------------variables---------------------


    // this.repProgramadosService.paramsReportesProgramados.p_chkAceleracion          = this.form.p_chkAceleracion;
    // this.repProgramadosService.paramsReportesProgramados.p_chkFatigaSomnolencia          = this.form.p_chkFatigaSomnolencia;
    // this.repProgramadosService.paramsReportesProgramados.p_chkFrenada          = this.form.p_chkFrenada;
    // this.repProgramadosService.paramsReportesProgramados.p_chkMovements          = this.form.p_chkMovements;
    // this.repProgramadosService.paramsReportesProgramados.p_chkOdomV          = this.form.p_chkOdomV;
    // this.repProgramadosService.paramsReportesProgramados.p_chkRiesgo          = this.form.p_chkRiesgo;
    // this.repProgramadosService.paramsReportesProgramados.p_chkStops          = this.form.p_chkStops;
    // this.repProgramadosService.paramsReportesProgramados.p_chkTrans1min          = this.form.p_chkTrans1min;

    // this.repProgramadosService.paramsReportesProgramados.p_checkboxDuration    = this.form.p_checkboxDuration;
    // this.repProgramadosService.paramsReportesProgramados.p_limitSpeed          = this.form.p_limitSpeed;
    // this.repProgramadosService.paramsReportesProgramados.p_minimDur            = this.form.p_minimDur;


    // this.repProgramadosService.paramsReportesProgramados.p_duracionExcesos          = this.form.p_duracionExcesos;

    // this.repProgramadosService.paramsReportesProgramados.p_ec          =      JSON.stringify(this.form.p_ec); //this.form.p_ec;
    // this.repProgramadosService.paramsReportesProgramados.p_ev          =      JSON.stringify(this.form.p_ev); //this.form.p_ev;
    // this.repProgramadosService.paramsReportesProgramados.p_fecha_actual          = this.form.p_fecha_actual;
    // this.repProgramadosService.paramsReportesProgramados.p_fecha_desde          = this.form.p_fecha_desde;
    // this.repProgramadosService.paramsReportesProgramados.p_fecha_hasta          = this.form.p_fecha_hasta;
    
    // this.repProgramadosService.paramsReportesProgramados.p_grupo          = this.form.p_grupo;
    // this.repProgramadosService.paramsReportesProgramados.p_limit          = this.form.p_limit;
    // this.repProgramadosService.paramsReportesProgramados.p_limitVel          = this.form.p_limitVel;
    // this.repProgramadosService.paramsReportesProgramados.p_minimDur          = this.form.p_minimDur;
    // this.repProgramadosService.paramsReportesProgramados.p_numRep          = this.form.p_numRep;
    // this.repProgramadosService.paramsReportesProgramados.p_og          = this.form.p_og;

    // this.repProgramadosService.paramsReportesProgramados.p_tags_zonas          = this.form.p_tags_zonas;
    // this.repProgramadosService.paramsReportesProgramados.p_toleraciaExcesos          = this.form.p_toleraciaExcesos;
    // this.repProgramadosService.paramsReportesProgramados.p_url          = this.form.p_url;
    // this.repProgramadosService.paramsReportesProgramados.p_vehiculos          = this.form.p_vehiculos;
    // this.repProgramadosService.paramsReportesProgramados.p_zonas          = this.form.p_zonas;

    // this.repProgramadosService.paramsReportesProgramados.bol_eliminado          = this.form.bol_eliminado;
    // this.repProgramadosService.paramsReportesProgramados.usuario_id          = this.form.usuario_id;

    // this.repProgramadosService.paramsReportesProgramados.notificacion_hora          = this.form.notificacion_hora;
    // this.repProgramadosService.paramsReportesProgramados.notificacion_minuto          = this.form.notificacion_minuto;



    // $RProgramado->p_chkAceleracion        = $request->p_chkAceleracion;
    // $RProgramado->p_chkFatigaSomnolencia  = $request->p_chkFatigaSomnolencia;//$request->var_nombre;
    // $RProgramado->p_chkFrenada            = $request->p_chkFrenada;//$request->var_color;
    // $RProgramado->p_chkMovements          = $request->p_chkMovements;//"true";//$request->bol_personalizado; //a corregir
    // $RProgramado->p_chkOdomV              = $request->p_chkOdomV;//"true";//$request->bol_personalizado; //a corregir
    // $RProgramado->p_chkRiesgo             = $request->p_chkRiesgo;
    // $RProgramado->p_chkStops              = $request->p_chkStops;
    // $RProgramado->p_chkTrans1min          = $request->p_chkTrans1min;
    // $RProgramado->p_duracionExcesos       = $request->p_duracionExcesos;
    // $RProgramado->p_ec                    = $request->p_ec;
    // $RProgramado->p_ev                    = $request->evp_ev;
    // $RProgramado->p_fecha_actual          = $request->p_fecha_actual;
    // $RProgramado->p_fecha_desde           = $request->p_fecha_desde;
    // $RProgramado->p_fecha_hasta           = $request->p_fecha_hasta;
    // $RProgramado->p_grupo                 = "[]";//$request->grupo;
    // $RProgramado->p_limit                 = $request->p_limit;
    // $RProgramado->p_limitVel              = $request->p_limitVel;
    // $RProgramado->p_minimDur              = $request->p_minimDur;
    // $RProgramado->p_numRep                = $request->p_numRep;
    // $RProgramado->p_og                    = $request->p_og;
    // $RProgramado->p_tags_zonas            = $request->p_tags_zonas;
    // $RProgramado->p_toleraciaExcesos      = $request->p_toleraciaExcesos;
    // $RProgramado->p_url                   = $request->p_url;
    // $RProgramado->p_vehiculos             = $request->p_vehiculos;
    // $RProgramado->p_zonas                 = $request->p_zonas;
    // $RProgramado->bol_eliminado           = false;
    // $RProgramado->usuario_id              = Auth::id();//$request->usuario_id;

    // $RProgramado->notificacion_email      = $request->notificacion_email;
    // $RProgramado->notificacion_email_lista  = $request->notificacion_email_lista;
    // $RProgramado->notificacion_hora       = $request->notificacion_hora;
    // $RProgramado->notificacion_minuto     = $request->notificacion_minuto;
    // $RProgramado->nombre_programacion     = $request->nombre_programacion;
    // $RProgramado->bol_activo              = $request->bol_activo;
    // $RProgramado->p_chkDateHour           = $request->p_chkDateHour;

    // $RProgramado->p_checkboxDuration      = $request->p_checkboxDuration;
    // $RProgramado->intervalo               = $request->intervalo;
    // $RProgramado->intervalo_hora          = $request->intervalo_hora;
    // $RProgramado->periodo_dias_semanal    = $request->periodo_dias_semanal;
    // $RProgramado->periodo_dias_mensual    = $request->periodo_dias_mensual;
    // $RProgramado->formato_exportacion     = $request->formato_exportacion;
    




    
    // this.repProgramadosService.paramsReportesProgramados.notificacion_email     = this.form.notificacion_email;

    console.log("================= PARAMETROS ======================");
    console.log(this.repProgramadosService.paramsReportesProgramados);
    

   
    // var notificacion = moment(new Date(this.form.hora_programado2));


    // this.repProgramadosService.paramsReportesProgramados.notificacion_hora   = notificacion.hours();


    

// [{"IMEI":"860640057334189","name":"(677) BVU542","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"860640057372882","name":"(678) BVT415","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"E423350350","name":"(678 CIPIA) BVT415","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"867604055674890","name":"(679) BVV179","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"860640057379358","name":"(680) BVT685","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"E423350296","name":"(680 CIPIA) BVT685","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"860640057346167","name":"(681) BVV286","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"867604055674833","name":"(682) BVU442","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"867604055674882","name":"(683) BVU601","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"866901060309863","name":"(684) BXJ582","nameconvoy":"Unidades Sin Convoy"},
//   {"IMEI":"860640057372346","name":"(685) BXL059","nameconvoy":"Unidades Sin Convoy"}]

    var param = this.repProgramadosService.paramsReportesProgramados;


    console.log("ES EDITADO?");
    console.log(this.repProgramadosService.modal_add0);

    var ruta_edit_add = "";
    if (this.repProgramadosService.modal_add0) {
        //ADD
        ruta_edit_add = '/api/saveReporteProgramado';
    } else {
        //EDIT
        ruta_edit_add = '/api/updateReporteProgramado';
    }

    this.http.post(environment.apiUrl + ruta_edit_add, param).subscribe({
      next: data => {
        //console.log(this.selectedConvoy.length);
        // console.log(typeof data);
        // console.log(data);
        // this.spinner.hide("reloadSpinner");
        // this.reportService.modalReportesProgramadosActive = false;

        this.fullScreenSpinnerMsg = "Actualizando ...";

        //=============================================================================================================
        this.http.get<any>(environment.apiUrl + '/api/getReportesProgramados').subscribe({
          next: data => {
              // this.listTags = data.data;
              console.log("reportProgramados", data);
              this.reportesProgramados = data;
              this.repProgramadosService.reportesProgramados = this.reportesProgramados;

              if (this.repProgramadosService.modal_add0) {
                  //ADD
                  Swal.fire(
                    'Agregado',
                    'Los datos se agregaron correctamente!!',
                    'success'
                  );
              } else {
                  //EDIT
                  Swal.fire(
                    'Editado',
                    'Los datos se editaron correctamente!!',
                    'success'
                  );
              }


              // for (let i = 0; i < this.reportesProgramados.length; i++) {
              //   const element = this.reportesProgramados[i];
              //   // element.unidades = JSON.parse(element.p_vehiculos);
              //   // element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
              //   // element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
              //   for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
              //     const reporte = this.repProgramadosService.listReportes[j];
              //     if (element.p_numRep == reporte.codigo ) {
              //       element.nombre_reporte = reporte.value;
              //     }
              //   }
              // }


              for (let i = 0; i < this.reportesProgramados.length; i++) {
                const element = this.reportesProgramados[i];
                element.unidades = JSON.parse(element.p_vehiculos);

                //==================================================== UNIDADES ==============================================
                element.vehiculos_str =  ( element.unidades.length == 1 ) ? element.unidades[0].name : element.unidades.length + " Vehículos";
                //==================================================== UNIDADES ==============================================
                // =================================================== SIGUIENTE PROGRAMACION =======================================================
                var MP1 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                var MP2 = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

                element.intervalo_hora_HH = moment(new Date(element.intervalo_hora)).format("HH");

                if (element.intervalo == 'diariamente') {
                    MP1 = moment(new Date()).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    MP2 = moment(new Date()).add(1, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                } else if (element.intervalo == 'semanal') {
                    MP1 = moment(new Date()).day( element.periodo_dias_semanal ).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    MP2 = moment(new Date()).day( element.periodo_dias_semanal ).add(7, 'days').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                } else if (element.intervalo == 'quincenal') {
                    MP1 = moment().date(15).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
                    MP2 = moment(new Date()).add(1, 'months').date(1).format("YYYY-MM-DD") + ' ' + element.intervalo_hora_HH + ':00:00';
                } else if (element.intervalo == 'mensual') {
                    MP1 = moment().date(1).format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                    MP2 = moment().date(1).add(1, 'months').format('YYYY-MM-DD') + ' ' + element.intervalo_hora_HH + ':00:00';
                }

                var h_actual = moment(new Date()); //2024-09-20 19:34:45
                var h_prog   = moment(MP1); //2024-09-20 16:00:00
                var s2       = h_prog.diff(h_actual, 'minutes');
      

                if ( s2 < 0 ) {
                  element.proximo_reporte_generado_2 = MP2;
                } else {
                  element.proximo_reporte_generado_2 = MP1;
                }
                // =================================================== FIN SIGUIENTE PROGRAMACION =======================================================

                // element.hora_inicio = moment(new Date(element.p_fecha_desde)).format("HH");
                // element.hora_fin = moment(new Date(element.p_fecha_hasta)).format("HH");
                // element.nombre_reporte  = element.p_numRep;
                for (let j = 0; j < this.repProgramadosService.listReportes.length; j++) {
                  const reporte = this.repProgramadosService.listReportes[j];
                  if (element.p_numRep == reporte.codigo ) {
                    element.nombre_reporte = reporte.value;
                  }
                }
              }


              this.spinner.hide("loadingReportesProgramadosAdd");


              this.repProgramadosService.modal_add0 = false;
              this.repProgramadosService.modal_add = false;
              this.repProgramadosService.modalReportesProgramadosActiveAddEdit = false;


          },
          error: () => {
            console.log('Fallo al obtener reportesProgramados');
          }
        });
        //=============================================================================================================
  
      },
      
    });

    

    // this.reportService.modalReportesProgramadosActiveDetalle = true;
  }

}
