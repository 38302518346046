import { Component, OnInit } from '@angular/core';
import { DriverDropdown,DriverProgramming,DriverProgrammingRequest } from '../../models/interfaces';
import { DriversService } from '../../services/drivers.service';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
import { VehicleList } from 'src/app/multiview/models/interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-drivers-programming-add',
  templateUrl: './drivers-programming-add.component.html',
  styleUrls: ['./drivers-programming-add.component.scss']
})
export class DriversProgrammingAddComponent implements OnInit {

  drivers: DriverDropdown[] = [];
  driverProgrammingForm!: FormGroup;
  vehicles: VehicleList[] = [];
  showDateEnd : boolean = true;
  constructor(
    private fb: FormBuilder,
    private vehicleService : VehicleService,
    public driversService: DriversService) {
    }

  ngOnInit(): void {
    this.drivers = this.driversService.getDriversList();
    this.vehicles = this.vehicleService.vehicles.map(vh => {
      return {
        id: vh.id!,
        vehicle_id: vh.vehicle_id,
        tracker_imei: vh.tracker_imei,
        name: vh.name!,
        nombre: vh.nombre!,
        numero_placa: vh.numero_placa!,
        cod_interno: vh.cod_interno!,
      }
    });

    // Parse Data From List To Edit
    if ( this.driversService.modalProgrammingNameComponent == "EDIT" ) {
      this.driverProgrammingForm = this.initForm();

      var sub = this.driversService.driversProgramming.find((item:DriverProgramming)=> item.id == this.driversService.idDriverProgEdit);
      console.log('PRUEBA EDIT ->',sub);
      if(sub){
        this.driverProgrammingForm = this.fb.group({
          driver_id:         [sub.driver_id],
          name_driver:     [sub.name_driver],
          fecha_ini:        [sub.fecha_ini],
          fecha_fin:        [sub.fecha_fin ?? null],
          // fecha_ini:        [this.extractDate(sub.fecha_ini.toString())],
          // fecha_fin:      (sub.fecha_fin) ? [this.extractDate(sub.fecha_fin.toString())] : [null],
          // hora_ini:        [this.extractTime(sub.fecha_ini.toString())],
          // hora_fin:      (sub.fecha_fin) ? [this.extractTime(sub.fecha_fin.toString())] : [null],
          key_id:   [sub.key_id],
          key_type:   [sub.key_type],
          key_number:   [sub.key_number],
        });
        console.log(this.driverProgrammingForm.value);
      }
    } else {
        this.driverProgrammingForm = this.initForm();
    }
  }

  onSubmit(){
    console.log('ON SUBMIT : ',this.driverProgrammingForm.value);
    if (!this.driverProgrammingForm.value.driver_id) {
      Swal.fire({
        title: 'Error',
        text: 'Debe seleccionar un conductor',
        icon: 'warning',
      });
      return ;
    }
    if (!this.driverProgrammingForm.value.key_number) {
      Swal.fire({
        title: 'Error',
        text: 'Debe seleccionar un vehículo',
        icon: 'warning',
      });
      return ;
    }

    if (!this.driverProgrammingForm.value.fecha_ini) {
      Swal.fire({
        title: 'Error',
        text: 'Debe ingresar una Fecha Inicial',
        icon: 'warning',
      });
      return ;
    }


    if(this.showDateEnd){
      if (!this.driverProgrammingForm.value.fecha_fin) {
        Swal.fire({
          title: 'Error',
          text: 'Debe ingresar una Fecha Final',
          icon: 'warning',
        });
        return ;
      }
      // Validaciones de Interval de tiempo
      if(this.driverProgrammingForm.value.fecha_ini < this.driverProgrammingForm.value.fecha_fin){
        Swal.fire({
          title: 'Error',
          text: 'La fecha Inicial debe ser menor a la fecha Final',
          icon: 'warning',
        });
        return ;
      }
    }


    const request:DriverProgrammingRequest = {
      user_tracker_id: this.driverProgrammingForm.value.key_number,
      driver_id: this.driverProgrammingForm.value.driver_id,
      fecha_ini: this.driverProgrammingForm.value.fecha_ini,
      fecha_fin: this.driverProgrammingForm.value.fecha_fin,
    }

    console.log(request);
    Swal.fire({
      //title: '¿Desea guardar los cambios?',
      text: '¿Desea guardar los cambios?',
      icon: 'question',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        if (this.driversService.modalProgrammingAction === 'edit') {
          return this.driversService.editDriverProgrammingToVehicle(request).toPromise();
        } else {
          return this.driversService.createDriverProgrammingToVehicle(request).toPromise();
        }
      },
    }).then((result) => {
      console.log('Response : ',result);
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'La programación ha sido guardada correctamente',
        });
      }
    }).catch((error) => {
      console.log('response : ',error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al guardar la programación. Por favor, intenta de nuevo.',
      });
    });
  }

  initForm(): FormGroup {
    return this.fb.group({
      driver_id: [null],
      name_driver: [null],
      fecha_ini: [null],
      fecha_fin: [null],
      // hora_ini: [null],
      // hora_fin: [null],
      key_id:[null],
      key_type:[null],
      key_number:[null],
    })
  }
}
