import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { DriverDropdown,DriverProgrammingList } from '../../models/interfaces';
import { DriversService } from '../../services/drivers.service';
import { PrimeNGConfig } from 'primeng-lts/api';

@Component({
  selector: 'app-drivers-programming-list',
  templateUrl: './drivers-programming-list.component.html',
  styleUrls: ['./drivers-programming-list.component.scss']
})
export class DriversProgrammingListComponent implements OnInit {
  drivers: DriverDropdown[] = [];
  startDate: string = '';
  endDate: string = '';
  filteredDriversProgramming: DriverProgrammingList[] = [];

  selectedFilters = {
    driver: null,  // Aquí almacenaría `selectedDriver` o el array para multiselect
    dateStart: null,
    dateEnd: null
  }
  driversProgramming: DriverProgrammingList[] = [];

  @Output() actionEdit: EventEmitter<void> = new EventEmitter();

  constructor(public driversService: DriversService,
    private config: PrimeNGConfig) { }

  ngOnInit(): void {
    this.config.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Limpiar',
      weekHeader: 'Sm'
  });

    this.drivers = this.driversService.getDriversList();

    this.driversProgramming = this.driversService.driversProgramming
    .filter(driverProg => driverProg.key_type == 'VEHICLE')
    .map(driverProg => {
      return {
        id: driverProg.id,
        key_type: driverProg.key_type,
        driver_id: driverProg.driver_id,
        name_driver: driverProg.name_driver ?? 'Nombre no Encontrado',
        fecha_ini: driverProg.fecha_ini,
        fecha_fin: driverProg.fecha_fin,
      }
    });

    this.filteredDriversProgramming = [...this.driversProgramming];

  }


  editProgramming(driverList:any){
    console.log('Editar ',driverList);

    this.driversService.modalProgrammingNameComponent = 'EDIT';
    this.driversService.modalProgrammingAction = 'Editar';
    this.driversService.activeTabIndex = 1;
    this.driversService.idDriverProgEdit = driverList.id;
    this.actionEdit.emit();
  }
  deleteProgramming(driver:any){
    console.log('Delete ',driver);
  }

  filter(type:string){
    console.log(type);
    console.log(this.selectedFilters);
    console.log(this.filteredDriversProgramming);


    this.filteredDriversProgramming = [...this.driversProgramming];
    // Filtrar por conductor seleccionado (en caso de que no sea null)
    if (this.selectedFilters.driver) {
      this.filteredDriversProgramming = this.filteredDriversProgramming.filter(driverProg =>
        driverProg.driver_id === this.selectedFilters.driver // Comparar por el ID o propiedad relevante
      );
    }

    // Filtrar por fecha de inicio (si está definida)
    if (this.selectedFilters.dateStart && this.selectedFilters.dateStart != null) {
      this.filteredDriversProgramming = this.filteredDriversProgramming.filter(driverProg =>
        new Date(driverProg.fecha_ini).getTime() >= new Date(this.selectedFilters.dateStart!).getTime()
      );
    }

    // Filtrar por fecha de fin (si está definida)
    if (this.selectedFilters.dateEnd) {
      this.filteredDriversProgramming = this.filteredDriversProgramming.filter(driverProg =>
        new Date(driverProg.fecha_fin!).getTime() <= new Date(this.selectedFilters.dateEnd!).getTime()
      );

    }
  }


}
