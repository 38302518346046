

<p-table [value]="filteredDriversProgramming" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [rowsPerPageOptions]="[10,25,50]">
  <ng-template pTemplate="caption">
    <div class="d-flex justify-content-around" #div_filters>
        <div class="p-mr-2">
          <div class="col p-field">
            <div class="col-12">
              <label>Conductor:</label>
            </div>
            <div class="col-12">
              <p-dropdown
                [options]="drivers"
                [(ngModel)]="selectedFilters.driver"
                placeholder="Selecciona un Conductor"
                [showClear]="true"
                (onChange)="filter('driver')"
                [appendTo]="'body'"
                optionValue="id"
                [style]="{ minWidth: '221px',width:'100%'}"
                optionLabel="name">
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="p-mr-2">
          <div class="col p-field">
            <div class="col-12">
              <label>Fecha Inicial:</label>
            </div>
            <div class="col-12">
              <p-calendar
                [(ngModel)]="selectedFilters.dateStart"
                [appendTo]="'body'"
                [showIcon]="true"
                showTime="true"
                dateFormat='dd/mm/yy'
                (onClearClick)="filter('dateStart')"
                (onSelect)="filter('dateStart')"
                hourFormat="24"
                [showSeconds]="true"
                [showButtonBar]="true"
              ></p-calendar>
            </div>
          </div>
        </div>
        <div>
          <div class="col p-field">
            <div class="col-12">
              <label>Fecha Final:</label>
            </div>
            <div class="col-12">
              <p-calendar
                [(ngModel)]="selectedFilters.dateEnd"
                [appendTo]="'body'"
                [showIcon]="true"
                dateFormat='dd/mm/yy'
                showTime="true"
                (onSelect)="filter('dateEnd')"
                (onClearClick)="filter('dateEnd')"
                hourFormat="24"
                [showButtonBar]="true"
                [showSeconds]="true"
              ></p-calendar>
            </div>
          </div>
        </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5%;">#</th>
      <th pSortableColumn="name" style="width: 35%;">Nombre de Conductor <p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="dateStart" style="width: 25%;">Fecha de Inicio <p-sortIcon field="dateStart"></p-sortIcon></th>
      <th pSortableColumn="dateEnd" style="width: 25%;">Fecha Final <p-sortIcon field="dateEnd"></p-sortIcon></th>
      <th style="width: 10%;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-driver_programming let-i="rowIndex">
    <tr>
      <td>{{ i+1 }}</td>
      <td>{{ driver_programming.name_driver }}</td>
      <td>{{ driver_programming.fecha_ini | date:'dd/MM/yyyy HH:mm:ss'  }}</td>
      <td>{{ driver_programming.fecha_fin | date:'dd/MM/yyyy HH:mm:ss'  }}</td>
      <td>
        <button pButton type="button" icon="pi pi-pencil" style="font-size: 2rem" class="p-button-rounded" (click)="editProgramming(driver_programming)"></button>
        <button pButton type="button" icon="pi pi-trash" class="p-button-rounded" (click)="deleteProgramming(driver_programming)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="row justify-content-center mb-4 pt-4 panel-izq-container-footer">
  <div class="col-4">
      <button type="button" class="btn-gl-cancel btn-dark-hover w-100" (click)="driversService.modalProgrammingActive=false">Cerrar</button>
  </div>
</div>
