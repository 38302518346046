import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { VehicleService } from '../../../vehicles/services/vehicle.service';
import { DriversService } from '../../services/drivers.service';

import Swal from 'sweetalert2';

import {Md5} from 'ts-md5';
import { Driver ,TypeDocument} from '../../models/interfaces';


@Component({
  selector: 'app-drivers-modal',
  templateUrl: './drivers-modal.component.html',
  styleUrls: ['./drivers-modal.component.scss']
})
export class DriversModalComponent implements OnInit {



  driversForm!: FormGroup;
  ibuttons :any = [];
  icipias :any = [];
  tipo_identificacion = '';

  public typeDocuments: TypeDocument[] = [
    { label: 'DNI', value: 'DNI' },
    { label: 'RUC', value: 'RUC' },
    { label: 'Carnet Extranjería', value: 'CARNET' },
    { label: 'Otro', value: 'OTRO' },
  ];

  public nroDocumentMaxLength: number = 0;
  public eyeStateCreate: boolean = true;
  public eyeStateEditBefore: boolean = true;
  public eyeStateEditAfter: boolean = true;
  public eyeStateEditAfter2: boolean = true;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,

    public driversService: DriversService,
    public VehicleService : VehicleService,



  ) {
    //this.loadData();

  }

  ngOnInit(): void {
    console.log("=========ngOnInit ");
    console.log(this.driversService.action);

    this.ibuttons = this.driversService.ibuttons;
    this.icipias = this.driversService.icipias;

    if ( this.driversService.action == "edit" ) {
      //solo para el caso de EDIT su ibutton debe mostrarse
      var sub = this.driversService.drivers.filter((item:Driver)=> item.id == this.driversService.idDriverEdit);
      console.log('PRUEBA EDIT ->',sub);
      this.driversForm = this.fb.group({
              id:         [sub[0].id],
              nombre:     [sub[0].nombre_conductor],
              dni:        [sub[0].dni_conductor],
              nro_llave:      [sub[0].id_keyIbutton],
              nro_licencia:   [sub[0].nro_licencia],
              nro_cipia:    [sub[0].id_keyIcipia],
              nro_cellphone: [sub[0].telefono_conductor],
              tipo_identificacion: [sub[0].tipo_identificacion],
              tipo_documento: [sub[0].tipo_documento],
            });
        console.log(this.driversForm.value);

    } else {
        this.driversForm = this.initForm();

    }
  }


  initForm(): FormGroup {
    return this.fb.group({
      id:[''],
      nombre: [''],
      dni:        [''],
      nro_llave:  [''],
      nro_cellphone:  [''],
      nro_cipia:  [''],
      nro_licencia: [''],
      tipo_identificacion: [''],
      tipo_documento: [''],

    })
  }

  changeTypeDocument(value:string){
    this.nroDocumentMaxLength = (value=='DNI') ? 8 : ((value=='RUC') ? 11 : 12);
    this.driversForm.controls['dni'].setValue('')
  }

  onSubmit(): void {
    console.log("============ onSubmit ");
    // console.log(this.driversForm);

    if (this.driversForm.value.nombre == '') {
      Swal.fire({
        title: 'Error',
        text: 'Ingrese el nombre del conductor',
        icon: 'error',
      });
      return ;
    }

    if (this.driversForm.value.dni == '') {
      Swal.fire({
        title: 'Error',
        text: 'Ingrese el DNI del conductor',
        icon: 'error',
      });
      return ;
    }

    if (this.driversForm.value.nro_cipia == '--'){
      this.driversForm.value.nro_cipia = null;
    }
    if (this.driversForm.value.nro_llave == '--'){
      this.driversForm.value.nro_llave = null;
    }

    Swal.fire({
      //title: '¿Desea guardar los cambios?',
      text: '¿Desea guardar los cambios?',
      icon: 'question',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        console.log(this.driversForm.value);
        var res:any;
        if ( this.driversService.action == "edit" ) {
          res = await this.driversService.edit(this.driversForm.value);
        } else {
          res = await this.driversService.create(this.driversForm.value);
        }
        console.log('response->',res);

        if (res.success == false) {
            Swal.fire('',res.msn,'warning');
        }else if(res.success == true){
          if(res.text == 'Insert') {
            Swal.fire('','Conductor se registro correctamente.','success');
            this.driversService.modalActive = false;
            this.driversService.spinner.show('loadingDrivers');
            this.driversService.initialize();
            // if(res.dataInsert.tipo_identificacion.vehicle == true){
            //   this.VehicleService.updateDriverAndId(res.dataInsert,true);
            // }
          } else if(res.text == 'Update') {
              Swal.fire('',res.msn,'success');
              this.driversService.modalActive = false;
              this.driversService.spinner.show('loadingDrivers');
              this.driversService.initialize();
              // if(res.driver.tipo_identificacion.vehicle == true || res.driverOld.tipo_identificacion.vehicle == true){
              //   //VERIFICAR IMEI IGUALEs
              //   if(res.driverOld.tracker_imei == res.driver.tracker_imei){
              //     // console.log('IMEI IGUALES SOLO ACTUALIZAR UNO');
              //     this.VehicleService.updateDriverAndId(res.driver,true);
              //   }else {
              //     // console.log('IMEI NO IGUALES SOLO ACTUALIZAR UNO');
              //     if (res.driverOld.tipo_identificacion.vehicle == true){
              //       // console.log('UPDATE OLD',res.driverOld);
              //       this.VehicleService.updateDriverAndId(res.driverOld,false); // SI ES FALSE ELIMINA
              //     }
              //     if(res.driver.tipo_identificacion.vehicle == true){
              //       // console.log('UPDATE NEW',res.driver);
              //       this.VehicleService.updateDriverAndId(res.driver,true);
              //     }
              //   }
              // }
          }
        }else{
            console.log('------------ CASO NO DETECTED');
        }
      },
    }).then((data) => {
      // console.log(data);
    });


  }

}
