import { Component, OnInit } from '@angular/core';
import { DriversService } from '../../services/drivers.service';
import { VehicleService } from 'src/app/vehicles/services/vehicle.service';
import { Driver,DriverProgramming,DriverListIcipia,DriverApiCipia } from '../../models/interfaces';
import { VehicleList } from 'src/app/multiview/models/interfaces';
import { Vehicle } from 'src/app/vehicles/models/vehicle';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { inArray } from 'jquery';
@Component({
  selector: 'app-driver-enrollment-modal',
  templateUrl: './driver-enrollment-modal.component.html',
  styleUrls: ['./driver-enrollment-modal.component.scss']
})
export class DriverEnrollmentModalComponent implements OnInit {
  selectedVehicle!: string | null;
  vehicles: VehicleList[] = [];

  listDriversFull: DriverProgramming[] = [];
  selectedDrivers : DriverProgramming | undefined;

  listDriverEnrolled: DriverProgramming[] = [];
  selectedDriversEnrolled : DriverProgramming | undefined;

  driversUnknown: number=0;
  messageDriversUnknown: string='';
  responseMessages: { [key: number]: { title: string, text: string, icon: any } } = {
    200: {
      title: 'Éxito',
      text: 'La solicitud se realizó correctamente.',
      icon: 'success'
    },
    500: {
      title: 'Error',
      text: 'Hubo un error interno del servidor. Inténtelo más tarde.',
      icon: 'error'
    },
    404: {
      title: 'No Encontrado',
      text: 'No se pudo encontrar la lista de Conductores.',
      icon: 'warning'
    },
    403: {
      title: 'Dispositivo No Encontrado',
      text: 'El dispositivo CIPIA no se encuentra en el servidor, comunicar al área de TI.',
      icon: 'error'
    },
    415: {
      title: 'Enrolamiento Fallido',
      text: 'El dispositivo CIPIA no reconoce el código Icipia, comunicar al área de TI.',
      icon: 'error'
    },
    408: {
      title: 'Tiempo de espera Agotado',
      text: 'El dispositivo CIPIA no respondió a tiempo, verificar que tengan ignición y conexión a internet.',
      icon: '<i class="fas fa-hourglass-end"></i>'
    },
    422: {
      title: 'Falta de información',
      text: 'La solicitud no se pudo completar, falta la información necesaria para el proceso.',
      icon: 'warning'
    },
  };

  constructor(
    public driversService: DriversService,
    public vehicleService: VehicleService,
  ) { }

  ngOnInit(): void {
    this.vehicles = this.vehicleService.vehicles
    .filter(vh => vh.IMEI!.startsWith('E'))
    .map(vh => {
      return {
        id: vh.id!,
        vehicle_id: vh.vehicle_id,
        IMEI: vh.IMEI,
        name: vh.name!,
        numero_placa: vh.numero_placa!,
        cod_interno: vh.cod_interno!,
      }
    });
  }

  async listedByDriver(){
    this.listDriverEnrolled = [];
    this.listDriversFull = [];

    console.log(this.selectedVehicle);
    if(!this.selectedVehicle){
      return ;
    }

    this.driversService.spinner.show('loadingListDriverEnroll');
    // Ejecutar la petición
    this.driversService.getDriversEnrolledList(this.selectedVehicle).subscribe(
      (response: { data: { code: string; }; }) => {
        this.driversService.spinner.hide('loadingListDriverEnroll');

        let jsonParse = JSON.parse(response.data.code);
        console.log(jsonParse);
        this.setListDriversByResponse(jsonParse);
        this.setUnknownDriversEnrolled(jsonParse.length);
        this.alertByCode(200,'Lista de Conductores Actualizada.'); // Mostrar SweetAlert basado en el código
      },
      (error: { status: number; }) => {
        this.selectedVehicle = null;
        this.driversService.spinner.hide('loadingListDriverEnroll');

        // console.log('Error en la petición ->', error);
        const statusCode = error.status || 500;
        this.alertByCode(statusCode); // Mostrar SweetAlert en caso de error
      }
    );
  }
  setListDriversByResponse(jsonParse:DriverListIcipia[]){
    // Filtrar los conductores para listDriverToEnrollment
    this.listDriverEnrolled = this.driversService.driversProgramming.filter(driver =>
      driver.key_type === 'ICIPIA' && driver.fecha_fin == null &&
      jsonParse.some((json) => json.driverId === driver.key_number && json.permission === true)
    );

    // Filtrar el resto de los conductores para listDriversEnrolled
    this.listDriversFull = this.driversService.driversProgramming.filter(driver =>
      (driver.key_type === 'ICIPIA' && driver.fecha_fin == null &&
        !jsonParse.some((json) => json.driverId === driver.key_number && json.permission === true))
    );

  }

  setUnknownDriversEnrolled(length:number){
    this.driversUnknown = length - this.listDriverEnrolled.length;
    if(this.driversUnknown>0){
      this.messageDriversUnknown='Hay '+this.driversUnknown+' enrolamientos con conductor desconocido.';
      console.log('Existen Drivers Enrolados que no existen en la lista...');
    }else{
      this.messageDriversUnknown = '';
    }
  }
  alertByCode(code: number,msn?:string) {
    const message = this.responseMessages[code] || {
      title: 'Error desconocido',
      text: 'Ha ocurrido un error inesperado.',
      icon: 'error'
    };

    const iconsDefault : string[] = ['error','success','warning'];
    if(iconsDefault.includes(message.icon)){
      Swal.fire({
        title: message.title,
        text: msn ?? message.text,
        icon: message.icon,
      });
    }else{
      Swal.fire({
        title: message.title,
        text: msn ?? message.text,
        iconHtml: message.icon,
      });
    }
  }
  // Mover de Lista Drivers a List Para Enrollar
  sendListDriversToEnrollment(){
    console.log('Enviando Drivers a Enrollar - ',this.selectedDrivers);

    if(!this.selectedDrivers){
      Swal.fire({
        title: 'Conductor No Seleccionado',
        text: 'Debe seleccionar un Conductor para realizar el enrolamiento con el dispositivo.',
        icon: 'warning',
      });
    }else{
      this.driversService.spinner.show('loadingListDriverEnroll');

      const driverApiCipia: DriverApiCipia = {
        deviceID: this.selectedVehicle!, // tracker_imei es el UnitID o DeviceID
        driverID: this.selectedDrivers.key_number,  // Aquí asignamos el key_number a driverID
        driverProgrammingID: this.selectedDrivers.id // Id de la Programación
      };
      console.log('Asignar Enrolamiento de : ',driverApiCipia);
      this.driversService.addDriverEnrolled(driverApiCipia).subscribe(
        (response) => {
          this.moveDriverToListEnrolled(this.selectedDrivers?.driver_id!)
          this.alertByCode(200,'Conductor Enrolado Correctamente.');
          this.driversService.spinner.hide('loadingListDriverEnroll');
        },
        (error: { status: number,error:any; }) => {
          // console.log('Error en la petición ->', error);
          const statusCode = error.status || 500;
          if(statusCode == 422){
          this.alertByCode(statusCode,error.error.messages);
          }else{
            this.alertByCode(statusCode);
          }
          this.driversService.spinner.hide('loadingListDriverEnroll');
        }
      );
    }

  }

  // Regresar de List Para Enrollar a Lista Drivers
  returnListDriversFull(){
    console.log('Eliminar Enrolamiento de : ',this.selectedDriversEnrolled);

    if(!this.selectedDriversEnrolled){
      Swal.fire({
        title: 'Conductor No Seleccionado',
        text: 'Debe seleccionar un Conductor para retirar enrolamiento del dispositivo.',
        icon: 'warning',
      });
    }else{
      const driverApiCipia: DriverApiCipia = {
        deviceID: this.selectedVehicle!, // tracker_imei es el UnitID o DeviceID
        driverID: this.selectedDriversEnrolled.key_number,  // Aquí asignamos el key_number a driverID
        driverProgrammingID: this.selectedDriversEnrolled.id // Id de la Programación
      };
      console.log('Asignar Enrolamiento de : ',driverApiCipia);
      Swal.fire({
        title: "Eliminar Enrolamiento de Conductor",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: "Retirar",
        confirmButtonColor: "#FF0000"
      }).then((result) => {
        if (result.isConfirmed) {
          this.driversService.spinner.show('loadingListDriverEnroll');

          this.driversService.deleteDriverEnrolled(driverApiCipia).subscribe(
            (response) => {
              this.moveDriverToListFull(this.selectedDrivers?.driver_id!)
              this.alertByCode(200, 'Conductor Removido correctamente.');
              this.driversService.spinner.hide('loadingListDriverEnroll');
            },
            (error: { status: number; }) => {
              this.driversService.spinner.hide('loadingListDriverEnroll');
              // console.log('Error en la petición ->', error);
              const statusCode = error.status || 500;
              this.alertByCode(statusCode); // Mostrar SweetAlert en caso de error
            }
          );
        }
      });
    }
  }

  moveDriverToListEnrolled(driverId:number){
    let auxListFull:DriverProgramming[] = [];
    let auxListEnrolled:DriverProgramming[] = [];

    auxListFull =[...this.listDriversFull];
    auxListEnrolled =[...this.listDriverEnrolled];

    this.listDriversFull = [];
    this.listDriverEnrolled = [];

    const driverIndex = auxListFull.findIndex(driver => driver.driver_id === driverId);
    if (driverIndex !== -1) {
      console.log('Encontrado en Lista General : ',driverIndex);

      auxListEnrolled.push(auxListFull[driverIndex]); // Lo agrega a listDriverEnrolled
      this.listDriverEnrolled =[...auxListEnrolled];

      auxListFull.splice(driverIndex, 1);
      this.listDriversFull = [...auxListFull];
    }

  }
  moveDriverToListFull(driverId:number){
    let auxListFull:DriverProgramming[] = [];
    let auxListEnrolled:DriverProgramming[] = [];

    auxListFull =[...this.listDriversFull];
    auxListEnrolled =[...this.listDriverEnrolled];

    this.listDriversFull = [];
    this.listDriverEnrolled = [];

    const enrolledIndex = auxListEnrolled.findIndex(driver => driver.driver_id === driverId);

    if (enrolledIndex !== -1) {
      console.log('Encontrado en Lista Enrolados : ',enrolledIndex);

      auxListFull.push(auxListEnrolled[enrolledIndex]); // Lo agrega a listDriverEnrolled
      this.listDriversFull =[...auxListFull];

      auxListEnrolled.splice(enrolledIndex, 1);
      this.listDriverEnrolled =[...auxListEnrolled]
    }


  }

}
