
<p-card>

    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" name="loadingReportesProgramdos" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" class="text-center"> {{ fullScreenSpinnerMsg }} </p></ngx-spinner>


    <ng-template pTemplate="header">
      <span class="d-block p-2 text-white">
        <i class="fas fa-chart-bar"></i> Reportes Programados</span>
    </ng-template>


    <p-button label="Agregar" icon="pi pi-plus" (click)="agregarReporteProgramado0();" styleClass="p-button-secondary"></p-button>
    <p-button label="Run" icon="pi pi-plus" (click)="enviarCorreoReporteProgramado();" styleClass="p-button-secondary"></p-button>

    <div class="p-fluid y-form-container">

      

        <p-table [value]="repProgramadosService.reportesProgramados" [tableStyle]="{ 'min-width': '50rem', 'font-size': '0.9rem' }" styleClass="p-datatable-sm" class="" >
        <!-- <p-table [value]="reportesProgramados" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-sm" > -->

          <ng-template pTemplate="header">
              <tr>
                  <th style="text-align: center; width:5%">N°</th>
                  <th style="text-align: center; width:25%">Informe</th>
                  <th style="text-align: center; width:15%">Nombre del Informe</th>
                  <th style="text-align: center; width:5%">Último generado</th>

                  <th style="text-align: center; width:10%">Formato</th>
                  <th style="text-align: center; width:5%">Vehículos</th>
                  <th style="text-align: center; width:10%">Intervalo</th>
                  <!-- <th style="text-align: center; width:5%">Periodo días</th> -->
                  <th style="text-align: center; width:5%">Next Scheduled tiem</th>

                  <th style="text-align: center; width:15%">Opciones</th>

              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-reporteProgramado let-rowIndex="rowIndex">
              <tr>
                  <td style="text-align: center" >{{ rowIndex + 1}}</td>
                  <td>{{ reporteProgramado.nombre_reporte }}</td>
                  <td style="text-align: center" >{{ reporteProgramado.nombre_programacion }}</td>
                  <td style="text-align: center" >{{ reporteProgramado.fecha_ultimo_reporte_generado }}</td>

                  <td style="text-align: center" >{{ reporteProgramado.formato_exportacion }}</td>
                  <td style="text-align: center" >{{ reporteProgramado.vehiculos_str }}</td>
                  <td style="text-align: center" >{{ reporteProgramado.intervalo }}</td>
                  <!-- <td>{{ reporteProgramado.proximo_reporte_generado }}</td> -->
                  <td>{{ reporteProgramado.proximo_reporte_generado_2 }}</td>
            
                  <td style="text-align: center">
                      <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editReporteProgramado(reporteProgramado)"></button>
                      <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" (click)="verDetalleReporteProgramado(reporteProgramado)"></button>
                      <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="EliminarReporteProgramado(reporteProgramado)"></button>
                  </td>

              </tr>
          </ng-template>
        </p-table>
    </div>






    <ng-template pTemplate="footer">
      <!-- <button class="btn-gl-save btn-dark-hover" style="width: 10rem; border: none;" (click)="generate();">Generar</button> -->
    </ng-template>

  </p-card>
