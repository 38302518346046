import { Component, OnInit } from '@angular/core';
import { DriversService } from '../../services/drivers.service';

@Component({
  selector: 'app-drivers-programming-modal',
  templateUrl: './drivers-programming-modal.component.html',
  styleUrls: ['./drivers-programming-modal.component.scss']
})
export class DriversProgrammingModalComponent implements OnInit {
  public titleModal: string = 'Programación de Conductores';
  isEditProgramming : boolean = false;

  constructor(public driversService: DriversService,) { }

  ngOnInit(): void {
  }
  changeTabView(){
    console.log('Change : ',this.driversService.activeTabIndex);
    this.driversService.modalProgrammingAction = 'Agregar'
    switch (this.driversService.activeTabIndex) {
      case 0:
        this.driversService.modalProgrammingNameComponent = 'LIST';
        this.titleModal = 'Programación de Conductores';
        this.isEditProgramming=false;
        break;
      case 1:
        this.isEditProgramming=true;
        this.driversService.modalProgrammingNameComponent = 'ADD';
        this.titleModal = 'Programación de Conductores - Agregar';
        break;
      case 2:
        this.driversService.modalProgrammingNameComponent = 'IMPORT';
        this.titleModal = 'Programación de Conductores - Importar'
        this.isEditProgramming=false;
        break;
      default:
        console.log('Tab no encontrado');
    }
  }

  onActionEdit(){
    this.isEditProgramming=true;
  }
  changeTitleByName(){
    this.titleModal = 'Programación de Conductores - Editar'
  }



}
